import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, CardMedia, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();
// import { ip } from "../../../service";
// import Pagination from "@material-ui/lab/Pagination";

const CardHeadFinancial = (props) => {
     const classes = useStyles();
     //  let { dataCard, profile } = props;
     //  console.log("test",dataCard);
     //  if (profile && profile.wallet) {
     //       dataCard[0].money = Math.floor(profile.wallet * 100) / 100;
     //  }
     //  if (profile && profile.balance) {
     //       dataCard[1].money = Math.floor(profile.balance * 100) / 100;
     //  }
     //  if (profile && profile.ib_wallet) {
     //       dataCard[2].money = Math.floor(profile.ib_wallet * 100) / 100;
     //  }
     return (
          <Grid container justifyContent="center" alignItems="center" columnSpacing={2}>
               <Grid item xs={6} sx={{ display: { xs: "none", sm: "none", md: "block" } ,mb:3 ,mt:3 }}>
                    <Card sx={{ py: 2, px: 3 }}>
                         <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                              {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                              <Grid>
                                   <Typography className={classes.titleStyle}>Deposit Rate</Typography>
                                   <Typography className={classes.moneyStyle}>$100.00</Typography>
                                  {/* ${props.avaliableCredit.toLocaleString('en-US', {minimumFractionDigits: 2})} */}
                              </Grid>
                         </Grid>
                    </Card>
               </Grid>
               {/* <Grid item xs={6} sx={{ display: { xs: "none", sm: "none", md: "block" } ,mb:3 ,mt:3}}>
                    <Card sx={{ py: 2, px: 3 }}>
                         <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                           
                              <Grid>
                                   <Typography className={classes.titleStyle}>Max</Typography>
                                   <Typography className={classes.moneyStyle}>$50,000.00</Typography>
                              </Grid>
                         </Grid>
                    </Card>
               </Grid> */}

               <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" }, marginBottom: 1 }}>
                    <Card sx={{ py: 2, px: 3 }}>
                         <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                              {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                              <Grid>
                                   <Typography className={classes.titleStyle}>Min</Typography>
                                   <Typography className={classes.moneyStyle}>$100.00</Typography>
                              </Grid>
                         </Grid>
                    </Card>
               </Grid>
               <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" }, marginBottom: 1 }}>
                    <Card sx={{ py: 2, px: 3 }}>
                         <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                              {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                              <Grid>
                                   <Typography className={classes.titleStyle}>Max</Typography>
                                   <Typography className={classes.moneyStyle}>$50,000.00</Typography>
                              </Grid>
                         </Grid>
                    </Card>
               </Grid>
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     titleStyle: {
          fontSize: 16,
          color: "#000",
          fontWeight: 500,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
     moneyStyle: {
          fontSize: 22,
          color: "#4589C6",
          fontWeight: 600,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
     imgStyle: {
          height: 50,
          objectFit: "contain",
          width: "auto",
          marginRight: 8,
          // [theme.breakpoints.up("sm")]: {
          //      height: 80,
          // },
     },
     fontStyleDetail: {
          fontSize: 12,
          color: "#676767",
          textAlign: "justify",
          marginTop: 24,
          [theme.breakpoints.up("sm")]: {
               fontSize: 18,
          },
     },
     fontStyleBodyTitle: {
          fontSize: 12,
          marginTop: 24,
          fontWeight: 600,
          [theme.breakpoints.up("sm")]: {
               fontSize: 18,
          },
     },
     fontStyleBodyDetail: {
          fontSize: 12,
          paddingLeft: 8,
          [theme.breakpoints.up("sm")]: {
               fontSize: 14,
          },
     },
}));
export default withRouter(CardHeadFinancial);
