import { Button, Grid, Typography, Card } from "@material-ui/core";
import { GridTextField } from "components";
// import SearchIcon from "@material-ui/icons/Search";
// import DatePicker from "react-date-picker";
import SupportTable from "./SupportTable";
import { headerTable } from "functions/Static";
import { makeStyles } from "@material-ui/styles";

const SupportCard = (props) => {
     const classes = useStyles();
     const {
          language,
          state,
          handleChange,
          textField_info,
          // handleDateStartChange,
          // handleDateEndChange,
          page,
          data,
          handleChangePage,
          onClickAdd,
          type_support,
          handleClickSearch,
          data_search,
          profile,
          profiledetail,
     } = props;
     const count = Math.ceil(data.length > 0 && data.length / 10);
     const setPage = page - 1;
     const dataList = data.slice(setPage * 10, page * 10);
     return (
          <Grid container spacing={2} xs={12} alignItems="center" mt={5}>
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                         {language === "th" ? "ผู้จัดการส่วนตัว" : "Support"}
                    </Typography>
               </Grid>
               <Grid item xs={12} container justifyContent={"right"}>
                    <Button variant="contained" style={{ backgroundColor : "#4589C6"}} sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }} onClick={onClickAdd}>
                         +{language === "th" ? "สร้าง" : "Create"}
                    </Button>
               </Grid>

               <Grid item xs={12} mt={1}>
                    <Card sx={{ py: 2, px: 3 }}>
                         <Grid container direction={"row"}>
                              {profiledetail.map((el, i) => (
                                   <Grid item xs={12} md={6} key={i}>
                                        <Typography className={classes.titleStyle}>
                                             {language === "th" ? el.labalTh : el.labalEn}: {el.detail}
                                        </Typography>
                                   </Grid>
                              ))}
                         </Grid>
                    </Card>
               </Grid>

               {textField_info && (
                    <>
                         <Grid item xs={12} md={4}>
                              <GridTextField data={textField_info.status_type} language={language} state={state} handleChange={handleChange} menuItem={menuItem} />
                         </Grid>
                         <Grid item xs={12} md={4}>
                              <GridTextField data={textField_info.search_id} language={language} state={state} handleChange={handleChange} />
                         </Grid>
                    </>
               )}
               {/* <Grid item xs={12} md={2}>
                    <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
               </Grid>
               <Grid item xs={12} md={2}>
                    <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
               </Grid> */}
               <Grid item xs={12} container md={2}>
                    <Button
                         variant="contained"
                         sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                         onClick={handleClickSearch}
                         onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                   handleClickSearch();
                              }
                         }}
                         style={{backgroundColor:"#4589C6"}}
                    >
                         {language === "th" ? "ค้นหา" : "Search"}
                    </Button>
               </Grid>
               <Grid item xs={12}>
                    <SupportTable
                         head={headerTable.support}
                         body={data_search.length > 0 ? data_search : dataList}
                         count={count}
                         page={page}
                         handleChangePage={handleChangePage}
                         type_support={type_support}
                         language={language}
                    />
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles((theme) => ({
     titleStyle: {
          fontSize: 16,
          color: "#000",
          fontWeight: 600,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
}));

const menuItem = [
     { label: "Select Status", value: "null" },
     { label: "Success", value: 1 },
     { label: "Received", value: 2 },
     { label: "Waiting", value: 3 },
];

export default SupportCard;
