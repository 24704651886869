import { CardMedia, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import mt4 from "../../../assets/images/mt4.png";
import mt4_logo from "../../../assets/images/metatrader-4-logo-og.png";
import mt5_logo from "../../../assets/images/logo_metatrader5.png"
import ios_logo from "../../../assets/images/ios.png";
import windows from "../../../assets/images/windows.png";
import android from "../../../assets/images/android.png";

import ComputerIcon from "@material-ui/icons/Computer";
import ios from "../../../assets/images/ios-icon.png";
import google from "../../../assets/images/google-play-icon.png";
// import { createMuiTheme } from "@material-ui/core/styles";
// const theme = createMuiTheme();

const DownloadCard = (props) => {
     const { language } = props;
     const classes = useStyles();
     return (
          <Grid container xs={12} spacing={2} mt={5}>
               <Grid item xs={12}>
                    <Typography className={classes.fontStyleTitleBorder}>{language === "th" ? "ดาวน์โหลด" : "Download"}</Typography>
               </Grid>

               {/* <Grid container justifyContent={"center"} xs={12} mt={15} mb={-12}>
                    <Grid p={10}>
                         <CardMedia component="img" sx={{ width: "100%", height: "100%" }} alt="mt4" image={windows} />
                    </Grid>
                    <Grid p={10}>
                         <CardMedia component="img" sx={{ width: "100%", height: "100%" }} alt="mt4" image={ios_logo} />

                    </Grid>
                    <Grid p={10}>
                         <CardMedia component="img" sx={{ width: "100%", height: "100%" }} alt="mt4" image={android} />

                    </Grid>
               </Grid> */}

               <Grid container xs={12} spacing={2} justifyContent={"center"} mt={15} mb={10}>
                    {/* <Grid container item direction={"row"} xs={12} justifyContent={"center"} alignContent={"center"} spacing={1}>
                    <Grid item xs={12} md={3}>
                         <CardMedia component="img" sx={{ width: "30%", height: "auto", justifyContent: "center" }} alt="mt4" image={windows} />
                         <CardMedia component="img" sx={{ width: "30%", height: "auto" }} alt="mt4" image={ios_logo} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                              <CardMedia component="img" sx={{ width: "30%", height: "auto" }} alt="mt4" image={ios_logo} />
                         </Grid>
                         <Grid item xs={12} md={4}>
                              <CardMedia component="img" sx={{ width: "30%", height: "auto" }} alt="mt4" image={android} />
                         </Grid>
                    </Grid> */}

                    {/* <Grid container item xs={12} md={12} justifyContent={"center"} spacing={2}>
                         <CardMedia component="img" sx={{ width: "50%", height: "50%" }} alt="mt4_logo" image={mt4_logo} />
                         <Grid container item direction={"row"} xs={12} justifyContent={"center"} alignContent={"center"} spacing={1}>
                              <button className={classes.btn} onClick={() => window.open("https://download.mql5.com/cdn/web/22281/mt4/qrsglobal4setup.exe")}>
                                   <Grid container item direction={"row"} justifyContent={"center"} alignContent={"center"}>
                                        <ComputerIcon />
                                        <Typography className={classes.fontTitle}>Windows Desktop</Typography>
                                   </Grid>
                              </button>
                              <button
                                   className={classes.btn}
                                   onClick={() => {
                                        window.open("https://download.mql5.com/cdn/mobile/mt4/ios?server=QRSGlobal-Demo,QRSGlobal-Real");
                                   }}
                              >
                                   <Grid container item direction={"row"} justifyContent={"center"} alignContent={"center"}>
                                        <CardMedia component="img" sx={{ width: 20, height: 20 }} alt="mt4" image={ios} />
                                        <Typography className={classes.fontTitle}>IOS</Typography>
                                   </Grid>
                              </button>
                              <button
                                   className={classes.btn}
                                   onClick={() => {
                                        window.open(
                                             "https://download.mql5.com/cdn/mobile/mt4/android?server=QRSGlobal-Demo,QRSGlobal-Real"
                                        );
                                   }}
                              >
                                   <Grid container item direction={"row"} justifyContent={"center"} alignContent={"center"}>
                                        <CardMedia component="img" sx={{ width: 25, height: 25 }} alt="mt4" image={google} />
                                        <Typography className={classes.fontTitle}>Google Play</Typography>
                                   </Grid>
                              </button>
                         </Grid>
                    </Grid> */}
                    <Grid container item xs={12} md={12} justifyContent={"center"} spacing={2} >
                         <CardMedia component="img" sx={{ width: "60%", height: "70%" }} alt="mt4_logo" image={mt5_logo} />
                         <Grid container item direction={"row"} xs={12} justifyContent={"center"} alignContent={"center"} spacing={1}>
                              <button className={classes.btn} onClick={() => window.open("https://download.mql5.com/cdn/web/qrs.global.llc/mt5/qrsglobal5setup.exe")}>
                                   <Grid container item direction={"row"} justifyContent={"center"} alignContent={"center"}>
                                        <ComputerIcon />
                                        <Typography className={classes.fontTitle}>Windows Desktop</Typography>
                                   </Grid>
                              </button>
                              <button
                                   className={classes.btn}
                                   onClick={() => {
                                        window.open("https://download.mql5.com/cdn/mobile/mt5/ios?server=QRSGlobal-Server");
                                   }}
                              >
                                   <Grid container item direction={"row"} justifyContent={"center"} alignContent={"center"}>
                                        <CardMedia component="img" sx={{ width: 20, height: 20 }} alt="mt4" image={ios} />
                                        <Typography className={classes.fontTitle}>IOS</Typography>
                                   </Grid>
                              </button>
                              <button
                                   className={classes.btn}
                                   onClick={() => {
                                        window.open(
                                             "https://download.mql5.com/cdn/mobile/mt5/android?server=QRSGlobal-Server"
                                        );
                                   }}
                              >
                                   <Grid container item direction={"row"} justifyContent={"center"} alignContent={"center"}>
                                        <CardMedia component="img" sx={{ width: 25, height: 25 }} alt="mt4" image={google} />
                                        <Typography className={classes.fontTitle}>Google Play</Typography>
                                   </Grid>
                              </button>
                              <button
                                   className={classes.btn}
                                   onClick={() => {
                                        window.open("https://download.mql5.com/cdn/web/metaquotes.ltd/mt5/MetaTrader5.pkg.zip?utm_source=support.metaquotes.net&utm_campaign=download.mt5.macos");
                                   }}
                              >
                                   <Grid container item direction={"row"} justifyContent={"center"} alignContent={"center"}>
                                        <CardMedia component="img" sx={{ width: 20, height: 20 }} alt="mt4" image={ios} />
                                        <Typography className={classes.fontTitle}>Mac OS</Typography>
                                   </Grid>
                              </button>
                         </Grid>
                    </Grid>
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles(() => ({
     fontStyleTitleBorder: {
          fontSize: 24,
          color: "#000",
          textAlign: "left",
          fontWeight: 600,
          marginTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
          borderBottom: "solid 2px #4589C6",
          marginBottom: 10,
     },
     fontTitle: {
          marginLeft: 10,
          marginRight: 20,
          fontSize: 22,
     },
     btn: {
          border: "0px",
          backgroundColor: "#fff",
          cursor: "pointer",
     },
}));

export default DownloadCard;
