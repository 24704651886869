import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, CardMedia, Typography, Grid, Button, Link } from "@material-ui/core";
import { textFiled } from "../../../functions/Static";
import { GridTextField, CardDetail } from "../../../components/index";
import logo_web from "../../../assets/logo/Logo-color.png";
import QRS_Logo from "../../../assets/logo/LOGOQrs.png";

import swal from "sweetalert2";
import { POST, GET, LOGINAPI, RESENDEMAILAPI } from "../../../service";
import LoadingPage from "components/LoadingPage/LoadingPage";

export class Login extends Component {
     constructor(props) {
          super(props);

          this.state = {
               inputValue: {},
               checkInput: {},
               step: 1,
               profile: {},
               language_state: localStorage.getItem("language"),
               loading: false,
          };
     }

     propHistory = (path) => {
          this.props.history.push(path);
     };
     handleChange = (e) => {
          let { checkInput, inputValue } = this.state;
          let name = e.target.name;
          let value = e.target.value;
          checkInput[name] = {
               err: value ? false : true,
               label: "",
          };
          inputValue[name] = value;
          this.setState({ inputValue, checkInput });
     };
     checkInput = () => {
          let state = this.state;
          let { checkInput, language_state } = this.state;
          let Check = textFiled.login.map((el) => el.name);
          Check.forEach((e) => {
               checkInput[e] = {
                    err: state.inputValue[e] ? false : true,
                    label: state.inputValue[e] ? "" : `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
               };
          });
          this.setState({ checkInput }, async () => {
               let { checkInput } = this.state;
               let input = Object.keys(checkInput).some((e) => checkInput[e].err);
               if (!input) {
                    this.setState({ checkInput: {} });
                    this.onSunmit();
               }
          });
     };
     resendEmail = async () => {
          const { language_state } = this.state;
          try {
               this.setState({ loading: true });
               let resend = await GET(RESENDEMAILAPI);
               if (resend.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "Success",
                         text: `${language_state === "th" ? "ส่ง Email ซ้ำสำเร็จ" : "Resend Email Success"}`,
                         icon: "success",
                         showConfirmButton: true,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "warning", text: resend.message, icon: "warning",  showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "warning", text: error, icon: "warning",  showConfirmButton: true });
          }
     };
     onSunmit = async () => {
          try {
               this.setState({ loading: true });
               let { inputValue } = this.state;
               let login = await POST(LOGINAPI, { email: inputValue.username, password: inputValue.password });
               if (login.success) {
                    if (login.result.status === 2) {
                         this.setState({ step: 2 });
                    } else if (login.result.status === 3) {
                         this.setState({ step: 3 });
                    } else {
                         window.location.assign("/dashboard");
                    }
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "warning", text: login.message, icon: "warning", showConfirmButton: true });
               }
          } catch (e) {
               this.setState({ loading: false });
               swal.fire({ title: "warning", text: e, icon: "warning", showConfirmButton: true });
          }
     };
     onClickJoin = () => {
          window.location.assign("/dashboard");
     };

     render() {
          const { inputValue, checkInput, step, loading } = this.state;
          const language = localStorage.getItem("language");
          const profile = this.props.profile;
          return (
               <Container maxWidth="xl">
                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                         <CardMedia sx={{ height: 80, width: "auto", objectFit: "contain", mb: 2 }} component="img" alt="Logo" image={QRS_Logo} />
                         {/* <Typography sx={{ color: "#fff", fontSize: 50, fontWeight: 600, mb: 3 }}>ATS-forex</Typography> */}
                         {step === 1 ? (
                              <Grid item xs={12}>
                                   <Typography sx={{ color: "#707070", fontSize: 35, mb: 3, textAlign: "center" }}>{language === "th" ? "Login" : "Login"}</Typography>
                                   <Grid container rowSpacing={3} mb={4}>
                                        <GridTextField
                                             data={textFiled.login}
                                             state={inputValue}
                                             check={checkInput}
                                             handleChange={this.handleChange}
                                             onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                       this.checkInput();
                                                  }
                                             }}
                                        />
                                   </Grid>
                                   <Grid container xs={12} direction="column" justifyContent="center" alignContent="center">
                                        <Button
                                             variant="contained"
                                             style={{
                                                  backgroundColor: "#4589C6",
                                             }}
                                             className="btn-style"
                                             sx={{ maxWidth: 260, mb: 2 }}
                                             onClick={this.checkInput}
                                        >
                                             {/* {language === "th" ? "เข้าสู่ระบบ" : "Login"} */}
                                             Login
                                        </Button>
                                        {/* <Button variant="outlined" color="error" className="btn-style" sx={{ maxWidth: 260, mb: 2 }} onClick={() => this.propHistory("/register")}>
                                             {language === "th" ? "ลงทะเบียน" : "Register"}
                                        </Button> */}
                                   </Grid>
                                   <Grid container xs={12} direction="column" justifyContent="flex-end" alignContent="center">
                                        <Link href="/register" color="#4589C6" sx={{ fontSize: 14, textAlign: "center",mb:1 }}>
                                             {/* {language === "th" ? "ลงทะเบียน" : "Register"} */}
                                             Register
                                        </Link>
                                   </Grid>
                                   <Grid container xs={12} direction="column" justifyContent="center" alignContent="center">
                                        <Link href="/forgot" color="#D4D4D4" sx={{ fontSize: 14, textAlign: "center" }}>
                                             {/* {language === "th" ? "ลืมรหัสผ่าน" : "Forgot password"} */}
                                             Forgot password
                                        </Link>
                                   </Grid>
                              </Grid>
                         ) : step === 2 ? (
                              <Grid>
                                   <Typography sx={{ color: "#fff", fontSize: 28 }}>Please check your email and click to confirm your email.</Typography>
                                   <Button variant="contained" 
                                   // color="error" 
                                   style={{backgroundColor:"#4589C6"}}
                                   className="btn-style" sx={{ maxWidth: 260, mb: 2, marginTop: 5 }} onClick={this.resendEmail}>
                                        {/* {language === "th" ? "ส่งเมลล์อีกครั้ง" : "Resend Email"} */}
                                        Resend Email
                                   </Button>
                              </Grid>
                         ) : (
                              <>
                                   {/* <CardDetail data={profile} page={"login"} /> */}
                                   <Button variant="contained" style={{ backgroundColor:"#4589C6" }} className="btn-style" sx={{ maxWidth: 260, mb: 2, marginTop: 5 }} onClick={this.onClickJoin}>
                                        {/* {language === "th" ? "เข้าร่วม" : "Join"} */}
                                        Join
                                   </Button>
                              </>
                         )}
                    </Grid>
                    <LoadingPage open={loading} />
               </Container>
          );
     }
}

export default withRouter(Login);
