import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Typography,
    Pagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { tofieds } from "functions/Functions";
const TableCommission = (props) => {

    const classes = useStyles();

    return (
        <Grid className="table-btn">
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {props.head.map((el, i) => {
                                return (
                                    <TableCell key={i} align={el.align || "left"}>
                                        <Typography noWrap>{el.label}</Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.body.map((el, i) => {
                            return (
                                <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                    <TableCell align="left">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.detail}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.money.toFixed(4) || "0.0000"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableCell>
                                            <Grid container alignContent="center">
                                                {el.type === 1 ? (
                                                    <Grid className={classes.fontActive} sx={{ backgroundColor: "#82C729" }}>
                                                        <Typography noWrap>Receive</Typography>
                                                    </Grid>
                                                ) : el.type === 2 ? (
                                                    <Grid className={classes.fontActive} sx={{ backgroundColor: "red" }}>
                                                        <Typography noWrap>Transfer</Typography>
                                                    </Grid>
                                                ) : (
                                                    <Grid className={classes.fontActive} sx={{ backgroundColor: "#FF0000" }}>
                                                        <Typography noWrap>Error</Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </TableCell>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 320,
    },
    fontActive: {
        fontSize: 15,
        color: "#fff",
        padding: 8,
        borderRadius: 10,
    },
});
export default TableCommission;
