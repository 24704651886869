import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import Routes from "./Routes";
import theme from "../theme/index";

export default class App extends Component {
     componentDidMount = () => {
          let data = localStorage.getItem("language");
          if (!data) {
               localStorage.setItem("language", "th");
          }
     };

     render() {
          return (
               <ThemeProvider theme={theme}>
                    <Router>
                         <Routes />
                    </Router>
               </ThemeProvider>
          );
     }
}
