import { Grid, Typography, Paper } from "@material-ui/core";
import { tofieds } from "functions/Functions";

const GridPaperIB = (props) => {

    return (
        <Grid container spacing={2}>
            <Grid item container mb={2} mt={1} justifyContent="space-between" rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    My Lot
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.my_lot)) || "0.00"} Lot</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Commission
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.commission)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Deposit
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.deposit)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Withdraw
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.withdraw)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Net Deposit
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.net_deposit)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Net Deposit Team
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.net_deposit_team)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Team Deposit
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.team_deposit)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Team Lot
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.team_lot)) || "0.00"} Lot</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GridPaperIB;
