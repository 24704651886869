import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Card, Stack, TextField, InputAdornment, Button } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const Trader = (props) => {
     const classes = useStyles();
     const { language, profile, onClickTranfer, state, handleChangeText, onClickRegisterIB } = props;
     // const [state, setState] = React.useState({
     //      open: false,
     // });
     // const handleOpen = () => setState({ ...state, open: true });
     // const handleClose = () => setState({ ...state, open: false });
     const [copied, setCopied] = useState(false);
     return (
          <Grid container columnSpacing={2} rowSpacing={2} justifyContent="center" alignItems="flex-start">
               <Grid item xs={12} md={6}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>IB INTERNAL TRANFERS TO WALLET</Typography>
                    <Grid container columnSpacing={2}>
                         <Grid item xs={12} sm={7}>
                              <Grid mt={3} className={classes.cardGridStyle}>
                                   <Typography sx={{ fontSize: 14 }}>Available funds in IB :</Typography>
                                   <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#4589C6" }}>
                                        ${profile.ib_wallet ? Math.floor(parseFloat(profile.ib_wallet) * 100) / 100 : "0.00"}
                                   </Typography>
                              </Grid>
                         </Grid>
                         <Grid item xs={12} sm={7}>
                              <Grid mt={3} className={classes.cardGridStyle}>
                                   <Typography sx={{ fontSize: 14 }}>Available funds in wallet : </Typography>
                                   <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#4589C6" }}>
                                        ${profile.wallet ? Math.floor(parseFloat(profile.wallet) * 100) / 100 : "0.00"}
                                   </Typography>
                              </Grid>
                         </Grid>
                    </Grid>
                    {profile.affiliate_url && (
                         <Grid item xs={12} container>
                              <Grid item xs={12} mt={2}>
                                   <Typography>{"https://portal.qrsfx.com/register" + profile.affiliate_url}</Typography>
                              </Grid>
                              <CopyToClipboard text={"https://portal.qrsfx.com/register" + profile.affiliate_url} onCopy={() => setCopied(true)}>
                                   <Grid container direction="row" justifyContent="flex-end" alignContent="flex-end">
                                        {copied && <Typography className={classes.detailStyleCopy}>{language === "th" ? "คัดลอกลิงค์แล้ว" : "Link is copied"}</Typography>}
                                        <Button variant="contained" 
                                        // color="error" 
                                        style={{
                                             backgroundColor: "#4589C6",
                                        }}
                                        sx={{ mt: 2 }}>
                                             Copy
                                        </Button>
                                   </Grid>
                              </CopyToClipboard>
                         </Grid>
                    )}
                    {/* {profile.ib_status === 2 && (
                         <Grid item xs={12} container>
                              <Button variant="contained" color="error" sx={{ mt: 2 }} onClick={() =>onClickRegisterIB()}>
                                   {language === 'th' ? 'สมัคร IB' : 'Register IB'}
                              </Button>
                         </Grid>
                    )} */}
               </Grid>
               <Grid item xs={12} md={6}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>IB TO WALLET</Typography>
                    <Card sx={{ borderRadius: 2, mt: 3 }}>
                         <Stack justifyContent="flex-start" alignItems="center" direction="row" sx={{ background: "#4589C6", color: "#fff", height: 60, px: 3 }}>
                              <Typography sx={{ fontSize: 16, fontWeight: 600, mr: 1 }}>IB PARTNER ACCOUNT:</Typography>
                              <Typography sx={{ fontSize: 16 }}>{profile.ibPartner}</Typography>
                         </Stack>
                         <Grid p={3}>
                              <Grid className="typography-profile">
                                   <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000" }}>Account Group : IB</Typography>
                                   <TextField
                                        className="textfield-profile"
                                        InputProps={{
                                             endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                        }}
                                        fullWidth
                                        size="small"
                                        value={state.money_tranfer}
                                        onChange={handleChangeText}
                                        name="money_tranfer"
                                        type="number"
                                        placeholder="0.00"
                                   />
                              </Grid>
                              <Grid mt={3} textAlign="center">
                                   <Button
                                        variant="contained"
                                        style={{ backgroundColor:"#4589C6" }}
                                        //  className="btn-style"
                                        sx={{ maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40 }}
                                        onClick={onClickTranfer}
                                   >
                                        IB TO Wallet
                                   </Button>
                              </Grid>
                         </Grid>
                    </Card>
               </Grid>
               {/* <ModalTranfers open={state.open} handleClose={handleClose} language={language} /> */}
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     cardGridStyle: {
          borderRadius: "5px",
          border: "solid 1px #ebebeb",
          background: "#fff",
          padding: 20,
     },
     detailStyleCopy: {
          fontSize: 14,
          color: "#000",
          fontWeight: 400,
          alignSelf: "flex-end",
          marginRight: 5,
     },
}));
export default withRouter(Trader);
