import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import { TransactionTD } from "pages/Trading/Trader";
import { GETTRADINGACCOUNT, TRADINGTRANSACTION, POST } from "service";
import swal from "sweetalert2";
import moment from "moment";
import ListTransaction from "./ListMarketing/ListTransaction";
import LoadingPage from "components/LoadingPage/LoadingPage";
import TapReportMeta from "./Tap/TapReportMeta";

export class ReportMetaTrader4 extends Component {
     constructor(props) {
          super(props);

          this.state = {
               page_td: 1,
               info_list: [{ label: "Select MT4 Accont", value: "text", disabled: true }],
               date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
               date_end: new Date(),
               mt4: "text",
               data_transaction_td: [],
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getInfo();
     };

     getInfo = async () => {
          let { info_list } = this.state;
          try {
               this.setState({ loading: true });
               let getInfo = await POST(GETTRADINGACCOUNT);
               
               if (getInfo.success) {
                    getInfo.result.real.map((el) => {
                         info_list.push({ label: el.mt4, value: el.mt4 });
                    });
                    this.setState({ info_list: info_list, loading: false });
               } else {
                    swal.fire({ title: "Warning", text: getInfo.message, icon: "warning",  showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "Warning", text: error, icon: "warning",  showConfirmButton: true });
          }
     };

     handleChangePage = (e, value) => {
          if (this.state.tap_value === 1) {
               this.setState({ page_td: value });
          } else {
               this.setState({ page_ib: value });
          }
     };

     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     submitSearch = async () => {
          let { mt4, date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          try {
               this.setState({ loading: true });
               let trading_transaction = await POST(TRADINGTRANSACTION, { mt4, date_start: date_start_format, date_end: date_end_format });
               if (trading_transaction.success) {
                    this.setState({ data_transaction_td: trading_transaction.result.data, loading: false, data_transaction: trading_transaction.result });
               } else {
                    swal.fire({ title: "warning", text: trading_transaction.message, icon: "warning", showConfirmButton: true });
                    this.setState({ loading: false });
               }
          } catch (error) {
               swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
          }
     };

     handleDateStartChange = (date) => {
          this.setState({ date_start: date });
     };

     handleDateEndChange = (date) => {
          this.setState({ date_end: date });
     };


     render() {
          const language = localStorage.getItem("language");
          const { data_transaction_td, page_td, info_list, loading,data_transaction } = this.state;
          return (
               <Container maxWidth="xl">
                    <LoadingPage open={loading} />
                    <TapReportMeta />
                    <ListTransaction
                         language={language}
                         data={data_transaction_td}
                         data_transaction={data_transaction}
                         page={page_td}
                         handleChangePage={this.handleChangePage}
                         handleDateStartChange={this.handleDateStartChange}
                         handleDateEndChange={this.handleDateEndChange}
                         handleSearch={this.handleSearch}
                         state={this.state}
                         submitSearch={this.submitSearch}
                         info_list={info_list}
                    />
               </Container>
          );
     }
}

export default withRouter(ReportMetaTrader4);
