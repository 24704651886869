import { Button, Grid, Typography } from "@material-ui/core";
import { GridTextField } from "components";
import ReactQuill from "react-quill";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";

const AddSupportCard = (props) => {
     const { language, textField_info, state, handleChange, onChangeTextEdit, onClickAdd, type_support, onClickSelect } = props;
     return (
          <Grid container spacing={2} mt={5}>
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                         {language === "th" ? "สร้างใบคำสั่ง" : "Create Support"}
                    </Typography>
               </Grid>
               <Grid container spacing={2} item xs={12} md={6}>
                    <Grid item xs={12}>
                         <Typography mb={2}>ประเภทใบคำสั่ง</Typography>
                         {type_support.map((el, i) => (
                              <Button
                                   key={i}
                                   variant={el.ticket_type_id === state.type_select ? "contained" : "outlined"}
                                   color="error"
                                   sx={{ maxWidth: 120, fontSize: 16, width: "100%", height: 40, mr: 2 }}
                                   onClick={() => onClickSelect(el.ticket_type_id)}
                              >
                                   {el.type_name}
                              </Button>
                         ))}
                    </Grid>
                    <Grid item xs={12}>
                         <Typography mb={2}>ชื่อใบคำสั่ง</Typography>
                         <GridTextField data={textField_info.add_support} language={language} state={state} handleChange={handleChange} />
                    </Grid>
               </Grid>
               <Grid item xs={12} mb={10}>
                    <Typography mb={2}>รายละเอียดของปัญหา</Typography>
                    <ReactQuill theme="snow" value={state.value_edit} onChange={onChangeTextEdit} style={{ width: "100%", height: "30vh" }} />
               </Grid>
               <Grid item xs={12} container>
                    <Button variant="contained" 
                    // color="error"
                    style={{backgroundColor:"#4589C6"}}
                     sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }} onClick={onClickAdd}>
                         {language === "th" ? "ส่งใบคำสั่ง" : "Send"}
                    </Button>
               </Grid>
          </Grid>
     );
};

export default AddSupportCard;
