import React, { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { NavLink as RoutLink } from "react-router-dom";
// import clsx from "clsx";
// import { makeStyles } from "@material-ui/styles";
import {
     AppBar,
     Toolbar,
     Typography,
     Grid,
     CardMedia,
     Container,
     Box,
     Avatar,
     CardActionArea,
     Popover,
     Backdrop,
     IconButton,
     Menu,
     MenuItem,
     Link,
     Collapse,
     ListItemButton,
     ListItemText,
     List,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// import { ip } from "../../service/service";
// import swal from "sweetalert2";
// import moment from "moment";
import logo_web from "../../assets/logo/Logo-color.png";
import th from "../../assets/logo/ThailandFlag.png";
import en from "../../assets/logo/ENFlag.png";
import avatar from "../../assets/logo/avatar@2x.png";
// import bell from "../../assets/logo/bell.png";
import loadingSvg from "../../assets/logo/LOGOQrs.png";
import { ip } from "service";
import MenuIcon from "@material-ui/icons/Menu";
import { sideBar } from "functions/Static";
import TitleHeader from "layout/PageLayout/TitleHeader";
import { useLocation } from "react-router-dom";


const MainHeader = (props) => {
     const { language, profile } = props;
     const changeLangauge = () => {
          handleClosePop();
          handleOpenLoading();
          let data = localStorage.getItem("language");
          localStorage.setItem("language", data === "th" ? "en" : "th");
          setTimeout(() => {
               // handleCloseLoading();
               window.location.reload();
          }, 3000);
     };
     //---------------------------------------------------------------//
     //---------------------------------------------------------------//
     const [anchorEl, setOpenPop] = React.useState(null);
     const [open, setOpen] = React.useState(false);
     const [openSub, setOpenSub] = React.useState(false);
     const location = useLocation();

     const handleOpenPop = (event) => {
          setOpenPop(event.currentTarget);
     };
     const handleClosePop = () => {
          setOpenPop(null);
     };
     const openpop = Boolean(anchorEl);
     const id = openpop ? "simple-popover" : undefined;
     //---------------------------------------------------------------//
     //---------------------------------------------------------------//
     const [openLoading, setOpenLoading] = React.useState(false);
     const handleOpenLoading = () => {
          setOpenLoading(!openLoading);
     };
     //---------------------------------------------------------------//
     //---------------------------------------------------------------//
     const [anchorEl_openMenu, setAnchorEl_openMenu] = React.useState(null);

     const handleClick = (event) => {
          setAnchorEl_openMenu(event.currentTarget);
     };

     const handleClose = () => {
          setAnchorEl_openMenu(null);
     };

     const handleClickCollapse = () => {
          setOpen(!open);
     };

     const handleClickCollapseSub = () => {
          setOpenSub(!openSub);
     };
     const CustomRouterLink = forwardRef((props, ref) => (
          <Grid ref={ref} style={{ flexGrow: 1 }}>
               <RoutLink {...props} />
          </Grid>
     ));
     return (
          <AppBar
               position="absolute"
               sx={{
                    boxShadow: "none",
                    background: "rgba(0, 0, 0, 0)",
               }}
          >
               <Container maxWidth="xl" className="main-header">
                    <Toolbar>
                         {/* <RouterLink to="/dashboard">
                              <CardMedia component="img" sx={{ height: 40, width: "auto" }} alt="Logo" image={logo_web} />
                         </RouterLink> */}
                         {/* <TitleHeader language={language} /> */}
                         <Grid item xs="auto" sx={{ display: { xs: "block", sm: "none" } }} >
                              <IconButton sx={{ alignItems: "center", color: "#fff" }} onClick={handleClick}>
                                   <MenuIcon fontSize="large" />
                              </IconButton>
                              <Menu id="simple-menu" anchorEl={anchorEl_openMenu} keepMounted open={Boolean(anchorEl_openMenu)} onClose={handleClose}>
                                   {sideBar.two.map((el, i) => (
                                        <MenuItem >
                                             {el.submenu1 ?
                                                  <>
                                                       {el.th === "IB" ?
                                                            <>
                                                                 <List>
                                                                      <ListItemText className="title-menu" onClick={handleClickCollapseSub}>{language === "th" ? el.th : el.en}</ListItemText>
                                                                      <Collapse in={openSub} timeout="auto" unmountOnExit>
                                                                           <List onClick={handleClickCollapseSub} component="div" disablePadding>
                                                                                <ListItemButton onClick={handleClose} className="link-head-minor-menu" component={CustomRouterLink} to={el.submenu1.link}>
                                                                                     <ListItemText className="title-menu" primary={language === "th" ? el.submenu1.th : el.submenu1.en} />
                                                                                </ListItemButton>
                                                                                <ListItemButton onClick={handleClose} className="link-head-minor-menu" component={CustomRouterLink} to={el.submenu2.link}>
                                                                                     <ListItemText className="title-menu" primary={language === "th" ? el.submenu2.th : el.submenu2.en} />
                                                                                </ListItemButton>
                                                                                <ListItemButton onClick={handleClose} className="link-head-minor-menu" component={CustomRouterLink} to={el.submenu3.link}>
                                                                                     <ListItemText className="title-menu" primary={language === "th" ? el.submenu3.th : el.submenu3.en} />
                                                                                </ListItemButton>
                                                                           </List>
                                                                      </Collapse>
                                                                 </List>
                                                            </>
                                                            :
                                                            <>
                                                                 <List>
                                                                      <ListItemText className="title-menu" onClick={handleClickCollapse}>{language === "th" ? el.th : el.en}</ListItemText>
                                                                      <Collapse in={open} timeout="auto" unmountOnExit>
                                                                           <List onClick={handleClickCollapse} component="div" disablePadding>
                                                                                {/* <Link onClick={handleClose} className="link-head-minor-menu" component={CustomRouterLink} to={el.submenu1.link}>
                                                                      <Typography ml={12} className="title-menu">{language === "th" ? el.submenu1.th : el.submenu1.en}</Typography>
                                                                 </Link> */}
                                                                                <ListItemButton onClick={handleClose} className="link-head-minor-menu" component={CustomRouterLink} to={el.submenu1.link}>
                                                                                     <ListItemText className="title-menu" primary={language === "th" ? el.submenu1.th : el.submenu1.en} />
                                                                                </ListItemButton>
                                                                           </List>
                                                                      </Collapse>
                                                                 </List>
                                                            </>
                                                       }
                                                  </>
                                                  :
                                                  <>

                                                       {el.th === "คัดลอกการซื้อขาย" ?
                                                            <Link onClick={handleClose} className="link-head-minor-menu" href={el.link} target="_blank">
                                                                 <Typography className="title-menu">{language === "th" ? el.th : el.en}</Typography>
                                                            </Link>
                                                            :
                                                            <Link onClick={handleClose} className="link-head-minor-menu" component={CustomRouterLink} to={el.link}>
                                                                 <Typography className="title-menu">{language === "th" ? el.th : el.en}</Typography>
                                                            </Link>
                                                       }
                                                  </>
                                             }
                                        </MenuItem>
                                   ))}
                              </Menu>
                         </Grid>
                         <Box sx={{ flexGrow: 1, display: { xs: "block", sm: "none" } }}>
                              <Grid spacing={2} container justifyContent="flex-end" alignItems="center">
                                   <Grid item xs="auto">
                                        <Avatar alt="Username" src={profile ? ip + profile.profile_image : avatar} />
                                   </Grid>
                                   <Grid item xs="auto" sx={{ display: "flex", alignItems: "center" }}>
                                        <Grid sx={{ height: 25, borderLeft: "solid 1px #989494" }} />
                                        {language === "th" ? (
                                             <CardActionArea aria-describedby={id} onClick={handleOpenPop} sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                                                  <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={th} />
                                             </CardActionArea>
                                        ) : (
                                             <CardActionArea aria-describedby={id} onClick={handleOpenPop} sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                                                  <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={en} />
                                             </CardActionArea>
                                        )}
                                        <Popover
                                             id={id}
                                             open={openpop}
                                             anchorEl={anchorEl}
                                             onClose={handleClosePop}
                                             anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "right",
                                             }}
                                             transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                             }}
                                        >
                                             <Typography py={1} className="main-pop">
                                                  {language !== "th" ? (
                                                       <CardActionArea onClick={changeLangauge} sx={{ pl: 2, pr: 2, display: "flex", alignItems: "center" }}>
                                                            <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={th} />
                                                            <Typography className="title-menu">ภาษาไทย</Typography>
                                                       </CardActionArea>
                                                  ) : (
                                                       <CardActionArea onClick={changeLangauge} sx={{ pl: 2, pr: 2, display: "flex", alignItems: "center" }}>
                                                            <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={en} />
                                                            <Typography className="title-menu">English</Typography>
                                                       </CardActionArea>
                                                  )}
                                             </Typography>
                                        </Popover>
                                   </Grid>
                              </Grid>
                         </Box>
                         <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
                              <Grid container spacing={3} direction="row" justifyContent="flex-end" alignItems="center">
                                   <Grid item xs="auto">
                                        <Avatar alt="Username" src={profile ? ip + profile.profile_image : avatar} />
                                   </Grid>
                                   <Grid item xs="auto">
                                        <Typography className="title-menu">
                                             {profile && profile.name} {profile.lastname} ({profile.user_id})
                                        </Typography>
                                   </Grid>
                                   {/* <Grid item xs="auto">
                                        <CardActionArea>
                                             <Badge variant="dot" color="error">
                                                  <CardMedia component="img" sx={{ height: 20, width: 20 }} alt="Bell" image={bell} />
                                             </Badge>
                                        </CardActionArea>
                                   </Grid> */}
                                   <Grid item xs="auto" sx={{ display: "flex", alignItems: "center" }}>
                                        <Grid sx={{ height: 25, borderLeft: "solid 1px #989494" }} />
                                        {language === "th" ? (
                                             <CardActionArea aria-describedby={id} onClick={handleOpenPop} sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                                                  <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={th} />
                                                  <Typography className="title-menu">ภาษาไทย</Typography>
                                             </CardActionArea>
                                        ) : (
                                             <CardActionArea aria-describedby={id} onClick={handleOpenPop} sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                                                  <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={en} />
                                                  <Typography className="title-menu">English</Typography>
                                             </CardActionArea>
                                        )}
                                        <Popover
                                             id={id}
                                             open={openpop}
                                             anchorEl={anchorEl}
                                             onClose={handleClosePop}
                                             anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "right",
                                             }}
                                             transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                             }}
                                        >
                                             <Typography py={1} className="main-pop">
                                                  {language !== "th" ? (
                                                       <CardActionArea onClick={changeLangauge} sx={{ pl: 2, pr: 2, display: "flex", alignItems: "center" }}>
                                                            <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={th} />
                                                            <Typography className="title-menu">ภาษาไทย</Typography>
                                                       </CardActionArea>
                                                  ) : (
                                                       <CardActionArea onClick={changeLangauge} sx={{ pl: 2, pr: 2, display: "flex", alignItems: "center" }}>
                                                            <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={en} />
                                                            <Typography className="title-menu">English</Typography>
                                                       </CardActionArea>
                                                  )}
                                             </Typography>
                                        </Popover>
                                   </Grid>
                              </Grid>
                         </Box>
                    </Toolbar>
               </Container>
               <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openLoading}>
                    <Grid className="loading-animation">
                         <CardMedia component="img" className="loadingSvg" alt="loadingSvg" image={loadingSvg} />
                    </Grid>
               </Backdrop>
          </AppBar>
     );
};

export default withRouter(MainHeader);





// return (
//      <AppBar
//           position="absolute"
//           sx={{
//                boxShadow: "none",
//                background: "rgba(0, 0, 0, 0.18)",
//           }}
//      >
//           <Container maxWidth="xl" className="main-header">
//                <Toolbar>
//                     <RouterLink to="/dashboard">
//                          <CardMedia component="img" sx={{ height: 40, width: "auto" }} alt="Logo" image={logo_web} />
//                     </RouterLink>
//                     <Grid item xs="auto"sx={{ display: { xs: "block", sm: "none" } }} >
//                          <IconButton sx={{ alignItems: "center", color: "#fff" }} onClick={handleClick}>
//                               <MenuIcon fontSize="large" />
//                          </IconButton>
//                          <Menu id="simple-menu" anchorEl={anchorEl_openMenu} keepMounted open={Boolean(anchorEl_openMenu)} onClose={handleClose}>
//                               {sideBar.two.map((el, i) => (
//                                    <MenuItem onClick={handleClose}>
//                                         <Link className="link-head-minor-menu" component={CustomRouterLink} to={el.link}>
//                                              <Typography className="title-menu">{language === "th" ? el.th : el.en}</Typography>
//                                         </Link>
//                                    </MenuItem>
//                               ))}
//                          </Menu>
//                     </Grid>
//                     <Box sx={{ flexGrow: 1, display: { xs: "block", sm: "none" } }}>
//                          <Grid spacing={2} container justifyContent="flex-end" alignItems="center">
//                               <Grid item xs="auto">
//                                    <Avatar alt="Username" src={profile ? ip + profile.profile_image : avatar} />
//                               </Grid>
//                               <Grid item xs="auto" sx={{ display: "flex", alignItems: "center" }}>
//                                    <Grid sx={{ height: 25, borderLeft: "solid 1px #989494" }} />
//                                    {language === "th" ? (
//                                         <CardActionArea aria-describedby={id} onClick={handleOpenPop} sx={{ display: "flex", alignItems: "center", ml: 2 }}>
//                                              <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={th} />
//                                         </CardActionArea>
//                                    ) : (
//                                         <CardActionArea aria-describedby={id} onClick={handleOpenPop} sx={{ display: "flex", alignItems: "center", ml: 2 }}>
//                                              <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={en} />
//                                         </CardActionArea>
//                                    )}
//                                    <Popover
//                                         id={id}
//                                         open={openpop}
//                                         anchorEl={anchorEl}
//                                         onClose={handleClosePop}
//                                         anchorOrigin={{
//                                              vertical: "bottom",
//                                              horizontal: "right",
//                                         }}
//                                         transformOrigin={{
//                                              vertical: "top",
//                                              horizontal: "right",
//                                         }}
//                                    >
//                                         <Typography py={1} className="main-pop">
//                                              {language !== "th" ? (
//                                                   <CardActionArea onClick={changeLangauge} sx={{ pl: 2, pr: 2, display: "flex", alignItems: "center" }}>
//                                                        <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={th} />
//                                                        <Typography className="title-menu">ภาษาไทย</Typography>
//                                                   </CardActionArea>
//                                              ) : (
//                                                   <CardActionArea onClick={changeLangauge} sx={{ pl: 2, pr: 2, display: "flex", alignItems: "center" }}>
//                                                        <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={en} />
//                                                        <Typography className="title-menu">English</Typography>
//                                                   </CardActionArea>
//                                              )}
//                                         </Typography>
//                                    </Popover>
//                               </Grid>
//                          </Grid>
//                     </Box>
//                     <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
//                          <Grid container spacing={3} direction="row" justifyContent="flex-end" alignItems="center">
//                               <Grid item xs="auto">
//                                    <Avatar alt="Username" src={profile ? ip + profile.profile_image : avatar} />
//                               </Grid>
//                               <Grid item xs="auto">
//                                    <Typography className="title-menu">
//                                         {profile && profile.name} {profile.lastname}
//                                    </Typography>
//                               </Grid>
//                               {/* <Grid item xs="auto">
//                                    <CardActionArea>
//                                         <Badge variant="dot" color="error">
//                                              <CardMedia component="img" sx={{ height: 20, width: 20 }} alt="Bell" image={bell} />
//                                         </Badge>
//                                    </CardActionArea>
//                               </Grid> */}
//                               <Grid item xs="auto" sx={{ display: "flex", alignItems: "center" }}>
//                                    <Grid sx={{ height: 25, borderLeft: "solid 1px #989494" }} />
//                                    {language === "th" ? (
//                                         <CardActionArea aria-describedby={id} onClick={handleOpenPop} sx={{ display: "flex", alignItems: "center", ml: 2 }}>
//                                              <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={th} />
//                                              <Typography className="title-menu">ภาษาไทย</Typography>
//                                         </CardActionArea>
//                                    ) : (
//                                         <CardActionArea aria-describedby={id} onClick={handleOpenPop} sx={{ display: "flex", alignItems: "center", ml: 2 }}>
//                                              <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={en} />
//                                              <Typography className="title-menu">English</Typography>
//                                         </CardActionArea>
//                                    )}
//                                    <Popover
//                                         id={id}
//                                         open={openpop}
//                                         anchorEl={anchorEl}
//                                         onClose={handleClosePop}
//                                         anchorOrigin={{
//                                              vertical: "bottom",
//                                              horizontal: "right",
//                                         }}
//                                         transformOrigin={{
//                                              vertical: "top",
//                                              horizontal: "right",
//                                         }}
//                                    >
//                                         <Typography py={1} className="main-pop">
//                                              {language !== "th" ? (
//                                                   <CardActionArea onClick={changeLangauge} sx={{ pl: 2, pr: 2, display: "flex", alignItems: "center" }}>
//                                                        <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={th} />
//                                                        <Typography className="title-menu">ภาษาไทย</Typography>
//                                                   </CardActionArea>
//                                              ) : (
//                                                   <CardActionArea onClick={changeLangauge} sx={{ pl: 2, pr: 2, display: "flex", alignItems: "center" }}>
//                                                        <CardMedia component="img" sx={{ height: 14, width: "auto", mr: 1 }} alt="Logo" image={en} />
//                                                        <Typography className="title-menu">English</Typography>
//                                                   </CardActionArea>
//                                              )}
//                                         </Typography>
//                                    </Popover>
//                               </Grid>
//                          </Grid>
//                     </Box>
//                </Toolbar>
//           </Container>
//           <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openLoading}>
//                <Grid className="loading-animation">
//                     <CardMedia component="img" className="loadingSvg" alt="loadingSvg" image={loadingSvg} />
//                </Grid>
//           </Backdrop>
//      </AppBar>
// );
// };

// export default withRouter(MainHeader);
