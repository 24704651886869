import React, { forwardRef, useEffect } from "react";
import { Footer, MainHeader, MinorHeader } from "../../components/index";
import {
     Box,
     drawerWidth,
     Toolbar,
     Typography,
     Grid,
     Link,
     Container,
     TableContainer,
     Table,
     AppBar,
     CssBaseline,
     Divider,
     Drawer,
     IconButton,
     InboxIcon,
     List,
     ListItem,
     ListItemButton,
     ListItemIcon,
     ListItemText,
     MailIcon,
     CardMedia,
     Collapse,
     // MenuIcon,
} from "@material-ui/core";
import TitleHeader from "./TitleHeader";
import { sideBar } from "../../functions/Static";
import { NavLink as RoutLink } from "react-router-dom";
import QRS_Broker from "../../assets/logo/LOGOQrs.png";
import { useLocation } from "react-router-dom";
import TitleQrs from "./TitleQrs";
import '../../css/index.scss'

// const location = useLocation()

const CustomRouterLink = forwardRef((props, ref) => (
     <Grid ref={ref} style={{ flexGrow: 1 }}>
          <RoutLink {...props} />
     </Grid>
));

const PageLayout = (props) => {
     const language = localStorage.getItem("language");
     const drawerWidth = 240;
     const { window } = props;
     const [mobileOpen, setMobileOpen] = React.useState(false);
     const [open, setOpen] = React.useState(false);
     const [openSub2, setOpenSub2] = React.useState(false);
     const location = useLocation();

     // {
     //      console.log("location", location);
     // }

     const handleDrawerToggle = () => {
          setMobileOpen(!mobileOpen);
     };

     const handleClick = () => {
          setOpen(!open);
     };

     const handleClickSub = () => {
          setOpenSub2(!openSub2);
     };

     const drawer = (
          <Grid sx={{ p: 2, ml: 1 }}>
               {/* <Toolbar /> */}
               {/* <img src={QRS_Broker} /> */}
               <CardMedia sx={{ height: 50, width: "auto", objectFit: "contain", mb: 3, ml: 2 }} component="img" alt="Logo" image={QRS_Broker} />
               <Divider />
               <List>
                    {sideBar.two.map((text, index) => (
                         <div key={index} disablePadding>
                              {/* {text.submenu1 || text.submenu2 ? ( */}
                              {text.submenu1 ? (
                                   <>
                                        {text.th === "IB" ?
                                             <>
                                                  <ListItemButton component={CustomRouterLink} to={text.link} onClick={handleClickSub}>
                                                       {text.link === location.pathname ? (
                                                            <ListItemText primary={language === "th" ? text.th : text.en} style={{ color: "#4589C6" }} />
                                                       ) : (
                                                            <ListItemText primary={language === "th" ? text.th : text.en} />
                                                       )}
                                                  </ListItemButton>
                                                  <Collapse in={openSub2} timeout="auto" unmountOnExit>
                                                       <List component="div" disablePadding>
                                                            <ListItemButton sx={{ pl: 4 }} component={CustomRouterLink} to={text.submenu1.link}>
                                                                 {text.submenu1.link === location.pathname ? (
                                                                      <ListItemText primary={language === "th" ? text.submenu1.th : text.submenu1.en} style={{ color: "#4589C6" }} />
                                                                 ) : (
                                                                      <ListItemText primary={language === "th" ? text.submenu1.th : text.submenu1.en} />
                                                                 )}
                                                            </ListItemButton>
                                                            <ListItemButton sx={{ pl: 4 }} component={CustomRouterLink} to={text.submenu2.link}>
                                                                 {text.submenu2.link === location.pathname ? (
                                                                      <ListItemText primary={language === "th" ? text.submenu2.th : text.submenu2.en} style={{ color: "#4589C6" }} />
                                                                 ) : (
                                                                      <ListItemText primary={language === "th" ? text.submenu2.th : text.submenu2.en} />
                                                                 )}
                                                            </ListItemButton>
                                                            <ListItemButton sx={{ pl: 4 }} component={CustomRouterLink} to={text.submenu3.link}>
                                                                 {text.submenu3.link === location.pathname ? (
                                                                      <ListItemText primary={language === "th" ? text.submenu3.th : text.submenu3.en} style={{ color: "#4589C6" }} />
                                                                 ) : (
                                                                      <ListItemText primary={language === "th" ? text.submenu3.th : text.submenu3.en} />
                                                                 )}
                                                            </ListItemButton>
                                                       </List>
                                                  </Collapse>
                                             </>
                                             :
                                             <>
                                                  <ListItemButton component={CustomRouterLink} to={text.link} onClick={handleClick}>
                                                       {text.link === location.pathname ? (
                                                            <ListItemText primary={language === "th" ? text.th : text.en} style={{ color: "#4589C6" }} />
                                                       ) : (
                                                            <ListItemText primary={language === "th" ? text.th : text.en} />
                                                       )}
                                                  </ListItemButton>
                                                  <Collapse in={open} timeout="auto" unmountOnExit>
                                                       <List component="div" disablePadding>
                                                            <ListItemButton sx={{ pl: 4 }} component={CustomRouterLink} to={text.submenu1.link}>
                                                                 {text.submenu1.link === location.pathname ? (
                                                                      <ListItemText primary={language === "th" ? text.submenu1.th : text.submenu1.en} style={{ color: "#4589C6" }} />
                                                                 ) : (
                                                                      <ListItemText primary={language === "th" ? text.submenu1.th : text.submenu1.en} />
                                                                 )}
                                                            </ListItemButton>
                                                       </List>
                                                  </Collapse>
                                             </>
                                        }
                                   </>
                              ) : (
                                   <>
                                        {text.th === "คัดลอกการซื้อขาย" ?
                                             <a className="link-head-minor-menu" style={{ textDecoration: "none" }} href={text.link} target="_blank">
                                                  <ListItemButton>
                                                       <ListItemText primary={language === "th" ? text.th : text.en} />
                                                  </ListItemButton>
                                             </a>
                                             :
                                             <ListItemButton component={CustomRouterLink} to={text.link}>
                                                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}

                                                  {text.link === location.pathname ? (
                                                       <ListItemText primary={language === "th" ? text.th : text.en} style={{ color: "#4589C6" }} />
                                                  ) : (
                                                       <ListItemText primary={language === "th" ? text.th : text.en} />
                                                  )}
                                             </ListItemButton>
                                        }
                                   </>
                              )}
                         </div>
                    ))}
               </List>

               {/* <Divider /> */}
          </Grid>
     );

     const container = window !== undefined ? () => window().document.body : undefined;

     return (
          <Box sx={{ display: "flex" }}>
               <CssBaseline />

               <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Drawer
                         container={container}
                         variant="temporary"
                         open={mobileOpen}
                         onClose={handleDrawerToggle}
                         ModalProps={{
                              keepMounted: true, // Better open performance on mobile.
                         }}
                         sx={{
                              display: { xs: "block", sm: "none" },
                              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                         }}
                    >
                         {drawer}
                    </Drawer>
                    <Drawer
                         variant="permanent"
                         sx={{
                              display: { xs: "none", sm: "block" },
                              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                         }}
                         open
                    >
                         {drawer}
                    </Drawer>
               </Box>

               <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    {/* <Toolbar /> */}
                    {/* <TitleHeader language={language}/> */}
                    <MainHeader language={language} profile={props.profile} />

                    <TitleQrs language={language} profile={props.profile} />

                    <Grid sx={{ position: "relative", background: "white", pt: 5, pb: 10, backgroundColor: "#fff" }}>{props.children}</Grid>
                    <Footer language={language} />
               </Box>
          </Box>
     );

     // <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
     //      {/* <Toolbar /> */}
     //      {/* <MainHeader language={language} profile={props.profile} /> */}

     //      <div >
     //           <MinorHeader language={language} />
     //           <div>
     //                <TitleHeader language={language} />
     //                <Grid sx={{ position: "relative", background: "white", width: "100vw", pt: 5, pb: 10, backgroundColor: "#fff" }}>{props.children}</Grid>
     //                {/* <Footer language={language} /> */}
     //           </div>
     //      </div>
     // </Box>

     // // <div>
     // //      <MinorHeader language={language} />
     // //           {/* <TitleHeader language={language} />
     // //           <Grid sx={{ position: "relative", background: "white", width: "100vw", pt: 5, pb: 10, backgroundColor: "#fff" }}>{props.children}</Grid> */}

     // // </div>
     // );
};
export default PageLayout;
