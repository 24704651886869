import React from "react";
import { makeStyles } from "@material-ui/styles";
import { CardMedia, Typography, Grid, Card, Avatar } from "@material-ui/core";
import logo_color from "../../assets/logo/Logo-color.png";
import icon_true from "../../assets/logo/icon-true@2x.png";
import icon_false from "../../assets/logo/invite-false.png";
import avatar from "../../assets/logo/avatar@2x.png";
import { ip } from "service";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const CardDetail = (props) => {
     const classes = useStyles();
     const { data, page } = props;
     return (
          <Card className={classes.cardStyle}>
               <Grid sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
                    <CardMedia className={classes.cardImg} component="img" alt="Logo" image={logo_color} />
                    <Grid textAlign="right">
                         <Grid sx={{ display: "flex", alignItems: "center" }}>
                              {data.verify_doc === 4 ? (
                                   <HourglassFullIcon fontSize="small" style={{ color: "#4082ef" }} />
                              ) : (
                                   <CardMedia className={classes.cardIcon} component="img" alt="Logo" image={data.verify_doc === 1 ? icon_true : icon_false} />

                              )}
                              <Typography className={classes.cardTitle}>
                                   {data.name} {data.lastname}
                              </Typography>
                         </Grid>
                         <Typography sx={{ fontSize: 12, color: "#fff" }}>{data.phone}</Typography>
                    </Grid>
               </Grid>
               <Typography sx={{ fontSize: 24, color: "#fff", mb: 4 }}>TRADER CARD</Typography>
               <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid container spacing={0} alignItems="flex-end">
                         <Typography className={classes.cardTitle}>{data.email}</Typography>
                    </Grid>
                    <Avatar alt="avatar" src={data.profile_image ? ip + data.profile_image : avatar} sx={{ width: 50, height: 50 }} />
               </Grid>
          </Card>
     );
};
const useStyles = makeStyles(() => ({
     cardImg: {
          height: 30,
          objectFit: "contain",
          width: "auto",
          [theme.breakpoints.up("sm")]: {
               height: 48,
          },
     },
     cardTitle: {
          fontSize: 16,
          color: "#fff",
          [theme.breakpoints.up("sm")]: {
               fontSize: 20,
          },
     },
     cardIcon: {
          height: 13,
          objectFit: "contain",
          width: "auto",
          marginRight: 4,
          [theme.breakpoints.up("sm")]: {
               height: 17,
          },
     },
     cardStyle: {
          background: "linear-gradient(133deg, #0a66de -10%, #fc0316 94%)",
          padding: 16,
          width: "100%",
          height: "200px",
          // minWidth: "400px",
          maxWidth: "400px",
          [theme.breakpoints.up("sm")]: {
               padding: 24,
          },
     },
}));
export default CardDetail;
