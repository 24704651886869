import React from "react";
import { Grid, Tabs, Tab, TableContainer,Table } from "@material-ui/core";

const DetailTaps = (props) => {
     const [value, setValue] = React.useState(1);
     const handleChange = (event, newValue) => {
          setValue(newValue);
          props.setTap(newValue);
     };
     return (
          <Grid container justifyContent="center" alignItems="center">
               <Grid item xs={12} className="taps-css" sx={{ display: { xs: "none", md: "block" } ,borderBottom: 1, borderColor: "#4589C6" }}>
                    <Tabs value={value} onChange={handleChange} centered variant="fullWidth">
                         <Tab value={1} label="Deposit Funds" />
                         <Tab value={2} label="Withdrawal Funds" />
                    </Tabs>
               </Grid>
               <Grid item xs={12} className="taps-css-mobile-financail" sx={{ display: { xs: "block", md: "none" } ,borderBottom: 1, borderColor: "#4589C6" }}>
                    <TableContainer>
                         <Table sx={{ minWidth: 270 }}>
                              <Tabs value={value} onChange={handleChange} centered variant="fullWidth">
                                   <Tab value={1} label="Deposit Funds" />
                                   <Tab value={2} label="Withdrawal Funds" />
                              </Tabs>
                         </Table>
                    </TableContainer>
               </Grid>
          </Grid>
     );
};
export default DetailTaps;
