import { Backdrop, Fade, Modal, Grid, Typography, FormControl, FormControlLabel, Radio, RadioGroup, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const ModalChangeLeverage = (props) => {
     const { open, handleClose, data, language, onChangeRadio, onClickSubmitChangeLeverage, state } = props;
     const classes = useStyles();
     let leverage_select = JSON.parse(state.leverage_select)
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               onClose={handleClose}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
          >
               <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container rowSpacing={3} justifyContent="center" alignItems="center">
                              <Grid item xs={12} textAlign="center">
                                   <Typography sx={{ fontSize: 24, fontWeight: 600 }}>{language === "th" ? "เปลี่ยนแปลง Leverage" : "Change Leverage"}</Typography>
                              </Grid>
                              <Grid container item xs={12} justifyContent={"center"} alignContent={"center"}>
                                   <FormControl component="fieldset">
                                        <RadioGroup row aria-label="position" value={state.leverage} name="position" defaultValue="top">
                                             {leverage_select ? leverage_select.map(e=>(
                                                  <FormControlLabel value={e} control={<Radio color="primary" />} label={`1:${e}`} onChange={onChangeRadio} />
                                             ))
                                        :null}
                                             {/* <FormControlLabel value="50" control={<Radio color="primary" />} label="1:50" onChange={onChangeRadio} /> */}
                                             {/* <FormControlLabel value="200" control={<Radio color="primary" />} label="1:200" onChange={onChangeRadio} />
                                             <FormControlLabel value="500" control={<Radio color="primary" />} label="1:500" onChange={onChangeRadio} />
                                             <FormControlLabel value="1000" control={<Radio color="primary" />} label="1:1000" onChange={onChangeRadio} /> */}
                                        </RadioGroup>
                                   </FormControl>
                              </Grid>
                              <Grid item xs={12} container rowSpacing={3} columnSpacing={3}>
                                   <Grid item xs={12} sm={6} className={classes.btnStyleClose}>
                                        <Button variant="outlined" color="error" className={classes.btnStyle} onClick={handleClose}>
                                             {language === "th" ? "ปิด" : "Close"}
                                        </Button>
                                   </Grid>
                                   <Grid item xs={12} sm={6} className={classes.btnStyleWallet}>
                                        <Button
                                             variant="contained"
                                             // color="error"
                                             style={{backgroundColor:"#4589C6"}}
                                             className={classes.btnStyle}
                                             onClick={() => {
                                                  onClickSubmitChangeLeverage();
                                                  handleClose();
                                             }}
                                        >
                                             {language === "th" ? "แก้ไข" : "Update"}
                                        </Button>
                                   </Grid>
                              </Grid>
                         </Grid>
                    </Grid>
               </Fade>
          </Modal>
     );
};
const useStyles = makeStyles(() => ({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "40px 18px",
          borderRadius: "8px",
     },
     btnStyle: { maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40 },
     btnStyleClose: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "right",
          },
     },
     btnStyleWallet: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "left",
          },
     },
}));
export default ModalChangeLeverage;
