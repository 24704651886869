import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import { GET, POST, PROFILEAPI, GETTRANSACTIONIB } from "service";
import swal from "sweetalert2";
import LoadingPage from "components/LoadingPage/LoadingPage";
import ListIBCommission from "./component/ListIBCommission";
import moment from "moment";
export class IBCommission extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            loading: false,
            profile: [],
            data_commission: [],
            date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end: new Date(),
        };
    }

    componentDidMount = () => {
        this.getProfile();
        this.getCommission();
    };

    async getProfile() {
        const { profile } = this.state
        try {
            this.setState({ loading: true });
            let res = await GET(PROFILEAPI);
            if (res.success) {
                this.setState({ loading: false });
                this.setState({ profile: res.result });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: profile.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    }

    getCommission = async () => {
        const { date_end, date_start } = this.state;

        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");

        try {
            this.setState({ loading: true });
            let getCommission = await POST(GETTRANSACTIONIB, { date_start: date_start_format, date_end: date_end_format });
            if (getCommission.success) {
                this.setState({ data_commission: getCommission.result });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                // swal.fire({ title: "Warning", text: getCommission.message, icon: "warning", timer: 1800, showConfirmButton: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    submitSearch = async () => {
        const { date_end, date_start } = this.state;

        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");

        try {
            this.setState({ loading: true });
            let getCommission = await POST(GETTRANSACTIONIB, { date_start: date_start_format, date_end: date_end_format });
            if (getCommission.success) {
                this.setState({ data_commission: getCommission.result });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                // swal.fire({ title: "Warning", text: getCommission.message, icon: "warning", timer: 1800, showConfirmButton: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    render() {
        const language = localStorage.getItem("language");
        const { page, loading, profile, data_commission } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <LoadingPage open={loading} />
                <ListIBCommission
                    language={language}
                    data={data_commission}
                    profile={profile}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    state={this.state}
                    submitSearch={this.submitSearch}
                    handleDateStartChange={this.handleDateStartChange}
                    handleDateEndChange={this.handleDateEndChange}
                />
            </Container>
        );
    }
}

export default withRouter(IBCommission);
