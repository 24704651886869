import React from "react";
import { Grid, Typography, Stack, CardMedia, Box, Container } from "@material-ui/core";
import moment from "moment";
import { Editor } from "draft-js";
import { ip_image } from "service";

const GridDetail = (props) => {
    const { data, language, state, image_promotion } = props;

    return (
        <>
            {data.map((e, i) =>
                <Grid key={i + 1} container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Promotion Detail</Typography>
                    </Grid>
                    <Grid sx={{ background: "#f8f8f8", p: 3, width: "auto", height: "auto", mt: 1 }} >
                        {e.promotion_img ?
                            <CardMedia component="img" alt="imagePromotion" src={`${ip_image}${e.promotion_img}`} />
                            :
                            <Box className="box-promotion-img">
                                <Typography >
                                    * ไม่มีรูปภาพแสดง *
                                </Typography>
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600, color: "#000" }}>
                            {language === "th" ? "ชื่อโปรโมชัน" : "Promotion Name"}
                        </Typography>
                        <Typography sx={{ fontSize: 16, color: "#000" }}>
                            {e.promotion_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600, color: "#000" }}>
                            {language === "th" ? "ระยะเวลา" : "Duration"}
                        </Typography>
                        <Typography >
                            {moment(e.start).format("DD/MM/YYYY")} - {moment(e.expire).format("DD/MM/YYYY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600, color: "#000" }}>
                            {language === "th" ? "รายละเอียดโปรโมชัน" : "Promotion detail"}
                        </Typography>
                        <Editor
                            defaultEditorState={state.editorState}
                            editorState={state.editorState}
                            wrapperClassName="wrapperClassName"
                            readOnly
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};


export default GridDetail;
