import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
    Grid,
    Typography,
    Button,
    TextField,
    InputAdornment,
    Stack,
    Pagination,
    FormControlLabel,
    Radio,
    CardMedia,
    MenuItem,
} from "@material-ui/core";
import { headerTable, textFiled } from "functions/Static";
import { GridTextField } from "components";
import TableWithdraw from "../TableWithraw/TableWithdraw";
import SearchIcon from "@material-ui/icons/Search";
import { createMuiTheme } from "@material-ui/core/styles";
import CardHeadFinancial from "../CardHead/CardHeadFinancial";
const theme = createMuiTheme();

const WebWithdraw = (props) => {
    const classes = useStyles();
    const {
        data,
        language,
        page,
        state,
        handleChangePage,
        profile,
        onClickSubmitWithDrawal,
        handleChangeText,
        submitSearch,
        handleSearch,
        onClickSendOTP,
        minwithdraw_error,
        maxwithdraw_error,
        min_withdraw,
        max_withdraw,
        onClickStatus,
        payment,
        // handleChange,
        internet_bank,
        handleChangeWithdraw,
    } = props;
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = page - 1;
    const dataList = data.slice(setPage * 10, page * 10);

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.fontStyleTitle}>
                    Withdrawal - Available Funds in Wallet: ${profile ? Math.floor(profile.wallet * 100) / 100 : "0.00"}
                </Typography>
            </Grid>
            <Grid item xs={12} container direction="row" spacing={2} alignContent="flex-start">
                <Grid item xs={12} md={4} container spacing={2} alignContent={"flex-start"}>
                    <Grid item xs={12}>
                        <Typography className={classes.fontStyleTitleBorder}>
                            {/* {language === "th" ? "ช่องทางการชำระเงิน" : "Payment Gateway"} */}
                        </Typography>
                    </Grid>
                    {payment &&
                        payment?.map((el, i) => {
                            return (
                                <>
                                    <Grid key={i} item xs={12} container className={classes.gridRadio} marginLeft={2} maxHeight={100}>
                                        <Grid xs={12} container item justifyContent="space-between">
                                            <Grid xs={8} container item alignContent="center">
                                                <FormControlLabel
                                                    sx={{ alignSelf: "center", marginBottom: 2 }}
                                                    value={1}
                                                    name={"withdraw_type"}
                                                    label={el.payment_name}
                                                    checked={Number(state.withdraw_type) === 1}
                                                    onChange={handleChangeWithdraw}
                                                    control={<Radio />}
                                                />
                                            </Grid>
                                            <Grid xs={4} container item justifyContent="flex-end">
                                                {el.img && <CardMedia className={classes.iconStyle} component="img" alt={el.name} image={el.img} />}
                                            </Grid>
                                        </Grid>

                                        {/* {el.payment_gateway_id === 1 && (
                                            <Grid xs={12} marginBottom={2} paddingRight={2}>
                                                <TextField
                                                    className="textfield-profile"
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    value={state.bank}
                                                    placeholder="Currency"
                                                >
                                                    {internet_bank.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            <Grid xs={12} container justifyContent="space-between" alignItems="center">
                                                                <Typography>{option.label}</Typography>
                                                                <CardMedia
                                                                    className={classes.iconStyle}
                                                                    component="img"
                                                                    alt={option.label}
                                                                    image={option.img}
                                                                />
                                                            </Grid>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        )} */}

                                    </Grid>
                                    {/* ////////////////// USDT/////////////// */}
                                    <Grid key={i} item xs={12} container className={classes.gridRadio} marginLeft={2} maxHeight={100}>
                                        <Grid xs={12} container item justifyContent="space-between">
                                            <Grid xs={8} container item alignContent="center">
                                                <FormControlLabel
                                                    sx={{ alignSelf: "center", marginBottom: 2 }}
                                                    value={3}
                                                    name={"withdraw_type"}
                                                    label={"USDT"}
                                                    checked={Number(state.withdraw_type) === 3}
                                                    onChange={handleChangeWithdraw}
                                                    control={<Radio />}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* {el.payment_gateway_id === 1 && (
                                            <Grid xs={12} marginBottom={2} paddingRight={2}>
                                                <TextField
                                                    className="textfield-profile"
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    value={state.bank}
                                                    placeholder="Currency"
                                                >
                                                    {internet_bank.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            <Grid xs={12} container justifyContent="space-between" alignItems="center">
                                                                <Typography>{option.label}</Typography>
                                                                <CardMedia
                                                                    className={classes.iconStyle}
                                                                    component="img"
                                                                    alt={option.label}
                                                                    image={option.img}
                                                                />
                                                            </Grid>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        )} */}
                                    </Grid>
                                </>
                            );
                        })}
                </Grid>

                <Grid item md={12} xs={12} container spacing={2} alignContent="flex-start">
                    <Grid item xs={12}>
                        <Typography className={classes.fontStyleTitleBorder}>{language === "th" ? "สกุลเงิน" : "Currency"}</Typography>
                    </Grid>
                    <Grid item container xs={10} spacing={2}>
                        <Grid item className="typography-profile pt-0" xs={6}>
                            <Typography>{language === "th" ? "การถอนเงิน" : "Withdrawal"}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                // className="textfield-profile"
                                error={minwithdraw_error || maxwithdraw_error}
                                helperText={
                                    minwithdraw_error
                                        ? `${language === "th" ? "ถอนขั้นต่ำ" : "Min Whitdraw is"} ${min_withdraw.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })} USD.`
                                        : maxwithdraw_error
                                            ? `${language === "th" ? "ถอนได้สูงสุด" : "Max Whitdraw is"} ${max_withdraw.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })} USD.`
                                            : `${language === "th"
                                                ? `ถอนขั้นต่ำ ${min_withdraw.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })} USD`
                                                : `Min Whitdraw is ${min_withdraw.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })} USD`
                                            }`
                                }
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                }}
                                fullWidth
                                size="small"
                                type="number"
                                placeholder="0.00"
                                name="withdraw"
                                value={state.withdraw}
                                onChange={handleChangeText}
                            />
                        </Grid>
                        {state.withdraw_type === 1 && (
                            <>
                                <Grid item className="typography-profile pt-0" xs={6}>
                                    <Typography>{language === "th" ? "อัตราการถอน" : "Withdraw Rate"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className="textfield-profile"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">฿</InputAdornment>,
                                        }}
                                        fullWidth
                                        size="small"
                                        type="number"
                                        placeholder="0.00"
                                        // name="receive_withdraw"
                                        value={(Math.round(state.withdrawRate * 100) / 100).toFixed(2)}
                                        // onChange={handleChangeText}
                                        disabled={true}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item className="typography-profile pt-0" xs={6}>
                            <Typography>{language === "th" ? "เงินรับ" : "Receive"}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className="textfield-profile"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{state.withdraw_type === 1 ? '฿' : '$'}</InputAdornment>,
                                }}
                                fullWidth
                                size="small"
                                type="number"
                                placeholder="0.00"
                                name="receive_withdraw"
                                value={(Math.round(state.receive_withdraw * 100) / 100).toFixed(2)}
                                onChange={handleChangeText}
                                disabled={true}
                            />
                        </Grid>
                        {state.ref && (
                            <>
                                <Grid item className="typography-profile pt-0" xs={6}>
                                    <Typography>{language === "th" ? "Ref" : "Ref"}</Typography>
                                </Grid>
                                <Grid item className=" pt-0" xs={6}>
                                    <Typography>{state.ref}</Typography>
                                </Grid>
                            </>
                        )}
                        <Grid item className="typography-profile pt-0" xs={6}>
                            <Typography>{language === "th" ? "OTP" : "OTP"}</Typography>
                        </Grid>
                        <Grid item container spacing={1} direction="row" xs={6}>
                            <Grid item xs={12} sm={6} md={8}>
                                <TextField
                                    className="textfield-profile"
                                    fullWidth
                                    size="small"
                                    type="number"
                                    placeholder="OTP"
                                    name="otp"
                                    value={state.otp}
                                    onChange={handleChangeText}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Button
                                    sx={{
                                        width: "100%",
                                        borderRadius: 2,
                                        height: "100%",
                                        fontSize: 12,
                                        backgroundColor: "#4589C6",
                                    }}
                                    variant="contained"
                                    // color="error"
                                    onClick={onClickSendOTP}
                                >
                                    {language === "th" ? "ส่ง OTP" : "Send OTP"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center" alignContent="flex-end" marginTop={10}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#4589C6" }}
                            className="btn-style"
                            sx={{ maxWidth: 260 }}
                            onClick={onClickSubmitWithDrawal}
                        >
                            {language === "th" ? "ถอนเงิน" : "Withdrawal Funds"}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.fontStyleTitleBorder}>{language === "th" ? "ธุรกรรม" : "Transaction"}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" mt={2}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 600,
                                    color: "#000",
                                    mr: 2,
                                    display: { xs: "none", md: "block" },
                                }}
                            >
                                {language === "th" ? "ค้นหา" : "Search"}:
                            </Typography>
                            <Grid container xs={12} md={4} sx={{ width: "40vw", mr: 2 }}>
                                <GridTextField
                                    data={textFiled.search_fn}
                                    state={state}
                                    language={language}
                                    handleChange={handleSearch}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            submitSearch();
                                        }
                                    }}
                                />
                            </Grid>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                //  className="btn-style"
                                sx={{
                                    maxWidth: 120,
                                    fontSize: 16,
                                    width: "100%",
                                    borderRadius: 40,
                                    height: 40,
                                    display: { xs: "none", md: "block" },
                                }}
                                onClick={submitSearch}
                            >
                                {language === "th" ? "ค้นหา" : "Search"}
                            </Button>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                //  className="btn-style"
                                sx={{
                                    maxWidth: 70,
                                    fontSize: 16,
                                    width: "100%",
                                    borderRadius: 40,
                                    height: 40,
                                    display: { xs: "block", md: "none" },
                                }}
                                onClick={submitSearch}
                            >
                                <SearchIcon />
                            </Button>
                        </Stack>
                    </Grid>
                    <TableWithdraw body={dataList} header={headerTable.withdraw} onClickStatus={onClickStatus} />
                    {dataList.length === 0 && (
                        <Grid container item justifyContent="center" alignItems="center" xs={12} mt={2}>
                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                            </Typography>
                        </Grid>
                    )}
                    {data.length > 0 && (
                        <Grid container item justifyContent="center" alignItems="center" xs={12} mt={2}>
                            {props.data.length > 0 && (
                                <Pagination
                                    count={count}
                                    page={page}
                                    onChange={handleChangePage}
                                    variant="outlined"
                                    color="secondary"
                                    hidePrevButton
                                    hideNextButton
                                />
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
const useStyles = makeStyles(() => ({
    imgStyle: {
        height: 230,
        [theme.breakpoints.up("sm")]: {
            height: 260,
        },
    },
    fontStyleTitle: {
        fontSize: 24,
        color: "#000",
        textAlign: "left",
        fontWeight: 600,
        marginTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
    },
    fontStyleTitleBorder: {
        fontSize: 24,
        color: "#000",
        textAlign: "left",
        fontWeight: 600,
        marginTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottom: "solid 2px #4589C6 ",
        marginBottom: 10,
    },
    fontStyleDetail: {
        fontSize: 13,
        color: "#000",
        textAlign: "justify",
        paddingLeft: 16,
        paddingRight: 16,
        [theme.breakpoints.up("sm")]: {
            fontSize: 18,
        },
    },
    fontStyleFooter: {
        fontSize: 10,
        color: "#000",
        [theme.breakpoints.up("sm")]: {
            fontSize: 14,
        },
    },
    gridRadio: {
        border: "solid 1px #ebebeb",
        marginBottom: 10,
    },
}));
export default WebWithdraw;
