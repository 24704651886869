import React, { Component } from "react";
import { POST, PROMOTIONBYIDAPI, PROMOTIONGETIMG, GETIMAGE } from "service";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import GridDetail from "./component/GridDetail";
import swal from "sweetalert2";
import LoadingPage from "components/LoadingPage/LoadingPage";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

export class PromotionDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            page: 1,
            search_text: "",
            loading: false,
            editorState: EditorState.createEmpty(),
            image_promotion: "",
            token: localStorage.getItem("token"),
        };
    }
    componentDidMount = () => {
        this.getPromotionById();
    };

    getPromotionById = async () => {

        const { promotion_data } = this.state;

        try {
            this.setState({ loading: true });
            let res = await POST(PROMOTIONBYIDAPI, { promotion_id: this.props.match.params.id });
            if (res.success) {
                const rawContentFromStore = convertFromRaw(JSON.parse(res.result[0].promotion_detail));
                const editorState = EditorState.createWithContent(rawContentFromStore);

                // if (res.result[0].promotion_img) {
                //     this.getImage(res.result[0].promotion_img);
                // }

                this.setState({
                    data: res.result,
                    editorState,
                });
                this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    // getImage = async (path_image) => {
    //     const { token } = this.state;
    //     try {
    //         const res = await GETIMAGE(PROMOTIONGETIMG, { token, path_image });
    //         const imageBlob = await res.blob();
    //         const image_promotion = URL.createObjectURL(imageBlob);
    //         this.setState({ image_promotion });
    //     } catch (error) {
    //         console.log("error", error);
    //     }
    // };

    render() {
        const language = localStorage.getItem("language");
        const { page, data, generate, url, loading, editorState, image_promotion } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <LoadingPage open={loading} />
                <Grid>
                    <GridDetail
                        language={language}
                        data={data}
                        // data={dataTable}
                        state={this.state}
                        editorState={editorState}
                        image_promotion={image_promotion}
                    />
                </Grid>
            </Container>
        );
    }
}

const dataTable = [
    {
        promotion_id: 1,
        promotion_name: "Promotion Name",
        promotion_detail: "สิ่งที่จะช่วยให้เรากำหนดโปรโมชั่นให้มีประสิทธิภาพมากยิ่งขึ้นคือการรู้เป้าหมายในการทำโปรโมชั่น ตอบคำถามง่าย ๆ ให้ได้ว่าคุณจะทำสินค้าหรือโปรโมชั่นไปทำไม อะไรคือปัญหาของธุรกิจที่คุณเจออยู่ตอนนี้ ",
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        expire: new Date(),
    },
];

export default withRouter(PromotionDetail);
