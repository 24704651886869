import { Fade, Modal, Backdrop, Grid, Button, Typography, TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const ModalDemo = (props) => {
     const { data, open, handleCloseModal, state, language, handleSearch, onClickAddDemo, loading } = props;
     const classes = useStyles();
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               onClose={handleCloseModal}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
          >
               <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container rowSpacing={3} justifyContent="center" alignItems="center">
                              <Grid container justifyContent="flex-end" xs={12}>
                                   <Button onClick={() => handleCloseModal()} className={classes.buttonClose}>
                                        <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                                   </Button>
                              </Grid>
                              <Typography sx={{ fontSize: 18 }}>{language === "th" ? "เพิ่มแอคเคาท์ทดสอบ" : "Add Demo Account"}</Typography>
                              <Grid item container spacing={2} justifyContent={"center"}>
                                   <Grid item xs={12} spacing={2}>
                                        <Typography sx={{ fontSize: 16, mb: 1 }}>{language === "th" ? "จำนวน" : "Amount"}</Typography>
                                        <TextField
                                             InputProps={{
                                                  endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                             }}
                                             fullWidth
                                             size="small"
                                             type="number"
                                             placeholder="0.00"
                                             name="amount"
                                             value={state.amount}
                                             onChange={handleSearch}
                                        />
                                   </Grid>
                                   <Button
                                        variant="contained"
                                        style={{ backgroundColor:"#4589C6"  }}
                                        onClick={async () => {
                                             await onClickAddDemo();
                                             handleCloseModal();
                                        }}
                                        disabled={loading}
                                        sx={{ m: 1, mt: 2, minWidth: 130 }}
                                   >
                                        {loading ? <CircularProgress size={25} style={{ color: "#fc0316"  }} /> : `${language === "th" ? "ตกลง" : "Submit"}`}
                                   </Button>
                                   <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => {
                                             handleCloseModal();
                                        }}
                                        disabled={loading}
                                        sx={{ m: 1, mt: 2, minWidth: 130 }}
                                   >
                                        {language === "th" ? "ยกเลิก" : "Cancel"}
                                   </Button>
                              </Grid>
                         </Grid>
                    </Grid>
               </Fade>
          </Modal>
     );
};

const useStyles = makeStyles(() => ({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "40px 18px",
          borderRadius: "8px",
          marginTop: "20px",
     },
     buttonClose: {
          backgroundColor: "Transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          "&:hover": {
               backgroundColor: "Transparent",
          },
     },
     media: {
          width: "100%",
          height: "500px",
     },
     textHeader: {
          fontSize: 14,
          color: "#707070",
     },
     textDetail: {
          fontSize: 17,
          marginBottom: 20,
     },
}));

export default ModalDemo;
