import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { headerTable } from "functions/Static";
import TablePort from "./TablePort";
const ListPort = (props) => {
    const { data, language, page, handleChangePage } = props;
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = page - 1;
    const dataList = data.slice(setPage * 10, page * 10);
    // const profile = location.state?.username;
    return (
        <Grid container rowSpacing={5} justifyContent="center" alignItems="center" mt={5}>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {language === "th" ? "รายการพอร์ต" : "List Port"}
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", mt: 3 }}>
                    {props.state.profile}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TablePort head={headerTable.iblist_port} body={dataList} count={count} page={page} handleChangePage={handleChangePage} language={language} />
            </Grid>
        </Grid>
    );
};

export default ListPort;
