import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import {  PROFILEAPI,  GET, GETNEWCUSTOMER } from "service";
import swal from "sweetalert2";
import LoadingPage from "components/LoadingPage/LoadingPage";
import ListIBNewMember from "./component/ListIBNewMember";
export class IBListNewMember extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            loading: false,
            profile: [],
            data_new_member: []
        };
    }

    componentDidMount = () => {
        this.getProfile();
        this.getNewCustomer();
    };

    async getProfile() {
        const { profile } = this.state
        try {
            this.setState({ loading: true });
            let res = await GET(PROFILEAPI);

            if (res.success) {
                
                this.setState({ loading: false });
                this.setState({ profile: res.result });

            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: profile.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    }

    async getNewCustomer() {
        try {
            this.setState({ loading: true });
            let res = await GET(GETNEWCUSTOMER);
            if (res.success) {
                this.setState({ loading: false });
                this.setState({ data_new_member: res.result });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: res.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    }

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };


    render() {
        const language = localStorage.getItem("language");
        const { page, loading, profile, data_new_member } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <LoadingPage open={loading} />
                <ListIBNewMember
                    language={language}
                    data={data_new_member}
                    profile={profile}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    state={this.state}
                />
            </Container>
        );
    }
}

export default withRouter(IBListNewMember);
