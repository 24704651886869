import { CardMedia, Container, Grid, Typography, Button } from "@material-ui/core";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import logo_web from "../../assets/logo/LOGOQrs.png";
import { POST, RESETPASSWORDFORGOT } from "service";
import swal from "sweetalert2";
import LoadingPage from "components/LoadingPage/LoadingPage";

export class ResetPassword extends Component {
     constructor(props) {
          super(props);

          this.state = {
               inputValue: {},
               checkInput: {},
               language_state: localStorage.getItem("language"),
               loading: false,
          };
     }
     handleChange = (e) => {
          let { checkInput, inputValue } = this.state;
          let name = e.target.name;
          let value = e.target.value;
          checkInput[name] = {
               err: value ? false : true,
               label: "",
          };
          inputValue[name] = value;
          this.setState({ inputValue, checkInput });
     };
     checkInput = () => {
          let state = this.state;
          let { checkInput, language_state } = this.state;
          let Check = textFiled.reset_password.map((el) => el.name);
          Check.forEach((e) => {
               checkInput[e] = {
                    err: state.inputValue[e] ? false : true,
                    label: state.inputValue[e] ? "" : `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
                    
               };
          });
          this.setState({ checkInput }, async () => {
               let { checkInput } = this.state;
               let input = Object.keys(checkInput).some((e) => checkInput[e].err);
               if (!input) {
                    this.onSunmit();
               } else {
                    swal.fire({
                         title: "Warning",
                         text: `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
               }
          });
     };
     onSubmit = async () => {
          const { new_password, repeat_password, language_state } = this.state.inputValue;
          if (new_password && repeat_password) {
               if (new_password.length < 6) {
                    swal.fire({
                         title: `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป" : "Your password needs a minimum of six characters"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป" : "Your password needs a minimum of six characters"}`,
                    });
               } else if (new_password.search(/[a-z]/) < 0) {
                    swal.fire({
                         title: `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your password needs a lower case letter"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your password needs a lower case letter"}`,
                    });
               } else if (new_password.search(/[A-Z]/) < 0) {
                    swal.fire({
                         title: `${language_state === "th" ? `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}` : "Warning"}`,
                         text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your password needs an uppser case letter"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your password needs an uppser case letter"}`,
                    });
               } else if (new_password.search(/[0-9]/) < 0) {
                    swal.fire({
                         title: `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your password needs a number"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({ error: true, error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your password needs a number"}` });
               } else {
                    if (new_password === repeat_password) {
                         try {
                              this.setState({ loading: true });
                              let reset = await POST(RESETPASSWORDFORGOT, { token: this.props.match.params.token, password: new_password });
                              if (reset.success) {
                                   this.setState({ loading: false });
                                   swal.fire({
                                        title: "Success",
                                        text: `${language_state === "th" ? "เปลี่ยนรหัสผ่านสำเร็จ" : "Reset password success"}`,
                                        icon: "success",
                                        // timer: 6000,
                                        showConfirmButton: true,
                                   });
                                   this.props.history.push("/login");
                              } else {
                                   this.setState({ loading: false });
                                   swal.fire({ title: "Warning", text: reset.message, icon: "warning", 
                                   // timer: 6000, 
                                   showConfirmButton: true });
                              }
                         } catch (error) {
                              this.setState({ loading: false });
                              swal.fire({ title: "Warning", text: error, icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "Warning",
                              text: `${language_state === "th" ? "กรุณากรอกรหัสผ่านให้ตรงกัน" : "Password not match"}`,
                              icon: "warning",
                              // timer: 6000,
                              showConfirmButton: true,
                         });
                    }
               }
          } else {
               this.setState({ loading: false });
               swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณากรอกให้ครบทุกช่อง" : "Please fill completed"}`,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
               });
          }
     };
     render() {
          const { inputValue, checkInput, loading } = this.state;
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl">
                    <LoadingPage open={loading} />
                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                         <CardMedia sx={{ height: 120, width: "auto", objectFit: "contain", mb: 2 }} component="img" alt="Logo" image={logo_web} />
                         <Typography sx={{ color: "#fff", fontSize: 50, fontWeight: 600, mb: 3 }}>QRS-Global</Typography>
                         <Grid item xs={12}>
                              <Typography sx={{ color: "#fff", fontSize: 35, mb: 3 }}>{language === "th" ? "เปลี่ยนรหัสผ่าน" : "Reset Password"}</Typography>
                              <Grid container rowSpacing={3} mb={4}>
                                   <GridTextField
                                        data={textFiled.reset_password}
                                        state={inputValue}
                                        check={checkInput}
                                        handleChange={this.handleChange}
                                        onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                                  this.checkInput();
                                             }
                                        }}
                                   />
                              </Grid>
                              <Grid container xs={12} direction="column" justifyContent="center" alignContent="center">
                                   <Button variant="contained" 
                                   // color="error" 
                                   style={{
                                        backgroundColor: "#4589C6",
                                   }}
                                   className="btn-style" sx={{ maxWidth: 260, mb: 2 }} onClick={()=>this.onSubmit()}>
                                        {language === "th" ? "เปลี่ยนรหัสผ่าน" : "Reset Password"}
                                   </Button>
                              </Grid>
                         </Grid>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(ResetPassword);
