import React from "react";
import { Grid, Typography, Stack, Button } from "@material-ui/core";
import { headerTable } from "../../../functions/Static";
import TableList from "./TableList";
import DatePicker from "react-date-picker";
import SearchIcon from "@material-ui/icons/Search";

const ListPromotion = (props) => {

    const { data, language, page, handleChangePage, state, onClickViewDeatil, handleDateStartChange, handleDateEndChange, submitSearch } = props;
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = page - 1;
    const dataList = data.slice(setPage * 10, page * 10);

    return (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Promotion List</Typography>
                <Stack direction="row" alignItems="center" mt={2}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                        {language === "th" ? "ค้นหา" : "Search"}:
                    </Typography>
                    <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                        <Grid item xs={12} md={3}>
                            <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                //  className="btn-style"
                                sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
                                onClick={submitSearch}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        submitSearch();
                                    }
                                }}
                            >
                                {language === "th" ? "ค้นหา" : "Search"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                        onClick={submitSearch}
                    >
                        <SearchIcon />
                    </Button>
                </Stack>
            </Grid>
            <Grid item mt={2} xs={12}>
                <TableList head={headerTable.pormotion_list} body={dataList} count={count} page={page} handleChangePage={handleChangePage} onClickViewDeatil={onClickViewDeatil} />
            </Grid>
        </Grid>
    );
};


export default ListPromotion;
