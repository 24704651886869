import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Tabs, Tab, TableContainer, Table, Box } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const DetailTaps = (props) => {
     const classes = useStyles();
     const [value, setValue] = React.useState(1);
     const handleChange = (event, newValue) => {
          setValue(newValue);
          props.setTap(newValue);
     };
     return (
          <Grid container justifyContent="center" alignItems="center">
               <Grid item xs={12} className="taps-css" sx={{ display: { xs: "none", md: "block" }, borderBottom: 1, borderColor: "#4589C6" }}>
                    <Tabs value={value} onChange={handleChange} centered variant="fullWidth">
                         <Tab value={1} className={classes.fontStyleTitle} label={props.language === "th" ? "โปรไฟล์" : "Profile"} />
                         <Tab value={2} className={classes.fontStyleTitle} label={props.language === "th" ? "เอกสาร" : "Documents"} />
                         <Tab value={3} className={classes.fontStyleTitle} label={props.language === "th" ? "บัญชี" : "Account"} />
                         <Tab value={4} className={classes.fontStyleTitle} label={props.language === "th" ? "เปลี่ยนรหัสผ่าน" : "Reset Password"} />
                         <Tab value={5} className={classes.fontStyleTitle} label={props.language === "th" ? "Address Crypto" : "Address Crypto"} />
                    </Tabs>
               </Grid>
               <Grid item xs={12} className="taps-css-mobile" sx={{ display: { xs: "block", md: "none" }, borderBottom: 1, borderColor: "#4589C6" }}>
                    <TableContainer>
                         <Table sx={{ minWidth: 400 }}>
                              <Tabs value={value} onChange={handleChange} centered variant="fullWidth">
                                   <Tab value={1} className={classes.fontStyleTitle} label={props.language === "th" ? "โปรไฟล์" : "Profile"} />
                                   <Tab value={2} className={classes.fontStyleTitle} label={props.language === "th" ? "เอกสาร" : "Documents"} />
                                   <Tab value={3} className={classes.fontStyleTitle} label={props.language === "th" ? "บัญชี" : "Account"} />
                                   <Tab value={4} className={classes.fontStyleTitle} label={props.language === "th" ? "เปลี่ยนรหัสผ่าน" : "Reset Password"} />
                                   <Tab value={5} className={classes.fontStyleTitle} label={props.language === "th" ? "Address Crypto" : "Address Crypto"} />
                              </Tabs>
                         </Table>
                    </TableContainer>
               </Grid>
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     fontStyleTitle: {
          fontSize: 14,
          color: "#000",
          fontWeight: 500,
          [theme.breakpoints.up("md")]: {
               marginTop: 0,
               fontSize: 18,
          },
     },
}));
export default withRouter(DetailTaps);
