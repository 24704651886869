import React from "react";
import { Grid } from "@material-ui/core";

const LoginLayoutIB = (props) => {
     return (
          <Grid className={`ib_register-layout`}>
               <Grid sx={{ position: "relative", background: "tranparent", width: "100vw", py: 5 }}>{props.children}</Grid>
          </Grid>
     );
};
export default LoginLayoutIB;
