import React from "react";
import { Backdrop, Fade, Typography, Modal, Grid, TextField, InputAdornment, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();
const ModalTranfers = (props) => {
     const classes = useStyles();
     const { open, detail, handleClose, language, onClickWithdraw, onClickDeposit, handleChangeText, props_state } = props;
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               onClose={handleClose}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
          >
               {detail && (
                    <Fade in={open}>
                         <Grid className={classes.boxStyle}>
                              <Grid container rowSpacing={3} justifyContent="center" alignItems="center">
                                   <Grid item xs={12} textAlign="center">
                                        <Typography sx={{ fontSize: 24, fontWeight: 600 }}>{detail.text ? detail.text : ""}</Typography>
                                   </Grid>
                                   {/* <Grid item container columnSpacing={1} xs={12}>
                                        <Grid item xs={12} sm={4}>
                                             <Typography sx={{ fontSize: 14, color: "#000" }}>Available Funds in Wallet</Typography>
                                             <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#184b8e" }}>{detail.available_wallet}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                             <Typography sx={{ fontSize: 14, color: "#000" }}>To trading account</Typography>
                                             <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#184b8e" }}>{detail.arrDetail && detail.arrDetail[1].value}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                             <Typography sx={{ fontSize: 14, color: "#000" }}>Trading account balance current</Typography>
                                             <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#184b8e" }}>{detail.arrDetail && detail.arrDetail[0].value}</Typography>
                                        </Grid>
                                   </Grid> */}
                                   <Grid item xs={12} className="typography-profile">
                                        <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000" }}>{detail.text ? detail.text : ""}</Typography>
                                        <TextField
                                             className="textfield-profile"
                                             InputProps={{
                                                  endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                             }}
                                             fullWidth
                                             size="small"
                                             type="number"
                                             name="money"
                                             onChange={handleChangeText}
                                             // value={props_state.money}
                                             placeholder="0.00"
                                        />
                                   </Grid>
                                   <Grid item xs={12} container rowSpacing={3} columnSpacing={3}>
                                        <Grid item xs={12} sm={6} className={classes.btnStyleClose}>
                                             <Button variant="outlined" color="error" className={classes.btnStyle} onClick={handleClose}>
                                                  {language === "th" ? "ปิด" : "Close"}
                                             </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={classes.btnStyleWallet}>
                                             <Button
                                                  variant="contained"
                                                  color="error"
                                                  className={classes.btnStyle}
                                                  style={{backgroundColor:"#4589C6"}}
                                                  onClick={() => {
                                                       if (detail.type === "deposit") {
                                                            onClickDeposit();
                                                            handleClose();
                                                       } else {
                                                            onClickWithdraw();
                                                            handleClose();
                                                       }
                                                  }}
                                             >
                                                  {language === "th" ? detail.text : detail.text}
                                             </Button>
                                        </Grid>
                                   </Grid>
                              </Grid>
                         </Grid>
                    </Fade>
               )}
          </Modal>
     );
};
const useStyles = makeStyles(() => ({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "40px 18px",
          borderRadius: "8px",
     },
     btnStyle: { maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40 },
     btnStyleClose: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "right",
          },
     },
     btnStyleWallet: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "left",
          },
     },
}));
export default ModalTranfers;
