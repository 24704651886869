import { Container, Grid, Typography, Button } from "@material-ui/core";
import { CardDetail } from "components";
import LoadingPage from "components/LoadingPage/LoadingPage";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { POST, VERIFYEMAILAPI, CONTRATAPI } from "service";

export class Verify extends Component {
     constructor(props) {
          super(props);
          this.state = {
               loading_verify: 0,
               profile: {},
               session: null,
               loading: false,
          };
     }

     componentDidMount() {
          this.getVerify();
     }

     getVerify = async () => {
          try {
               this.setState({ loading: true });
               let verify = await POST(VERIFYEMAILAPI, { token: this.props.match.params.token });
               if (verify.success) {
                    this.setState({ loading: false });
                    this.setState({ loading_verify: 1, profile: verify.result });
                    await POST(CONTRATAPI, { token: this.props.match.params.token });
               } else {
                    this.setState({ loading: false });
                    this.setState({ loading_verify: 2 });
               }
          } catch (error) {
               this.setState({ loading: false });
               this.setState({ loading_verify: 2 });
          }
     };

     onClickJoin = () => {
          const { session } = this.state;
          if (session) {
               this.props.history.push("/dashboard");
          } else {
               this.props.history.push("/login");
          }
     };

     render() {
          const language = localStorage.getItem("language");
          const { loading_verify, profile, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <LoadingPage open={loading} />
                    {loading_verify === 0 ? (
                         <Grid></Grid>
                    ) : loading_verify === 1 ? (
                         <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                              <Typography sx={{ color: "#fff", fontSize: 50, fontWeight: 600, mb: 3 }}> {language === "th" ? "ยินดีด้วย!!!" : "Congratulation!!!"}</Typography>
                              {/* <CardDetail data={profile} /> */}
                              <Button variant="contained" style={{backgroundColor:"#4589C6"}} className="btn-style" sx={{ maxWidth: 260, mb: 2, marginTop: 5 }} onClick={this.onClickJoin}>
                                   {language === "th" ? "เข้าร่วม" : "Join"}
                              </Button>
                         </Grid>
                    ) : (
                         <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                              <Typography sx={{ color: "#fff", fontSize: 50, fontWeight: 600, mb: 3 }}>
                                   {language === "th" ? "ยืนยันอีเมลไม่สำเร็จ" : "Verify Email fail"}
                              </Typography>
                         </Grid>
                    )}
               </Container>
          );
     }
}

export default withRouter(Verify);
