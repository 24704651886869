import React, { Component } from "react";
import { GET, POST, PROMOTIONAPI, PROMOTIONSEARCH } from "service";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import ListPromotion from "./component/ListPromotion";
import swal from "sweetalert2";
import LoadingPage from "components/LoadingPage/LoadingPage";
import moment from "moment";

export class Promotion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end: new Date(),
            page: 1,
            search_text: "",
            loading: false,
        };
    }
    componentDidMount = () => {
        this.getPromotion();
    };

    getPromotion = async () => {
        try {
            this.setState({ loading: true });
            let res = await GET(PROMOTIONAPI);
            if (res.success) {
                this.setState({ data: res.result, });
                this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    onClickViewDeatil = (id) => {
        this.props.history.push(`/promotion/promotionDetail/${id}`);
    };

    submitSearch = async () => {
        let { date_start, date_end } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let res = await POST(PROMOTIONSEARCH, { date_start: date_start_format, date_end: date_end_format });
            if (res.success) {
                this.setState({ data: res.result, });
                this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    render() {
        const language = localStorage.getItem("language");
        const { page, data, generate, url, loading } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <LoadingPage open={loading} />
                <Grid>
                    <ListPromotion
                        language={language}
                        data={data}
                        // data={dataTable}
                        page={page}
                        state={this.state}
                        handleChangePage={this.handleChangePage}
                        onClickViewDeatil={this.onClickViewDeatil}
                        handleDateStartChange={this.handleDateStartChange}
                        handleDateEndChange={this.handleDateEndChange}
                        submitSearch={this.submitSearch}
                    />
                </Grid>
            </Container>
        );
    }
}

const dataTable = [
    {
        promotion_id: 1,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        promotion_id: 2,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        promotion_id: 3,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        promotion_id: 4,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        promotion_id: 5,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        promotion_id: 6,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
];

export default withRouter(Promotion);
