import { Container, Grid } from "@material-ui/core";
import LoadingPage from "components/LoadingPage/LoadingPage";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GET, GETIB, GETIBALL, GETIBHEADER, POST, PROFILEAPI } from "service";
import swal from "sweetalert2";
import ListIB from "./component/ListIB";
export class IBList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end: new Date(),
            data_member: [],
            loading: false,
            nav_sub_ib: [],
            profile: [],
            count: 0,
            user_id: '',
            IB: [],
            data_header: [],
            count_page: 0,
        };
    }

    componentDidMount = () => {
        this.getProfile();
        this.getHeader();
        // this.getCountNetwork(this.state.user_id);
        // this.getIB();
    };

    async getProfile() {
        const { profile } = this.state
        try {
            this.setState({ loading: true });
            let res = await GET(PROFILEAPI);

            if (res.success) {
                let nav_sub_ib = []
                // if (res.result.is_ib === 1) {
                nav_sub_ib.push({
                    user_id: res.result.user_id,
                    name: res.result.name,
                    lastname: res.result.lastname,
                })
                // }
                this.setState({ loading: false });
                // this.setState({ user_id: res.result.user_id });
                this.setState({ profile: res.result, nav_sub_ib });
                this.setState({ user_id: res.result.user_id }, () => {
                    this.getIB();
                })
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: profile.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    }

    getIB = async () => {
        const { date_end, date_start, user_id } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let getIB = await POST(GETIB, { date_start: date_start_format, date_end: date_end_format });
            if (getIB.success) {
                // this.setState({ data_member: getIB.result });

                if (getIB.result.length > 0) {
                    // this.setState({ data_member: getIB.result });
                    this.setState({ IB: getIB.result });
                    this.getCountNetwork(user_id);
                } else {
                    this.setState({ data_member: getIB.result });
                }
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                // swal.fire({ title: "Warning", text: getIB.message, icon: "warning", timer: 1800, showConfirmButton: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    onClickNetworkChild = async (user_id, name, lastname) => {
        const { date_end, date_start, nav_sub_ib, page } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let getIB = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id: user_id ? user_id : null, page });
            if (getIB.success) {
                nav_sub_ib.push({
                    user_id,
                    name,
                    lastname,
                })
                this.setState({ data_member: getIB.result.ib_data, IB: getIB.result.ib_data, user_id, count_page: getIB.result.count });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    onClickNetworkChildBack = async (user_id) => {
        const { date_end, date_start, nav_sub_ib, page } = this.state;

        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true, page: 1 });
            let getIB = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id: user_id ? user_id : null, page });
            if (getIB.success) {
                const indexObj = nav_sub_ib.findIndex(el => (el.user_id === user_id))
                this.setState({ nav_sub_ib: nav_sub_ib.slice(0, indexObj + 1) });
                // this.setState({ data_member: getIB.result, IB: getIB.result, user_id });
                this.setState({ data_member: getIB.result.ib_data, IB: getIB.result.ib_data, user_id, count_page: getIB.result.count });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    getHeader = async () => {
        let { date_start, date_end } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            let amount = await POST(GETIBHEADER, { date_start: date_start_format, date_end: date_end_format });
            if (amount.success) {
                this.setState({ data_header: amount.result })
            }
        } catch (error) {
            console.log(error)
        }
    }

    getCountNetwork = async (id, value) => {
        let { date_start, date_end, page } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let amount = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id: id, page: value ? value : page });
            if (amount.success) {
                // this.setState({ data_member: amount.result })
                this.setState({ data_member: amount.result.ib_data, count_page: amount.result.count })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: amount.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    // getCountNetwork = async (id) => {
    //     let { date_start, date_end } = this.state;
    //     let date_start_format = moment(date_start).format("YYYY-MM-DD");
    //     let date_end_format = moment(date_end).format("YYYY-MM-DD");
    //     try {
    //         this.setState({ loading: true, page: 1 });
    //         let amount = await POST(GETCOUNTNETWORKALL, { date_start: date_start_format, date_end: date_end_format, user_id: id });
    //         if (amount.success) {
    //             let data_member = []
    //             amount.result.map(el => {
    //                 let data_net = el.data_net;
    //                 let team_deposit = data_net.map((e) => e.deposit).filter((e) => e.money !== null).reduce((prevValue, currentValue) => prevValue + currentValue.money, 0);
    //                 let team_withdraw = data_net.map((e) => e.withdraw).filter((el) => el.money !== null).reduce((prevValue, currentValue) => prevValue + currentValue.money, 0);
    //                 data_member.push({
    //                     // ...obj_data,
    //                     name: el.name,
    //                     lastname: el.lastname,
    //                     email: el.email,
    //                     invite: el.invite,
    //                     invite_id: el.invite_id,
    //                     user_id: el.user_id,
    //                     count: el.count,
    //                     commission: el.commission,
    //                     myLot: el.myLot,
    //                     allLotTeam: el.allLotTeam,
    //                     team_deposit,
    //                     team_withdraw,
    //                     net_deposit_team: team_deposit - team_withdraw,
    //                     verify_doc: el.verify_doc,
    //                     // team_deposit_team: team_deposit - team_withdraw,
    //                 })
    //             })
    //             // console.log('network')
    //             // console.log(data_member)
    //             let IB = this.state.IB
    //             let op = data_member.map((e, i) => {
    //                 let temp = IB.find(element => element.user_id === e.user_id)
    //                 // console.log('temp', temp)
    //                 if (temp) {
    //                     e.deposit = temp.deposit
    //                     e.withdraw = temp.withdraw
    //                 }
    //                 return e;
    //             })
    //             // console.log('op')
    //             // console.log(op)
    //             this.setState({ data_member: op })
    //             this.setState({ loading: false });
    //         } else {
    //             this.setState({ loading: false });
    //             swal.fire({
    //                 title: "ไม่สำเร็จ", text: amount.message, icon: "warning",
    //                 // timer: 6000, 
    //                 showConfirmButton: true
    //             });
    //         }
    //     } catch (error) {
    //         this.setState({ loading: false });
    //         swal.fire({
    //             title: "ไม่สำเร็จ", text: error, icon: "warning",
    //             // timer: 6000, 
    //             showConfirmButton: true
    //         });
    //     }
    // };

    handleChangePage = (e, value) => {
        const { user_id } = this.state;
        this.setState({ page: value });
        this.getCountNetwork(user_id ? user_id : null, value);
    };

    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitSearch = async () => {
        let { date_start, date_end, user_id } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true, page: 1 });
            let getIB = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id: user_id ? user_id : null, page: 1 });
            if (getIB.success) {
                this.getHeader();
                this.setState({ data_member: getIB.result.ib_data, IB: getIB.result.ib_data, count_page: getIB.result.count });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    render() {
        const language = localStorage.getItem("language");
        const { data_member, page, loading, nav_sub_ib, profile, user_id, data_header, count_page } = this.state;
        return (
            <Container maxWidth="xl">
                <LoadingPage open={loading} />
                <Grid>
                    <ListIB
                        language={language}
                        data={data_member}
                        data_header={data_header}
                        // data={data_member.filter((e) => e.invite_id === user_id)}
                        profile={profile}
                        nav_sub_ib={nav_sub_ib}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        handleDateStartChange={this.handleDateStartChange}
                        handleDateEndChange={this.handleDateEndChange}
                        state={this.state}
                        submitSearch={this.submitSearch}
                        onClickNetworkChild={this.onClickNetworkChild}
                        onClickNetworkChildBack={this.onClickNetworkChildBack}
                        count_page={count_page}
                    />
                </Grid>
            </Container>
        );
    }
}

export default withRouter(IBList);
