import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import { GET, CHECKSESSION, PROFILEAPI } from "service";

//-------------------- components -----------------//
import { NotFound } from "../components/index";

//-------------------- layout ---------------------//
import { PageLayout, NotFoundLayout, RouteAndLayout, LoginLayout, VerifyLayout, LoginLayoutIB } from "../layout/index";

//-------------------- pages ----------------------//
import {
     MainLogin,
     Login,
     Register,
     Dashboard,
     Profile,
     Report,
     Financial,
     Inbox,
     Trading,
     Verify,
     ForgotPassword,
     ResetPassword,
     Dowload,
     Support,
     AddSupport,
     ReportMetaTrader4,
     IB,
     IBNEWMEMBER,
     IBPORT,
     COMMISSION,
     Promotion,
     PromotionDetail,
} from "../pages/index";
//-------------------------------------------------//

export default class Routes extends Component {
     constructor(props) {
          super(props);

          this.state = {
               profile: {},
          };
     }

     componentDidMount() {
          this.getSession();
          this.getProfile();
     }

     getSession = async () => {
          if (
               !window.location.pathname.includes("login") &&
               !window.location.pathname.includes("register") &&
               !window.location.pathname.includes("forgot") &&
               !window.location.pathname.includes("ib_register") &&
               !window.location.pathname.includes("verify") &&
               !window.location.pathname.includes("reset-password")
          ) {
               try {
                    let checksession = await GET(CHECKSESSION);
                    if (!checksession.success) {
                         console.log('login');
                         window.location.replace("/login");
                         // this.props.history.push(`/login`);
                    }
               } catch (error) {
                    window.location.replace("/login");
                    //  this.props.history.push(`/login`);
               }
          }
     };
     getProfile = async () => {
          try {
               let profile = await GET(PROFILEAPI);
               if (profile.success) {
                    this.setState({ profile: profile.result });
               }
          } catch (error) {
               console.log(`error`, error);
          }
     };
     render() {
          const { profile } = this.state;
          return (
               <Switch>
                    {/* <Redirect exact from="/" to="/mainlogin" /> */}
                    <Redirect exact from="/" to="/login" />
                    {/* <RouteAndLayout component={MainLogin} exact layout={LoginLayout} path="/mainlogin" /> */}
                    <RouteAndLayout component={Login} exact layout={LoginLayout} profile={profile} path="/login" />
                    <RouteAndLayout component={ForgotPassword} exact layout={LoginLayout} profile={profile} path="/forgot" />
                    <RouteAndLayout component={ResetPassword} exact layout={VerifyLayout} profile={profile} path="/reset-password/:token" />
                    {/* <RouteAndLayout component={Register} exact layout={LoginLayout} path="/register" /> */}
                    {/* <RouteAndLayout component={Register} exact layout={LoginLayoutIB} path="/ib_register" /> */}
                    {/* <RouteAndLayout component={Register} exact layout={LoginLayout} path="/register" /> */}
                    <RouteAndLayout exact component={Register} layout={LoginLayout} path="/register" />
                    <RouteAndLayout component={Register} exact layout={LoginLayoutIB} path="/register/sale/:manager_id" />
                    <RouteAndLayout component={Register} layout={LoginLayout} path="/register/:invited_email" />



                    <RouteAndLayout component={Verify} exact layout={VerifyLayout} path="/verify/:token" />
                    {/*======================================================================================*/}
                    {/* <RouteAndLayout component={Main} exact layout={MainLayout} path="/main" /> */}
                    <RouteAndLayout component={Dashboard} exact layout={PageLayout} profile={profile} path="/dashboard" />
                    <RouteAndLayout component={Profile} exact layout={PageLayout} profile={profile} path="/profile" />
                    <RouteAndLayout component={Trading} exact layout={PageLayout} profile={profile} path="/accounts" />
                    <RouteAndLayout component={Financial} exact layout={PageLayout} profile={profile} path="/financial" />
                    {/* <RouteAndLayout component={Financial} exact layout={PageLayout} profile={profile} path="/financial/:mt4" /> */}
                    <RouteAndLayout component={Dowload} exact layout={PageLayout} profile={profile} path="/download" />
                    {/* <RouteAndLayout component={Dowload} exact layout={PageLayout} profile={profile} path="/downloadMt5" /> */}
                    <RouteAndLayout component={Report} exact layout={PageLayout} profile={profile} path="/report" />
                    <RouteAndLayout component={ReportMetaTrader4} exact layout={PageLayout} profile={profile} path="/reportmetatrader" />

                    <RouteAndLayout component={Support} exact layout={PageLayout} profile={profile} path="/support" />
                    <RouteAndLayout component={AddSupport} exact layout={PageLayout} profile={profile} path="/support/add" />
                    <RouteAndLayout component={Inbox} exact layout={PageLayout} profile={profile} path="/inbox" />

                    <RouteAndLayout component={IB} exact layout={PageLayout} profile={profile} path="/iblist" />
                    <RouteAndLayout component={IBNEWMEMBER} exact layout={PageLayout} profile={profile} path="/iblistnewmember" />
                    <RouteAndLayout component={IBPORT} exact layout={PageLayout} profile={profile} path="/iblist/port/:id" />
                    <RouteAndLayout component={COMMISSION} exact layout={PageLayout} profile={profile} path="/iblistcommission" />
                    <RouteAndLayout component={Promotion} exact layout={PageLayout} profile={profile} path="/promotion" />
                    <RouteAndLayout component={PromotionDetail} exact layout={PageLayout} profile={profile} path="/promotion/promotionDetail/:id" />

                    {/* <RouteAndLayout component={Policy} exact layout={PageLayout} path="/policy" /> */}
                    {/* <RouteAndLayout component={Question} exact layout={PageLayout} path="/question" /> */}
                    {/* <RouteAndLayout component={Contact} exact layout={ContactLayout} path="/contact" /> */}
                    <RouteAndLayout component={NotFound} exact layout={NotFoundLayout} path="/not-found" />
                    <Redirect to="/not-found" />
               </Switch>
          );
     }
}
