import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid, Table, TableContainer } from "@material-ui/core";
import CardHead from "./CardHead/CardHead";
import CardFooter from "./CardFooter/CardFooter";
import { CardDetail } from "../../components/index";
import wallet1 from "../../assets/logo/wallet1.png";
import wallet2 from "../../assets/logo/wallet2.png";
import broker from "../../assets/logo/broker.png";
import LoadingPage from "components/LoadingPage/LoadingPage";
export class Dashboard extends Component {
     constructor(props) {
          super(props);

          this.state = {
               profile: this.props.profile,
               dataFooterDetail: {
                    title: "PERSONAL INFORMATION",
                    arrDetail: [
                         {
                              title_en: "Full Name",
                              title_th: "ชื่อ - นามสกุล",
                              detail: "-",
                         },
                         {
                              title_en: "Date of birth",
                              title_th: "วัน/เดือน/ปี เกิด",
                              detail: "-",
                         },
                         {
                              title_en: "Mobile",
                              title_th: "โทรศัพท์",
                              detail: "-",
                         },
                         {
                              title_en: "Email",
                              title_th: "อีเมล",
                              detail: "-",
                         },
                         {
                              title_en: "Country",
                              title_th: "ประเทศ",
                              detail: "-",
                         },
                         {
                              title_en: "Affiliate Link",
                              title_th: "Link แนะนำ",
                              detail: "-",
                         },
                         {
                              title_en: "Status",
                              title_th: "สถานะ",
                              detail: "-",
                         },
                    ],
               },
               dataFooterTable: {
                    title: "ACTIVITY LASTEST",
                    arrDetail: [
                         {
                              date: "2021-06-14 12:48",
                              by: "by Username",
                              activity: "ACTIVITY 1",
                         },
                         {
                              date: "2021-06-14 12:48",
                              by: "by Username",
                              activity: "ACTIVITY 1",
                         },
                         {
                              date: "2021-06-14 12:48",
                              by: "by Username",
                              activity: "ACTIVITY 1",
                         },
                         {
                              date: "2021-06-14 12:48",
                              by: "by Username",
                              activity: "ACTIVITY 1",
                         },
                         {
                              date: "2021-06-14 12:48",
                              by: "by Username",
                              activity: "ACTIVITY 1",
                         },
                         {
                              date: "2021-06-14 12:48",
                              by: "by Username",
                              activity: "ACTIVITY 1",
                         },
                    ],
               },
               dataCard: [
                    {
                         name: "Wallet",
                         money: "0.00",
                         icon: wallet1,
                    },
                    {
                         name: "MT4/MT5 Balance",
                         money: "0.00",
                         icon: wallet2,
                    },
                    {
                         name: "IB",
                         money: "0.00",
                         icon: broker,
                    },
                    {
                         name: "Cashback",
                         money: "0.00",
                         // icon: cashback,
                    },
                    // {
                    //      name: "Reward",
                    //      money: "$0.00",
                    //      icon: reward,
                    // },
               ],
          };
     }
     onClickCopy = () => {
          alert("Copy");
     };

     render() {
          const { dataCard, dataFooterDetail, dataFooterTable } = this.state;
          const language = localStorage.getItem("language");
          const profile = this.props.profile;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <LoadingPage open={profile ? false : true} />
                    <Grid sx={{ position: "inherit", top: -90, display: { xs: "none", sm: "none", md: "block" } }}>
                         <CardHead language={language} dataCard={dataCard} profile={profile} />
                    </Grid>
                    <Grid sx={{ position: "inherit", display: { xs: "block", sm: "block", md: "none" } }}>
                         <CardHead language={language} dataCard={dataCard} profile={profile} />
                    </Grid>
                    {/* <Grid sx={{ display: "flex", justifyContent: "center" }}>
                         <Grid container sx={{ width: "100%", textAlign: "center", justifyContent: "center" }}>
                              <CardDetail data={profile} />
                         </Grid>
                    </Grid> */}
                    <Grid>
                         <CardFooter language={language} dataFooterDetail={dataFooterDetail} dataFooterTable={dataFooterTable} profile={profile} onClickCopy={this.onClickCopy} />
                    </Grid>
               </Container>
          );
     }
}
export default withRouter(Dashboard);
