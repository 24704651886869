const jwt = require("jsonwebtoken");

export const selectYear = () => {
     var start = 2490;
     var end = new Date().getFullYear() + 543;
     var years = [];
     for (var i = start; i <= end; i++) {
          years.push({ value: i, label: `${i} / ${i - 543}` });
     }
     return years;
};
export const selectDate = () => {
     let date_start = 1;
     let date_end = 31;
     let dates = [];
     for (var date = date_start; date <= date_end; date++) {
          dates.push({ value: date, label: date });
     }
     return dates;
};
export const getToken = () => {
     let get = JSON.parse(localStorage.getItem("token"));
     let decode = get && get.length > 0 ? jwt.decode(get, "amsapplication.kku.ac.th") : null;
     return decode;
};
export const add = (key) => {
     return (a, b) => {
          let d = Number(b[key]) ? Number(b[key]) : b[key] ? Number(b[key].replace(/,/g, "")) : 0;
          return a + d;
     };
};
export const formatIdcard = (e) => {
     let data = e.slice(0, 1) + "-" + e.slice(1, 5) + "-" + e.slice(5, 10) + "-" + e.slice(10, 12) + "-" + e.slice(12, 13);
     return data;
};
export const formatBookBank = (e) => {
     let data = e.slice(0, 3) + "-" + e.slice(3, 4) + "-" + e.slice(4, 9) + "-" + e.slice(9, 10);
     return data;
};
export const tofieds = (num) =>
     Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
export const setPdf = (arr, type) => {
     let data = arr.map((e, i) => ({ ...e, idtable: i + 1 }));
     let sum1 = data.reduce(add("car_adjust"), 0);
     let sum2 = data.reduce(add("car_reward"), 0);
     let sum3 = data.reduce(add("car_support"), 0);
     let sum4 = data.reduce(add("car_last"), 0);
     let sum5 = data.reduce(add("traffic_adjust"), 0);
     let sum6 = data.reduce(add("traffic_reward"), 0);
     let sum7 = data.reduce(add("traffic_support"), 0);
     let sum8 = data.reduce(add("traffic_last"), 0);
     let sum9 = data.reduce(add("total_1"), 0);
     let sum10 = data.reduce(add("total_2"), 0);
     let sum11 = data.reduce(add("total_3"), 0);
     let sum12 = data.reduce(add("total_4"), 0);
     //----------------------------------------------------//
     //----------------------------------------------------//
     data.unshift({
          idtable: "",
          code: "",
          name: "",
          bookbank: "",
          car_adjust: "",
          car_reward: "(1) 57%",
          car_support: "(1) 38%",
          car_last: "(1) 5%",
          traffic_adjust: "",
          traffic_reward: "[(5)/2]*57%",
          traffic_support: "[(5)/2]*38%",
          traffic_last: "[(5)/2]*5%",
          total_1: "รางวัล(2)+(6)",
          total_2: "สนับสนุน(3)+(7)",
          total_3: "ทั้งสิ้น(9)+(10)",
          total_4: "ผ./กสส.(4+8)",
     });
     data.unshift({
          idtable: "",
          code: "",
          name: "",
          bookbank: "",
          car_adjust: "ปรับ(1)",
          car_reward: "รางวัล(2)",
          car_support: "สนับสนุน(3)",
          car_last: "ผ./กสส.(4)",
          traffic_adjust: "ปรับ(5)",
          traffic_reward: "รางวัล(6)",
          traffic_support: "สนับสนุน(7)",
          traffic_last: "ผ./กสส.(8)",
          total_1: "",
          total_2: "",
          total_3: "",
          total_4: "",
     });
     data.unshift({
          idtable: "ลำดับ",
          code: type === 1 ? "รหัส" : "เลขคดี",
          name: type === 1 ? "ชื่อ-สกุล" : "ประเภท",
          bookbank: "เลขบัญชี",
          car_adjust: "พ.ร.บ. รถยนต์",
          car_reward: "",
          car_support: "",
          car_last: "",
          traffic_adjust: "พ.ร.บ.จราจรฯ",
          traffic_reward: "",
          traffic_support: "",
          traffic_last: "",
          total_1: "รวม(9)",
          total_2: "รวม(10)",
          total_3: "รวม ร.,สน.",
          total_4: "รวม",
     });
     data.push({
          idtable: "รวม",
          code: "",
          name: "",
          bookbank: "",
          car_adjust: sum1,
          car_reward: sum2,
          car_support: sum3,
          car_last: sum4,
          traffic_adjust: sum5,
          traffic_reward: sum6,
          traffic_support: sum7,
          traffic_last: sum8,
          total_1: sum9,
          total_2: sum10,
          total_3: sum11,
          total_4: sum12,
     });
     return data;
};
export const setExcel = (arr) => {
     let data = arr.map((e, i) => ({ ...e, idtable: i + 1 }));
     let sum1 = data.reduce(add("car_adjust"), 0);
     let sum2 = data.reduce(add("car_reward"), 0);
     let sum3 = data.reduce(add("car_support"), 0);
     let sum4 = data.reduce(add("car_last"), 0);
     let sum5 = data.reduce(add("traffic_adjust"), 0);
     let sum6 = data.reduce(add("traffic_reward"), 0);
     let sum7 = data.reduce(add("traffic_support"), 0);
     let sum8 = data.reduce(add("traffic_last"), 0);
     let sum9 = data.reduce(add("total_1"), 0);
     let sum10 = data.reduce(add("total_2"), 0);
     let sum11 = data.reduce(add("total_3"), 0);
     let sum12 = data.reduce(add("total_4"), 0);
     //----------------------------------------------------//
     //----------------------------------------------------//
     data.push({
          idtable: "รวม",
          code: "",
          name: "",
          car_adjust: sum1,
          car_reward: sum2,
          car_support: sum3,
          car_last: sum4,
          traffic_adjust: sum5,
          traffic_reward: sum6,
          traffic_support: sum7,
          traffic_last: sum8,
          total_1: sum9,
          total_2: sum10,
          total_3: sum11,
          total_4: sum12,
     });
     return data;
};
export const setTable = (arr) => {
     let arrData = [
          {
               name: "พ.ร.บ. รถยนต์ (1)",
          },
          {
               name: "   - รางวัล 57% (2) ",
          },
          {
               name: "   - สนับสนุน 38% (3)",
          },
          {
               name: "   - ผ./กสส. 5% (4)",
          },
          {
               name: "พ.ร.บ. จราจรฯ (5)",
          },
          {
               name: "   - รางวัล 57% (6) ",
          },
          {
               name: "   - สนับสนุน 38% (7)",
          },
          {
               name: "   - ผ./กสส. 5% (8)",
          },
          {
               name: "รวมยอดของคุณ",
          },
     ];
     let data = arrData.map((el, i) => {
          i = i + 1;
          let data_car = arr.filter((e) => e.type === 1);
          let data_traffic = arr.filter((e) => e.type === 2);
          let sum1 = data_car.reduce(add("car_adjust"), 0);
          let sum2 = data_car.reduce(add("car_reward"), 0);
          let sum3 = data_car.reduce(add("car_support"), 0);
          let sum4 = data_car.reduce(add("car_last"), 0);
          let sum5 = data_traffic.reduce(add("traffic_adjust"), 0);
          let sum6 = data_traffic.reduce(add("traffic_reward"), 0);
          let sum7 = data_traffic.reduce(add("traffic_support"), 0);
          let sum8 = data_traffic.reduce(add("traffic_last"), 0);
          let sum9 = arr.reduce(add("total_1"), 0);
          let total =
               i === 1
                    ? tofieds(sum1)
                    : i === 2
                    ? tofieds(sum2)
                    : i === 3
                    ? tofieds(sum3)
                    : i === 4
                    ? tofieds(sum4)
                    : i === 5
                    ? tofieds(sum5)
                    : i === 6
                    ? tofieds(sum6)
                    : i === 7
                    ? tofieds(sum7)
                    : i === 8
                    ? tofieds(sum8)
                    : i === 9
                    ? tofieds(sum9)
                    : "0.00";
          let obj = {
               name: el.name,
               total,
               check: i === 1 || i === 5 || i === 9 ? true : false,
               color: i === 1 ? "#ffc107" : i === 5 ? "#f5acac" : i === 9 ? "#3fe665" : "transparent",
          };
          return obj;
     });

     return data;
};
