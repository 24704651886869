import React from "react";
import { Grid } from "@material-ui/core";

const LoginLayout = (props) => {
     return (
          <Grid className={`verify-layout`}>
               <Grid sx={{ position: "relative", background: "tranparent", width: "100vw", py: 5 }}>{props.children}</Grid>
          </Grid>
     );
};
export default LoginLayout;
