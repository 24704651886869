import { palette } from "../palette";

export let MuiIconButton = {
     root: {
          color: palette.icon,
          "&:hover": {
               backgroundColor: "rgba(0, 0, 0, 0.03)",
          },
     },
};
