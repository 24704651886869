import { CardMedia, Container, Grid, Typography, Button } from "@material-ui/core";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import logo_web from "../../assets/logo/LOGOQrs.png";
import { POST, FORGOTPASSWORD } from "service";
import swal from "sweetalert2";
import LoadingPage from "components/LoadingPage/LoadingPage";

export class ForgotPassword extends Component {
     constructor(props) {
          super(props);

          this.state = {
               inputValue: {},
               checkInput: {},
               language_state: localStorage.getItem("language"),
               loading: false,
          };
     }
     handleChange = (e) => {
          let { checkInput, inputValue } = this.state;
          let name = e.target.name;
          let value = e.target.value;
          checkInput[name] = {
               err: value ? false : true,
               label: "",
          };
          inputValue[name] = value;
          this.setState({ inputValue, checkInput });
     };
     checkInput = () => {
          let state = this.state;
          let { checkInput } = this.state;
          let Check = textFiled.forgot.map((el) => el.name);
          Check.forEach((e) => {
               checkInput[e] = {
                    err: state.inputValue[e] ? false : true,
                    label: state.inputValue[e] ? "" : "กรุณากรอกข้อมูลให้ครบ",
               };
          });
          this.setState({ checkInput }, async () => {
               let { checkInput } = this.state;
               let input = Object.keys(checkInput).some((e) => checkInput[e].err);
               if (!input) {
                    this.setState({ checkInput: {} });
                    this.onSunmit();
               }
          });
     };
     onSunmit = async () => {
          const { inputValue, language_state } = this.state;
          let email = inputValue.username;
          try {
               this.setState({ loading: true });
               let forgot = await POST(FORGOTPASSWORD, { email });
               if (forgot.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "success",
                         text: `${language_state === "th" ? "กรุณาตรวจสอบ Email ของคุณ" : "Please check your email"}`,
                         icon: "success",
                         showConfirmButton: true,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "warning", text: forgot.message, icon: "warning", showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
          }
     };
     render() {
          const { inputValue, checkInput, loading } = this.state;
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl">
                    <LoadingPage open={loading} />
                    <Grid container direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
                         <CardMedia sx={{ height: 70, width: "auto", objectFit: "contain", mb: 2 }} component="img" alt="Logo" image={logo_web} />
                         {/* <Typography sx={{ color: "#000", fontSize: 50, fontWeight: 600, mb: 3 }}>QRS Broker</Typography> */}
                         <Grid container direction="column" item xs={12}>
                              <Typography sx={{ color: "#000", fontSize: 35, mb: 3 }}>{language === "th" ? "กรอกอีเมล" : "Enter Email"}</Typography>
                              <Grid rowSpacing={3} mb={4}>
                                   <GridTextField
                                        data={textFiled.forgot}
                                        state={inputValue}
                                        check={checkInput}
                                        handleChange={this.handleChange}
                                        onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                                  this.checkInput();
                                             }
                                        }}
                                   />
                              </Grid>
                              <Grid container xs={12} direction="column" justifyContent="center" alignContent="center">
                                   <Button
                                        variant="contained"
                                        style={{
                                             backgroundColor: "#4589C6",
                                        }}
                                        className="btn-style"
                                        sx={{ maxWidth: 260, mb: 2 }}
                                        onClick={this.checkInput}
                                   >
                                        {language === "th" ? "Send Email" : "Send Email"}
                                   </Button>
                                   <Button variant="outlined" 
                                   // color="error" 
                                   // style={{
                                   //      backgroundColor: "#4589C6",
                                   // }}
                                   className="btn-style" sx={{ maxWidth: 260, mb: 2 }} onClick={() => this.props.history.push("/login")}>
                                        {language === "th" ? "Login" : "Login"}
                                   </Button>
                              </Grid>
                         </Grid>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(ForgotPassword);
