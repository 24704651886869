import { Container } from "@material-ui/core";
import LoadingPage from "components/LoadingPage/LoadingPage";
import { textFiled } from "functions/Static";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETSUPPORT, GET, POST, GETTYPESUPPORT } from "service";
import SupportCard from "./component/SupportCard";
import swal from "sweetalert2";

export class Support extends Component {
     constructor(props) {
          super(props);

          this.state = {
               page: 1,
               data_table: [],
               type_support: [],
               loading: false,
               status_type: "null",
               data_search: [],
               profile: this.props.profile,
               profiledetail: [],
          };
     }

     componentDidMount = () => {
          this.getSupport();
          this.getType();
          const { profile } = this.state;
          // alert(JSON.stringify(profile))

          let profileData = [
               {
                    labalTh: "ชื่อ",
                    labalEn: "Fist name",
                    detail: profile.admin_name || "-",
               },
               {
                    labalTh: "นามสกุล",
                    labalEn: "Last name",
                    detail: profile.admin_lastname || "-",
               },
               {
                    labalTh: "เบอร์โทรศัพท์",
                    labalEn: "Phone number",
                    detail: profile.admin_phone || "-",
               },
               {
                    labalTh: "ไลน์ไอดี",
                    labalEn: "Line id",
                    detail: profile.line_id || "-",
               },
          ];

          this.setState({
               profiledetail: profileData,
          });
     };

     getSupport = async () => {
          try {
               this.setState({ loading: true });
               let get_support = await GET(GETSUPPORT);
               if (get_support.success) {
                    this.setState({ data_table: get_support.result, loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "Warning", text: get_support.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "Warning", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     getType = async () => {
          try {
               this.setState({ loading: true });
               let getType = await POST(GETTYPESUPPORT);
               if (getType.success) {
                    this.setState({ type_support: getType.result });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "Warning", text: getType.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "Warning", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     handleClickSearch = () => {
          const { data_table, search_text, status_type } = this.state;
          let data_status = data_table.filter((el) => el.status === status_type);
          let data_search = [];
          console.log(`data_status`, status_type, data_status);
          if (data_status.length > 0) {
               data_search = data_status.filter(
                    (el) =>
                         (search_text ? el.name_ticket.toString().toLowerCase().includes(search_text.toLowerCase()) : el) ||
                         (search_text ? el.ticket_id.toString().toLowerCase().includes(search_text.toLowerCase()) : el)
               );
          } else {
               data_search = data_table.filter(
                    (el) =>
                         (search_text ? el.name_ticket.toString().toLowerCase().includes(search_text.toLowerCase()) : el) ||
                         (search_text ? el.ticket_id.toString().toLowerCase().includes(search_text.toLowerCase()) : el)
               );
          }

          this.setState({ data_search });
     };

     handleChange = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleDateStartChange = (date) => {
          this.setState({ date_start: date });
     };

     handleDateEndChange = (date) => {
          this.setState({ date_end: date });
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     onClickAdd = () => {
          this.props.history.push("/support/add");
     };

     render() {
          const { page, data_table, type_support, loading, data_search } = this.state;
          const language = localStorage.getItem("language");
          const { profiledetail, profile } = this.state;
          return (
               <Container maxWidth="xl">
                    <LoadingPage open={loading} />
                    <SupportCard
                         language={language}
                         handleChange={this.handleChange}
                         state={this.state}
                         textField_info={textFiled.textfield_support}
                         page={page}
                         data={data_table}
                         data_search={data_search}
                         onClickAdd={this.onClickAdd}
                         type_support={type_support}
                         handleClickSearch={this.handleClickSearch}
                         profile={profile}
                         profiledetail={profiledetail}
                    />
               </Container>
          );
     }
}

export default withRouter(Support);
