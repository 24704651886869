import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { POST, GET, PROFILEAPI, GETPORT } from "service";
import swal from "sweetalert2";
import LoadingPage from "components/LoadingPage/LoadingPage";
import ListPort from "./component/ListPort";
export class IBPort extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            loading: false,
            data_port: [],
            profile: this.props.location.state.username
        };
    }

    componentDidMount = () => {
        // this.getProfile();
        this.getPort();
    }

    getPort = async () => {
        try {
            this.setState({ loading: true });
            console.log(this.props);
            let getPort = await POST(GETPORT, { user_id: this.props.match.params.id });
            if (getPort.success) {
                this.setState({ data_port: getPort.result });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                // swal.fire({ title: "Warning", text: getIB.message, icon: "warning", timer: 1800, showConfirmButton: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    // async getProfile() {
    //     const { profile } = this.state
    //     try {
    //         this.setState({ loading: true });
    //         let res = await GET(PROFILEAPI);

    //         if (res.success) {
    //             this.setState({ loading: false });
    //             this.setState({ profile: res.result });

    //         } else {
    //             this.setState({ loading: false });
    //             swal.fire({
    //                 title: "Warning",
    //                 text: profile.message,
    //                 icon: "warning",
    //                 // timer: 6000,
    //                 showConfirmButton: true,
    //             });
    //         }
    //     } catch (error) {
    //         this.setState({ loading: false });
    //         swal.fire({
    //             title: "Warning",
    //             text: error,
    //             icon: "warning",
    //             // timer: 6000,
    //             showConfirmButton: true,
    //         });
    //     }
    // }

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    render() {
        const language = localStorage.getItem("language");
        const { data_port, page, loading } = this.state;
        return (
            <Container maxWidth="xl">
                <LoadingPage open={loading} />
                <Grid>
                    <ListPort
                        language={language}
                        data={data_port}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        state={this.state}
                    />
                </Grid>
            </Container>
        );
    }
}

export default withRouter(IBPort);
