import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GridTextField } from "../../../components/index";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const TapResetPassword = (props) => {
     const classes = useStyles();
     const { data, inputValue, checkInput, handleChange, handleSubmit, language } = props;

     return (
          <Grid container justifyContent="start" alignItems="start" rowSpacing={5}>
               <Grid item xs={12} container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                         <Typography className={classes.titleStyle}>{language === "th" ? "เปลี่ยนรหัสผ่าน" : "Reset Password"}</Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
               </Grid>
               <Grid item xs={12} container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                         <Grid container rowSpacing={3}>
                              <GridTextField
                                   data={data}
                                   state={inputValue}
                                   check={checkInput}
                                   handleChange={handleChange}
                                   language={language}
                              />
                         </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
               </Grid>
               <Grid item xs={12} textAlign="center">
                    <Button variant="contained" style={{ backgroundColor:"#4589C6" }} className="btn-style" sx={{ maxWidth: 260, mt: 10 }} onClick={handleSubmit}>
                         {language === "th" ? "เปลี่ยนรหัสผ่าน" : "Reset Password"}
                    </Button>
               </Grid>
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     titleStyle: {
          fontSize: 20,
          color: "#000",
          fontWeight: 600,
          borderBottom: "solid 2px #4589C6",
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 32,
          // },
     },
     borderSolid: {
          textAlign: "center",
          width: 50,
          border: "solid 1px #fc0316",
          background: "#4589C6",
          [theme.breakpoints.up("md")]: {
               width: 100,
               border: "solid 2px #4589C6",
          },
     },
     imgStyle: {
          objectFit: "contain",
          height: 90,
          [theme.breakpoints.up("md")]: {
               height: 140,
          },
     },
     fontStyleTitle: {
          fontSize: 17,
          color: "#000",
          textAlign: "center",
          marginTop: 15,
          fontWeight: 600,
          [theme.breakpoints.up("md")]: {
               fontSize: 28,
          },
     },
     fontStyleDetail: {
          fontSize: 12,
          color: "#676767",
          textAlign: "center",
          marginTop: 10,
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
     },
}));
export default withRouter(TapResetPassword);
