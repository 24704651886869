import React from "react";
import { Route } from "react-router-dom";
// import PropTypes from 'prop-types';

const RouteAndLayout = ({ layout: Layout, component: Component, profile, ...rest }) => {
     return (
          <Route
               {...rest}
               render={(matchProps) => (
                    <Layout profile={profile}>
                         <Component {...matchProps} profile={profile} />
                    </Layout>
               )}
          />
     );
};
export default RouteAndLayout;
