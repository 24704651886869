import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Pagination } from "@material-ui/core";
// import moment from "moment";
import { withRouter } from "react-router-dom";
import moment from "moment";

const GridTable = (props) => {
    const classes = useStyles();
    //-------------------------------------------------//
    return (
        <Grid className="table-btn">
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {props.head.map((el, i) => {
                                return (
                                    <TableCell key={i} align={el.align || "left"}>
                                        <Typography noWrap>{el.label}</Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.body.map((el, i) => {
                            return (
                                <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                    <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.cashback_transaction_id}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {moment(el.created_at).format("DD/MM/YYYY")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.detail}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.money}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.type === 1 ? "Cashback" : "transfer"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {props.body.length > 0 && (
                    <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />
                )}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 320,
    },
});
export default withRouter(GridTable);
