import { Container, Grid } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DownloadCard from "./Component/DownloadCard";

export class Dowload extends Component {
     render() {
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <DownloadCard language={language} />
               </Container>
          );
     }
}

export default withRouter(Dowload);
