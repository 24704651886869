import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Pagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";

const SupportTable = (props) => {
     const { type_support, language } = props;
     const classes = useStyles();
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.head.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  <Typography noWrap>{el.label}</Typography>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.ticket_id}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {type_support.length > 0 ? type_support.find((e) => e.ticket_type_id === el.type).type_name : null}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.name_ticket}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  {/* 1 = success, 2 = received, 3 = waiting  */}
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.status === 1
                                                            ? language === "en"
                                                                 ? "Success"
                                                                 : "เสร็จสิ้น"
                                                            : el.status === 2
                                                            ? language === "en"
                                                                 ? "Received"
                                                                 : "เจ้าหน้าที่รับเรื่องแล้ว"
                                                            : el.status === 3
                                                            ? language === "en"
                                                                 ? "Waiting"
                                                                 : "รอการตวจสอบ"
                                                            : language === "en"
                                                            ? "Error"
                                                            : "ผิดพลาด"}
                                                  </Typography>
                                             </TableCell>
                                             {/* <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.profit}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.type}
                                                  </Typography>
                                             </TableCell> */}
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
});
export default SupportTable;
