import { Backdrop, Fade, Typography, Modal, Grid, Button, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { ip } from "service";
import { height } from "@material-ui/system";
import moment from "moment";

const ModalSlip = (props) => {
     const { data } = props;
     const classes = useStyles();
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={props.open}
               onClose={props.handleCloseModal}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
          >
               <Fade in={props.open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container rowSpacing={3} justifyContent="center" alignItems="center">
                              <Grid container justifyContent="flex-end" xs={12}>
                                   <Button onClick={() => props.handleCloseModal()} className={classes.buttonClose}>
                                        <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                                   </Button>
                              </Grid>
                              <Typography sx={{ fontSize: 18 }}>View Slip</Typography>
                              {data && (
                                   <Grid item container direction="row">
                                        <Grid xs={12} md={6} item>
                                             <Typography className={classes.textHeader}>Transaction Number</Typography>
                                             <Typography className={classes.textDetail}>{data.transaction_id || "-"}</Typography>
                                             <Typography className={classes.textHeader}>Create</Typography>
                                             <Typography className={classes.textDetail}>
                                                  {data.created_at ? moment(data.created_at).format("DD/MM/YYYY, HH:mm:ss") : "-"}
                                             </Typography>
                                             <Typography className={classes.textHeader}>Deposit</Typography>
                                             <Typography className={classes.textDetail}>{data.money || "-"}</Typography>
                                             <Typography className={classes.textHeader}>Receive</Typography>
                                             <Typography className={classes.textDetail}>{data.receive || "-"}</Typography>
                                        </Grid>
                                        <Grid xs={12} md={6} item>
                                             <CardMedia className={classes.media} image={ip + data.slip_path} title="Contemplative Reptile" />
                                        </Grid>
                                   </Grid>
                              )}
                         </Grid>
                    </Grid>
               </Fade>
          </Modal>
     );
};

const useStyles = makeStyles((theme) => ({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "40px 18px",
          borderRadius: "8px",
          marginTop: '20px'
     },
     buttonClose: {
          backgroundColor: "Transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          "&:hover": {
               backgroundColor: "Transparent",
          },
     },
     media: {
          width: "100%",
          height: "500px",
     },
     textHeader: {
          fontSize: 14,
          color: "#707070",
     },
     textDetail: {
          fontSize: 17,
          marginBottom: 20,
     },
}));

export default ModalSlip;
