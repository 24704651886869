import React from "react";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Stack, Button } from "@material-ui/core";
import { headerTable } from "../../../functions/Static";
import SearchIcon from "@material-ui/icons/Search";
import DatePicker from "react-date-picker";
import GridTable from "./GridTable";

const Transaction = (props) => {
     //  const classes = useStyles();
     const { data, language, page, handleChangePage, handleSearch, state, submitSearch, handleDateStartChangeMT4, handleDateEndChangeMT4, info_list } = props;
     const count = Math.ceil(data.length > 0 && data.length / 10);
     const setPage = page - 1;
     const dataList = data.slice(setPage * 10, page * 10);
     return (
          <Grid container rowSpacing={5} justifyContent="center" alignItems="center" mt={5}>
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                         {language === "th" ? "ธุรกรรม" : "Transaction"}
                    </Typography>
                    <Stack direction="row" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                              {language === "th" ? "ค้นหา" : "Search"}:
                         </Typography>
                         <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                              {/* <Grid item xs={12} md={3}>
                                   <GridTextField data={textFiled.search_td} language={language} state={state} handleChange={handleSearch} menuItem={info_list} />
                              </Grid> */}
                              <Grid item xs={12} md={3}>
                                   <DatePicker className={"date-picker"} onChange={handleDateStartChangeMT4} value={state.date_start_mt4} format="dd/MM/yyyy" />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                   <DatePicker className={"date-picker"} onChange={handleDateEndChangeMT4} value={state.date_end_mt4} format="dd/MM/yyyy" />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                   <Button
                                        variant="contained"
                                        style={{backgroundColor:"#4589C6"}}
                                        //  className="btn-style"
                                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
                                        onClick={submitSearch}
                                   >
                                        {language === "th" ? "ค้นหา" : "Search"}
                                   </Button>
                              </Grid>
                         </Grid>
                         <Button
                              variant="contained"
                              style={{backgroundColor:"#4589C6"}}
                              //  className="btn-style"
                              sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                              onClick={submitSearch}
                         >
                              <SearchIcon />
                         </Button>
                    </Stack>
               </Grid>
               <Grid item xs={12}>
                    <GridTable head={headerTable.transaction} body={dataList} count={count} page={page} handleChangePage={handleChangePage} language={language} />
               </Grid>
          </Grid>
     );
};
// const useStyles = makeStyles((theme) => ({
//      imgStyle: {
//           height: 230,
//           [theme.breakpoints.up("sm")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//           [theme.breakpoints.up("md")]: {
//                objectFit: "cover",
//                height: 340,
//           },
//           [theme.breakpoints.up("lg")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//      },
//      borderSolid: {
//           width: "100%",
//           border: "solid 1px #fc0316",
//           background: "#fc0316",
//           [theme.breakpoints.up("md")]: {
//                width: 100,
//           },
//      },
//      fontStyleTitle: {
//           fontSize: 24,
//           color: "#000",
//           marginTop: 15,
//           fontWeight: 600,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                marginTop: 0,
//           },
//      },
//      fontStyleDetail: {
//           fontSize: 13,
//           color: "#000",
//           textAlign: "justify",
//           marginTop: 24,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                fontSize: 18,
//           },
//      },
//      btnStyle: {
//           textAlign: "right",
//           marginTop: 3,
//           [theme.breakpoints.up("md")]: {
//                marginTop: 16,
//                textAlign: "left",
//           },
//      },
// }));
export default withRouter(Transaction);
