import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Stack, Button, Autocomplete, TextField } from "@material-ui/core";
import { headerTable } from "../../../functions/Static";
import GridTable from "./GridTable";
import DatePicker from "react-date-picker";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const Transaction = (props) => {
    const classes = useStyles();
    const {
        data,
        language,
        page,
        handleChangePage,
        handleSearch,
        state,
        submitSearch,
        handleDateStartChangeCashback,
        handleDateEndChangeCashback,
        handleChangeSearchCashback,
        profile,
        sum_ib,
    } = props;
    console.log("props:", props);
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = page - 1;
    const dataList = data.slice(setPage * 10, page * 10);

    return (
        <Grid container rowSpacing={5} justifyContent="center" alignItems="center" mt={5}>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {language === "th" ? "ธุรกรรม" : "Transaction"}
                </Typography>
                <Stack direction="row" alignItems="center" mt={2}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, alignSelf: "flex-start" }}>
                        {language === "th" ? "ค้นหา" : "Search"}:
                    </Typography>
                    <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                        {/* <Grid item xs={12} md={12} lg={4}>
                                   <GridTextField
                                        data={textFiled.search_ib}
                                        language={language}
                                        state={state}
                                        handleChange={handleSearch}
                                        onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                                  submitSearch();
                                             }
                                        }}
                                   />
                              </Grid> */}
                        <Grid item xs={12} md={3}>
                            <TextField
                                id="Ticket"
                                label="Ticket"
                                variant="outlined"
                                value={state.search_cashback}
                                state={state}
                                onChange={handleChangeSearchCashback}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DatePicker
                                className={"date-picker"}
                                onChange={handleDateStartChangeCashback}
                                value={state.date_start_cashback}
                                format="dd/MM/yyyy"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DatePicker
                                className={"date-picker"}
                                onChange={handleDateEndChangeCashback}
                                value={state.date_end_cashback}
                                format="dd/MM/yyyy"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                //  className="btn-style"
                                sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                onClick={submitSearch}
                            >
                                {language === "th" ? "ค้นหา" : "Search"}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
                {/* {profile.is_ib === 1 && (
                         <Grid container spacing={2}>
                              <Grid item xs={12} sm={4}>
                                   <Grid mt={3} className={classes.cardGridStyle}>
                                        <Typography sx={{ fontSize: 14 }}>Sum Deposit :</Typography>
                                        <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#184b8e" }}>
                                             ${sum_ib?.sumDeposit ? parseFloat(sum_ib.sumDeposit).toFixed(4) : "0.00"}
                                        </Typography>
                                   </Grid>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                   <Grid mt={3} className={classes.cardGridStyle}>
                                        <Typography sx={{ fontSize: 14 }}>Sum Withdraw :</Typography>
                                        <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#184b8e" }}>
                                             ${sum_ib?.sumWithDraw ? parseFloat(sum_ib.sumWithDraw).toFixed(4) : "0.00"}
                                        </Typography>
                                   </Grid>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                   <Grid mt={3} className={classes.cardGridStyle}>
                                        <Typography sx={{ fontSize: 14 }}>All Lot :</Typography>
                                        <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#184b8e" }}>
                                             {sum_ib?.allLot ? parseFloat(sum_ib.allLot).toFixed(4) : "0.00"}
                                        </Typography>
                                   </Grid>
                              </Grid>
                         </Grid>
                    )} */}
            </Grid>
            <Grid item xs={12}>
                <GridTable
                    head={headerTable.cashback}
                    body={dataList}
                    count={count}
                    page={page}
                    handleChangePage={handleChangePage}
                    language={language}
                />
            </Grid>
        </Grid>
    );
};
const useStyles = makeStyles((theme) => ({
    cardGridStyle: {
        borderRadius: "5px",
        border: "solid 1px #ebebeb",
        background: "#fff",
        padding: 20,
    },
    //      imgStyle: {
    //           height: 230,
    //           [theme.breakpoints.up("sm")]: {
    //                objectFit: "contain",
    //                height: 340,
    //           },
    //           [theme.breakpoints.up("md")]: {
    //                objectFit: "cover",
    //                height: 340,
    //           },
    //           [theme.breakpoints.up("lg")]: {
    //                objectFit: "contain",
    //                height: 340,
    //           },
    //      },
    //      borderSolid: {
    //           width: "100%",
    //           border: "solid 1px #fc0316",
    //           background: "#fc0316",
    //           [theme.breakpoints.up("md")]: {
    //                width: 100,
    //           },
    //      },
    //      fontStyleTitle: {
    //           fontSize: 24,
    //           color: "#000",
    //           marginTop: 15,
    //           fontWeight: 600,
    //           paddingLeft: 16,
    //           paddingRight: 16,
    //           [theme.breakpoints.up("md")]: {
    //                paddingLeft: 0,
    //                paddingRight: 0,
    //                marginTop: 0,
    //           },
    //      },
    //      fontStyleDetail: {
    //           fontSize: 13,
    //           color: "#000",
    //           textAlign: "justify",
    //           marginTop: 24,
    //           paddingLeft: 16,
    //           paddingRight: 16,
    //           [theme.breakpoints.up("md")]: {
    //                paddingLeft: 0,
    //                paddingRight: 0,
    //                fontSize: 18,
    //           },
    //      },
    //      btnStyle: {
    //           textAlign: "right",
    //           marginTop: 3,
    //           [theme.breakpoints.up("md")]: {
    //                marginTop: 16,
    //                textAlign: "left",
    //           },
    //      },
}));
export default withRouter(Transaction);
