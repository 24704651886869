import { Container } from "@material-ui/core";
import { textFiled } from "functions/Static";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ADDSUPPORT, GETTYPESUPPORT, POST } from "service";
import AddSupportCard from "./component/AddSupportCard";
import swal from "sweetalert2";
import LoadingPage from "components/LoadingPage/LoadingPage";
export class AddSupport extends Component {
     constructor(props) {
          super(props);

          this.state = {
               value_edit: "",
               type_support: [],
               language_state: localStorage.getItem("language"),
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getType();
     };

     handleChange = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     getType = async () => {
          try {
               this.setState({ loading: true });
               let getType = await POST(GETTYPESUPPORT);
               if (getType.success) {
                    this.setState({ type_support: getType.result, loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "warning", text: getType.message, icon: "warning",
                    //  timer: 6000, 
                     showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "warning", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickSelect = (type) => {
          this.setState({ type_select: type });
     };

     onChangeTextEdit = (e) => {
          this.setState({ value_edit: e });
     };

     onClickAdd = async () => {
          const { type_select, value_edit, support_name, language_state } = this.state;
          try {
               this.setState({ loading: true });
               let addSupport = await POST(ADDSUPPORT, { type: type_select, name_ticket: support_name, problem: value_edit });
               if (addSupport.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "Success",
                         text: `${language_state === "th" ? "สร้างคำสั่งเสร็จสิ้น" : "Create support success"}`,
                         icon: "success",
                         confirmButtonText: "OK",
                    }).then((result) => {
                         if (result.isConfirmed) {
                              this.props.history.push("/support");
                         }
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "warning", text: addSupport.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "warning", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     render() {
          const { type_support, loading } = this.state;
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl">
                    <AddSupportCard
                         language={language}
                         state={this.state}
                         handleChange={this.handleChange}
                         textField_info={textFiled.textfield_support}
                         onClickAdd={this.onClickAdd}
                         type_support={type_support}
                         onClickSelect={this.onClickSelect}
                         onChangeTextEdit={this.onChangeTextEdit}
                    />
                    <LoadingPage open={loading} />
               </Container>
          );
     }
}

export default withRouter(AddSupport);
