import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, Stack, Typography } from "@material-ui/core";
import GridTableMember from "./GridTableMember";
import { headerTable } from "../../../functions/Static";
import DatePicker from "react-date-picker";

const Tranfers = (props) => {
     const classes = useStyles();
     const { data, language, page, handleChangePage, handleDateEndMemberChange, handleDateStartMemberChange, state, onClickSubmitSearchMember } = props;
     const count = Math.ceil(data.length > 0 && data.length / 10);
     const setPage = page - 1;
     const dataList = data.slice(setPage * 10, page * 10);
     return (
          <Grid container justifyContent="center" alignItems="center" mt={5}>
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Membership</Typography>
                    <Stack direction="row" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, alignSelf: "flex-start" }}>{language === "th" ? "ค้นหา" : "Search"}:</Typography>
                         <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                              <Grid item xs={12} md={4}>
                                   <DatePicker className={"date-picker"} onChange={handleDateStartMemberChange} value={state.date_start_member} format="dd/MM/yyyy" />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                   <DatePicker className={"date-picker"} onChange={handleDateEndMemberChange} value={state.date_end_member} format="dd/MM/yyyy" />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                   <Button
                                        variant="contained"
                                        style={{ backgroundColor:"#4589C6"}}
                                        //  className="btn-style"
                                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                        onClick={onClickSubmitSearchMember}
                                   >
                                        {language === "th" ? "ค้นหา" : "Search"}
                                   </Button>
                              </Grid>
                         </Grid>
                    </Stack>
                    {/* <Stack direction="row" mt={2}>
                         <Typography sx={{ fontSize: 20, color: "#000", mr: 3 }}>Remark :</Typography>
                         <Grid>
                              <Stack direction="row" alignItems="center">
                                   <CardMedia className={classes.cardIcon} component="img" alt="Logo" image={inviteTrue} />
                                   <Typography sx={{ fontSize: 20, color: "#5ce153" }}>Found deposit</Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                   <CardMedia className={classes.cardIcon} component="img" alt="Logo" image={inviteFalse} />
                                   <Typography sx={{ fontSize: 20, color: "#fc0316" }}>Not Found deposit</Typography>
                              </Stack>
                         </Grid>
                    </Stack> */}
               </Grid>
               {/* <Grid item xs={12} textAlign="right">
                    <Button
                         variant="contained"
                         color="error"
                         sx={{ maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40 }}
                         // onClick={handleOpen}
                    >
                         {language === "th" ? "ออกรายงาน" : "Export Report"}
                    </Button>
               </Grid> */}
               <Grid item xs={12} mt={2}>
                    <GridTableMember head={headerTable.member} body={dataList} count={count} page={page} handleChangePage={handleChangePage} language={language} />
               </Grid>
          </Grid>
     );
};
const useStyles = makeStyles((theme) => ({
     cardIcon: {
          height: 20,
          objectFit: "contain",
          width: "auto",
          marginRight: 8,
          // [theme.breakpoints.up("sm")]: {
          //      height: 25,
          // },
     },
}));
export default withRouter(Tranfers);
