import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const Tranfers = (props) => {
     const [hover, setHover] = useState(false);
     const [index, setIndex] = useState();
     const classes = useStyles();
     const { data, language } = props;
     const [state, setState] = React.useState({
          open: false,
          obj: {},
     });
     // toLocaleString("en-US")
     const handleOpen = (el, text) => setState({ ...state, open: true, obj: { ...el, text, available_wallet: "$2,760.20" } });
     const handleClose = () => setState({ ...state, open: false, obj: {} });
     const onMouseEnter = (i) => {
          setHover(true);
          setIndex(i);
     };
     const onMouseLeave = (i) => {
          setHover(false);
          setIndex(i);
     };
     return (
          <Grid container rowSpacing={5} justifyContent="center" alignItems="center">
               <Grid item xs={12}>
                    {/* <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #fc0316" }}>Internal Tranfers</Typography> */}
                    <Grid container direction="row" mt={2}>
                         <Typography sx={{ fontSize: 26, fontWeight: 600, color: "#000", mr: 3, display: { xs: "none", md: "inline" } }}>AVAILABLE FUNDS IN WALLET</Typography>
                         <Typography sx={{ fontSize: 26, fontWeight: 600, color: "#000", mr: 3, display: { xs: "inline", md: "none" } }}>WALLET</Typography>
                         <Typography sx={{ fontSize: 26, color: "#184b8e" }}>${props.profile.wallet ? Math.floor(props.profile.wallet * 100) / 100 : "0.00"}</Typography>
                    </Grid>
                    <Grid container direction="row" mt={2} mb={2}>
                         <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", mr: 3, display: { xs: "none", md: "inline" } }}>AVAILABLE CREDIT</Typography>
                         <Typography sx={{ fontSize: 26, fontWeight: 600, color: "#000", mr: 3, display: { xs: "inline", md: "none" } }}>CREDIT</Typography>
                         <Typography sx={{ fontSize: 24, color: "#184b8e" }}>${props.avaliableCredit.toLocaleString('en-US', { minimumFractionDigits: 2 })}</Typography>
                    </Grid>
                    {/* <Grid xs={12} mt={2} sx={{ display: { xs: "block", md: "none",} }}>
                         <Grid xs={12}>
                              <Typography sx={{ fontSize: 26, fontWeight: 600, color: "#000", mr: 3 }}>AVAILABLE FUNDS IN WALLET</Typography>
                         </Grid>
                         <Grid xs={12}>
                              <Typography sx={{ fontSize: 26, color: "#184b8e" }}>$2,760.20</Typography>
                         </Grid>
                    </Grid> */}
               </Grid>
               {/* <Grid item xs={12} container rowSpacing={3} columnSpacing={3}>
                    {data.map((e, i) => (
                         <Grid xs={12} md={6} lg={4} key={i} item className="zoom-tranfer">
                              <Card p={3} sx={{ borderRadius: 2 }} onMouseEnter={() => onMouseEnter(i)} onMouseLeave={() => onMouseLeave(i)} className="zoom">
                                   <Stack
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="row"
                                        className={classes.hederStyle}
                                   >
                                        <Typography sx={{ fontSize: 16, fontWeight: 600, mr: 1 }}>TRADING ACCOUNT NO:</Typography>
                                        <Typography sx={{ fontSize: 16 }}>{e.accountNO}</Typography>
                                   </Stack>
                                   <Grid container rowSpacing={1} pt={2} pb={2} className={classes.contentStyleHover}>
                                        {e.arrDetail.map((el, ind) => (
                                             <Grid key={ind} item xs={12} container columnSpacing={2}>
                                                  <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                                                       <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{el.title}</Typography>
                                                  </Grid>
                                                  <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                                                       <Typography sx={{ fontSize: 14 }}>{el.value}</Typography>
                                                  </Grid>
                                             </Grid>
                                        ))}
                                        <Grid item xs={12} mt={4}>
                                             <Container maxWidth="sm">
                                                  <Grid container textAlign="center" columnSpacing={1} rowSpacing={2}>
                                                       <Grid item xs={12}>
                                                            <Button
                                                                 variant="contained"
                                                                 color="error"
                                                                 className={classes.buttonContain}
                                                                 onClick={() => handleOpen(e, language === "th" ? "วอเลท ทู เทรดดิ้ง" : "Wallet To Trading")}
                                                            >
                                                                 Wallet To Trading
                                                            </Button>
                                                       </Grid>
                                                       <Grid item xs={12}>
                                                            <Button
                                                                 variant="contained"
                                                                 color="error"
                                                                 className={classes.buttonOutline}
                                                                 onClick={() => handleOpen(e, language === "th" ? "เทรดดิ้ง ทู วอเลท" : "Trading to wallet")}
                                                            >
                                                                 Trading to wallet
                                                            </Button>
                                                       </Grid>
                                                  </Grid>
                                             </Container>
                                        </Grid>
                                   </Grid>
                              </Card>
                         </Grid>
                    ))}
               </Grid> */}
               {/* <ModalTranfers open={state.open} detail={state.obj} handleClose={handleClose} language={language} /> */}
          </Grid>
     );
};

const useStyles = makeStyles(() => ({
     hederStyle: {
          background: "#fc0316",
          color: "#fff",
          height: 60,
     },
     // hederStyleHover: {
     //      background: "#fff",
     //      color: "#fc0316",
     //      height: 60,
     // },
     // contentStyleHover: {
     //      background: "#fc0316",
     //      color: "#fff",
     // },
     buttonOutline: {
          maxWidth: 200,
          fontSize: 12,
          width: "100%",
          borderRadius: 40,
          background: "#fff",
          color: "#fc0316",
          border: "1px solid #fc0316",
     },
     // buttonOutlineHover: {
     //      maxWidth: 200,
     //      fontSize: 12,
     //      width: "100%",
     //      borderRadius: 40,
     //      background: "#fc0316",
     //      color: "#fff",
     //      border: "1px solid #fff",
     // },
     buttonContain: {
          maxWidth: 200,
          fontSize: 12,
          width: "100%",
          borderRadius: 40,
          background: "#fc0316",
          color: "#fff",
     },
     // buttonContainHover: {
     //      maxWidth: 200,
     //      fontSize: 12,
     //      width: "100%",
     //      borderRadius: 40,
     //      background: "#fff",
     //      color: "#fc0316",
     // },
}));
// const useStyles = makeStyles((theme) => ({
//      imgStyle: {
//           height: 230,
//           [theme.breakpoints.up("sm")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//           [theme.breakpoints.up("md")]: {
//                objectFit: "cover",
//                height: 340,
//           },
//           [theme.breakpoints.up("lg")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//      },
//      borderSolid: {
//           width: "100%",
//           border: "solid 1px #fc0316",
//           background: "#fc0316",
//           [theme.breakpoints.up("md")]: {
//                width: 100,
//           },
//      },
//      fontStyleTitle: {
//           fontSize: 24,
//           color: "#000",
//           marginTop: 15,
//           fontWeight: 600,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                marginTop: 0,
//           },
//      },
//      fontStyleDetail: {
//           fontSize: 13,
//           color: "#000",
//           textAlign: "justify",
//           marginTop: 24,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                fontSize: 18,
//           },
//      },
//      btnStyle: {
//           textAlign: "right",
//           marginTop: 3,
//           [theme.breakpoints.up("md")]: {
//                marginTop: 16,
//                textAlign: "left",
//           },
//      },
// }));
export default withRouter(Tranfers);
