import { Button, Container, Grid } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { GridTextField } from "components";
import { withRouter } from "react-router-dom";
import '../../../css/index.scss';
const theme = createMuiTheme();

const TapCrypto = (props) => {
    const { language, handleChangeText, inputValue, handleSubmit, data, menuItem, profile } = props;
    return (
        <Grid>
            <Grid mt={5} container justifyContent="center" alignItems="center" rowSpacing={2}>
                <Grid item xs={12}>
                    <Grid item container justifyContent="center" xs={12} marginTop={2}>
                        <Grid item container spacing={1} xs={12} md={6}>
                            <GridTextField data={data} state={inputValue} language={language} handleChange={handleChangeText} menuItem={menuItem} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={10}>
                    <Container maxWidth="sm">
                        <Grid container textAlign="center" columnSpacing={1} rowSpacing={3} justifyContent="center">
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: "#4589C6" }}
                                    className="btn-style" sx={{ maxWidth: 260 }}
                                    onClick={handleSubmit}
                                    disabled={profile.crypto_type && profile.address_usdt}
                                >
                                    {language === "th" ? "บันทึก" : "Svae"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(TapCrypto);
