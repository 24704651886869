import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Tabs, Tab, TableContainer, Table, Box, Typography, Stack, Card } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const TapReportMeta = (props) => {
     const classes = useStyles();
     const [value, setValue] = React.useState(1);
     const handleChange = (event, newValue) => {
          setValue(newValue);
          props.setTap(newValue);
     };
     return (
          <Grid>
               <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {props.language === "th" ? "รายงานการซื้อขาย" : "Trading Reports"}
               </Typography>
               <Stack direction="row" alignItems="center" mt={2}></Stack>
               <Grid container justifyContent="center" alignItems="center" columnSpacing={2}>
                    <Grid item xs={3} sx={{ display: { xs: "none", sm: "none", md: "block" }, mb: 3, mt: 3 }}>
                         <Card sx={{ py: 2, px: 3 }}>
                              <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                   {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                                   <Grid>
                                        <Typography className={classes.titleStyle}>Deposit</Typography>
                                        <Typography className={classes.moneyStyle}>$4,230.00</Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                    </Grid>
                    <Grid item xs={3} sx={{ display: { xs: "none", sm: "none", md: "block" }, mb: 3, mt: 3 }}>
                         <Card sx={{ py: 2, px: 3 }}>
                              <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                   {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                                   <Grid>
                                        <Typography className={classes.titleStyle}>Withdrawal</Typography>
                                        <Typography className={classes.moneyStyle}>$163.00</Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                    </Grid>
                    <Grid item xs={3} sx={{ display: { xs: "none", sm: "none", md: "block" }, mb: 3, mt: 3 }}>
                         <Card sx={{ py: 2, px: 3 }}>
                              <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                   {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                                   <Grid>
                                        <Typography className={classes.titleStyle}>MT4 Balance</Typography>
                                        <Typography className={classes.moneyStyle}>$259.00</Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                    </Grid>
                    <Grid item xs={3} sx={{ display: { xs: "none", sm: "none", md: "block" }, mb: 3, mt: 3 }}>
                         <Card sx={{ py: 2, px: 3 }}>
                              <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                   {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                                   <Grid>
                                        <Typography className={classes.titleStyle}>Profit</Typography>
                                        <Typography className={classes.moneyStyle}>$0.00</Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                    </Grid>

                    <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" }, marginBottom: 1 }}>
                         <Card sx={{ py: 2, px: 3 }}>
                              <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                   {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                                   <Grid>
                                        <Typography className={classes.titleStyle}>Deposit</Typography>
                                        <Typography className={classes.moneyStyle}>$2,760.20</Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                    </Grid>
                    <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" }, marginBottom: 1 }}>
                         <Card sx={{ py: 2, px: 3 }}>
                              <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                   {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                                   <Grid>
                                        <Typography className={classes.titleStyle}>Deposit</Typography>
                                        <Typography className={classes.moneyStyle}>$4,230.00</Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                    </Grid>
                    <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" }, marginBottom: 1 }}>
                         <Card sx={{ py: 2, px: 3 }}>
                              <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                   {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                                   <Grid>
                                        <Typography className={classes.titleStyle}>MT4 Balance</Typography>
                                        <Typography className={classes.moneyStyle}>$259.00</Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                    </Grid>
                    <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" }, marginBottom: 1 }}>
                         <Card sx={{ py: 2, px: 3 }}>
                              <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                   {/* <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} /> */}
                                   <Grid>
                                        <Typography className={classes.titleStyle}>Profit</Typography>
                                        <Typography className={classes.moneyStyle}>$0.00</Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                    </Grid>
               </Grid>
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     fontStyleTitle: {
          fontSize: 14,
          color: "#000",
          fontWeight: 500,
          [theme.breakpoints.up("md")]: {
               marginTop: 0,
               fontSize: 18,
          },
     },
     titleStyle: {
          fontSize: 16,
          color: "#000",
          fontWeight: 500,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
     moneyStyle: {
          fontSize: 22,
          color: "#4589C6",
          fontWeight: 600,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
     imgStyle: {
          height: 50,
          objectFit: "contain",
          width: "auto",
          marginRight: 8,
          // [theme.breakpoints.up("sm")]: {
          //      height: 80,
          // },
     },
     fontStyleDetail: {
          fontSize: 12,
          color: "#676767",
          textAlign: "justify",
          marginTop: 24,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 18,
          // },
     },
     fontStyleBodyTitle: {
          fontSize: 12,
          marginTop: 24,
          fontWeight: 600,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 18,
          // },
     },
     fontStyleBodyDetail: {
          fontSize: 12,
          paddingLeft: 8,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 14,
          // },
     },
}));
export default withRouter(TapReportMeta);
