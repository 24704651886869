import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import DetailInbox from "./DetailIndox/DetailInbox";
import mail from "../../assets/logo/mail.png";
import readMail from "../../assets/logo/read-mail.png";

export class Inbox extends Component {
     constructor(props) {
          super(props);

          this.state = {
               search_text: "",
               page: 1,
               data: [],
          };
     }
     componentDidMount = () => {
          this.setState({ data: dataTableTD });
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };
     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     submitSearch = () => {
          let { search_text } = this.state;
          let data = dataTableTD.filter(
               (el) =>
                    (search_text ? el.subject.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.sender.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.date.toLowerCase().includes(search_text.toLowerCase()) : el)
          );
          this.setState({ data, page: 1 });
     };
     render() {
          const language = localStorage.getItem("language");
          const { page, data } = this.state;
          return (
               <Container maxWidth="xl">
                    <DetailInbox
                         language={language}
                         data={data}
                         page={page}
                         handleChangePage={this.handleChangePage}
                         handleSearch={this.handleSearch}
                         state={this.state}
                         submitSearch={this.submitSearch}
                    />
               </Container>
          );
     }
}
const dataTableTD = [
     {
          id: 1,
          subject: "Approved Transaction Deposit funds : DF-20210326000239",
          sender: "admin",
          date: "2021-06-14 12:48",
          read: 1,
          img_true: mail,
          img_false: readMail,
     },
     {
          id: 2,
          subject: "Approved Transaction Deposit funds : DF-20210326000239",
          sender: "admin",
          date: "2021-06-14 12:48",
          read: 1,
          img_true: mail,
          img_false: readMail,
     },
     {
          id: 3,
          subject: "Promotion : DF-20210326000239",
          sender: "admin",
          date: "2021-06-14 12:48",
          read: 0,
          img_true: mail,
          img_false: readMail,
     },
     {
          id: 4,
          subject: "Promotion : DF-20210326000239",
          sender: "admin",
          date: "2021-06-14 12:48",
          read: 0,
          img_true: mail,
          img_false: readMail,
     },
     {
          id: 5,
          subject: "Seminar Course : DF-20210326000239",
          sender: "admin",
          date: "2021-06-14 12:48",
          read: 0,
          img_true: mail,
          img_false: readMail,
     },
];
export default withRouter(Inbox);
