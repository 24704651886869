import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import DetailTaps from "./DetailTaps/DetailTaps";
import { TraderTD, TranfersTD, TransactionTD } from "./Trader/index";
import { TraderIB, TranfersIB, TransactionIB } from "./IB/index";
import { TraderCashback, TranfersCashback, TransactionCashback } from "./Cashback/index";
import eye from "../../assets/logo/eye.png";
import ModalReal from "./Trader/ModalReal";
import ModalDemo from "./Trader/ModalDemo";
import {
    ADDTRADINGDEMO,
    GET,
    POST,
    GETTRADINGACCOUNT,
    CHANGEPASSWORD,
    GETACCOUNTTYPE,
    ADDTRADINGREAL,
    DEPOSITMT4,
    WITHDRAWMT4,
    GETTRANSACTIONIB,
    TRANFERTOWALLET,
    GETIB,
    GETTRANSACTIONMT4,
    CHANGELEVERAGE,
    REGISTERIB,
    GETIBSUM,
    PROFILEAPI,
    DELETEACCOUNT,
    GETTRANSACTIONCASHBACK,
    TRANFERTOWALLETCASHBACK,
} from "service";
import swal from "sweetalert2";
import ModalEditTrading from "./Trader/ModalEditTrading";
import LoadingPage from "components/LoadingPage/LoadingPage";
import ModalTranfers from "./Trader/ModalTranfers";
import moment from "moment";
import ModalChangeLeverage from "./Trader/ModalChangeLeverage";

export class Trading extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language_state: localStorage.getItem("language"),
            tap_value: 1,
            page_td: 1,
            search_text_td: "",
            data_transaction_td: [],
            //-----------------------//
            page_ib: 1,
            search_text_ib: "",
            data_transaction_ib: [],
            //-----------------------//
            page_cashback: 1,
            search_text_cashback: "",
            search_cashback: "",
            data_transaction_cashback: [],
            //-----------------------//
            page_member: 1,
            data_member: [],
            openModalReal: false,
            openModalRealMT5: false,
            openEdit: false,
            real: [],
            demo: [],
            loading: false,
            trader_password: "",
            invester_password: "",
            confirm: "",
            account_type: "Select account type",
            date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end: new Date(),
            date_start_member: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end_member: new Date(),
            date_start_mt4: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end_mt4: new Date(),
            date_start_cashback: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end_cashback: new Date(),
            transaction_ib: [],
            transactiom_mt4: [],
            open_changeLeverage: false,
            realLeverageSelect: [],
            avaliableCredit: "",
            profile: [],
            leverage_select: null,
            transaction_cashback: [],
            account_type_list: [],
            meta_type: "MT4",
        };
    }
    componentDidMount = () => {
        this.setState({ data_transaction_td: dataTableTD, data_transaction_ib: dataTableIB });
        this.getTradingAccount();
        this.getAccountType();
        this.getTransactionIB();
        this.getTransactionMT4();
        this.getProfile();
        this.getTransactionCashback();
        // this.getIB();
        // this.getSumIB();
    };

    getTradingAccount = async () => {
        try {
            this.setState({ loading: true });
            let getTradingAccount = await POST(GETTRADINGACCOUNT);
            if (getTradingAccount.success) {
                let info = getTradingAccount.result;
                this.setState({ real: info.real, demo: info.demo });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: getTradingAccount.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };
    getAccountType = async () => {
        try {
            this.setState({ loading: true });
            let getAccountType = await POST(GETACCOUNTTYPE);
            if (getAccountType.success) {
                let account_type_list = [{ label: "Select account type", value: "Select account type", disabled: true }];
                getAccountType.result.map((el) => account_type_list.push({ label: el.account_type_name, value: el.account_type_id, type: el.type }));
                this.setState({ account_type_list, loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: getAccountType.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                //  timer: 6000,
                showConfirmButton: true,
            });
        }
    };

    getTransactionMT4 = async () => {
        const { date_start_mt4, date_end_mt4 } = this.state;
        let date_start_format = moment(date_start_mt4).format("YYYY-MM-DD");
        let date_end_format = moment(date_end_mt4).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let getTransactionMT4 = await POST(GETTRANSACTIONMT4, { date_start: date_start_format, date_end: date_end_format });
            if (getTransactionMT4.success) {
                this.setState({ transactiom_mt4: getTransactionMT4.result });
                this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "Warning",
                    text: getTransactionMT4.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                //  timer: 6000,
                showConfirmButton: true,
            });
            this.setState({ loading: false });
        }
    };

    getTransactionIB = async () => {
        const { date_start, date_end } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let getTransactionIB = await POST(GETTRANSACTIONIB, { date_start: date_start_format, date_end: date_end_format });
            if (getTransactionIB.success) {
                this.setState({ transaction_ib: getTransactionIB.result });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: getTransactionIB.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };

    getIB = async () => {
        const { date_end_member, date_start_member } = this.state;
        let date_start_format = moment(date_start_member).format("YYYY-MM-DD");
        let date_end_format = moment(date_end_member).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let getIB = await POST(GETIB, { date_start: date_start_format, date_end: date_end_format });
            if (getIB.success) {
                this.setState({ data_member: getIB.result });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                // swal.fire({ title: "Warning", text: getIB.message, icon: "warning", timer: 1800, showConfirmButton: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };

    getSumIB = async () => {
        const { date_start, date_end } = this.state;
        if (this.props.profile.is_ib === 1) {
            let sum_ib = await POST(GETIBSUM, {
                date_start: moment(date_start).format("YYYY-MM-DD"),
                date_end: moment(date_end).format("YYYY-MM-DD"),
            });
            if (sum_ib.success) {
                this.setState({ sum_ib: sum_ib.result });
            }
        }
    };

    getTransactionCashback = async () => {
        const { date_start_cashback, date_end_cashback, search_cashback } = this.state;
        let date_start_format = moment(date_start_cashback).format("YYYY-MM-DD");
        let date_end_format = moment(date_end_cashback).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let getTransactionCashback = await POST(GETTRANSACTIONCASHBACK, {
                date_start: date_start_format,
                date_end: date_end_format,
                search: search_cashback,
            });
            if (getTransactionCashback.success) {
                this.setState({ transaction_cashback: getTransactionCashback.result });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: getTransactionCashback.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };

    handleChangePage = (e, value) => {
        if (this.state.tap_value === 1) {
            this.setState({ page_td: value });
        } else if (this.state.tap_value === 2) {
            this.setState({ page_ib: value });
        } else {
            this.setState({ page_cashback: value });
        }
    };

    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    submitSearch = () => {
        let { tap_value, date_start, date_start_mt4, date_end, date_end_mt4, search } = this.state;
        if (tap_value === 1) {
            this.getTransactionMT4();
        } else if (tap_value === 2) {
            this.getSumIB();
            this.getTransactionIB();
        } else {
            this.getTransactionCashback();
        }
    };

    onClickAddAccount = (type, meta_type) => {
        if (type === "real") {
            this.setState({ openModalReal: true, meta_type });
        } else if (type === "realmt5") {
            this.setState({ openModalRealMT5: true, meta_type });
        } else {
            this.setState({ openModalDemo: true, meta_type });
        }
    };

    handleCloseModal = () => {
        this.setState({
            openModalReal: false,
            openModalRealMT5: false,
            openModalDemo: false,
            openEdit: false,
            openTranfer: false,
            open_changeLeverage: false,
        });
    };

    onClickAddDemo = async () => {
        const { amount, language_state, meta_type } = this.state;
        try {
            if (amount) {
                swal.fire({
                    text: `${language_state === "th" ? "ต้องการเพิ่มบัญชีทดสอบหรือไม่" : "Do you want add demo account?"}`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ loading: true });
                        let addDemo = await POST(ADDTRADINGDEMO, { money: amount, meta_type });
                        if (addDemo.success) {
                            swal.fire({
                                title: "Success",
                                text: `${language_state === "th" ? "เพิ่มบัญชีทดสอบสำเร็จ" : "Add Demo Account Completed"}`,
                                icon: "success",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            this.setState({ loading: false });
                            window.location.reload();
                        } else {
                            swal.fire({
                                title: "Warning",
                                text: addDemo.message,
                                icon: "warning",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            this.setState({ loading: false });
                        }
                    }
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณากรอกจำนวนเงินที่ต้องการ" : "Please fill amount"}`,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
            this.setState({ loading: false });
        }
    };

    onClickEdit = (type, type_edit, mt4_id) => {
        this.setState({ typeEdit: type, openEdit: true, type_edit: type_edit, mt4_id: mt4_id });
    };

    onClickChangeLeverage = (mt4_id, leverage, leverage_select) => {
        this.setState({ mt4_id: mt4_id, open_changeLeverage: true, leverage: leverage, leverage_select: leverage_select });
    };

    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    onClickAddTypeAccount = async () => {
        const { account_type, language_state } = this.state;
        try {
            if (account_type !== "Select account type") {
                swal.fire({
                    text: `${language_state === "th" ? "ต้องการเพิ่มบัญชีหรือไม่" : "Do you want to Add Account?"}`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ loading: true });
                        let addAccount = await POST(ADDTRADINGREAL, { account_type_id: account_type });
                        if (addAccount.success) {
                            swal.fire({
                                title: "Success",
                                text: `${language_state === "th" ? "เพิ่มบัญชีสำเร็จ" : "Add Account Completed"}`,
                                icon: "success",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            this.setState({ loading: false });
                            window.location.reload();
                        } else {
                            swal.fire({
                                title: "Warning",
                                text: addAccount.message,
                                icon: "warning",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            this.setState({ loading: false });
                        }
                    }
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณาเลือกประเภทแอคเคาท์" : "Please select account type"}`,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
            this.setState({ loading: false });
        }
    };

    onClickChangePassword = async (type_edit) => {
        const { mt4_id, trader_password, invester_password, language_state, confirm } = this.state;
        let whichPassword, password;
        if (type_edit === "trader") {
            password = trader_password;
            whichPassword = 1;
        } else {
            password = invester_password;
            whichPassword = 2;
        }
        if (password.length < 5) {
            this.setState({
                error: true,
                error_massage: `${
                    language_state === "th"
                        ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 5 ตัวอักษรขึ้นไป"
                        : "Your password needs a minimum of five characters"
                }`,
            });
        } else if (password.search(/[a-z]/) < 0) {
            this.setState({
                error: true,
                error_massage: `${
                    language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your password needs a lower case letter"
                }`,
            });
        } else if (password.search(/[A-Z]/) < 0) {
            this.setState({
                error: true,
                error_massage: `${
                    language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your password needs an uppser case letter"
                }`,
            });
        } else if (password.search(/[0-9]/) < 0) {
            this.setState({
                error: true,
                error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your password needs a number"}`,
            });
        } else {
            if (password && confirm) {
                if (password === confirm) {
                    this.setState({ error: false, error_massage: "" });
                    this.handleCloseModal();
                    try {
                        swal.fire({
                            text: `${language_state === "th" ? "ต้องการเปลี่ยนรหัสผ่านหรือไม่" : "Do you want to change password?"}`,
                            icon: "question",
                            showCancelButton: true,
                            confirmButtonText: "Submit",
                            cancelButtonText: "Cancel",
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                this.setState({ loading: true });
                                let changePassword = await POST(CHANGEPASSWORD, { mt4_id, password, whichPassword });
                                if (changePassword.success) {
                                    swal.fire({
                                        title: "Success",
                                        text: `${language_state === "th" ? "เปลี่ยนรหัสผ่านสำเร็จ" : "Change Password Completed"}`,
                                        icon: "success",
                                        // timer: 6000,
                                        showConfirmButton: true,
                                    });
                                    this.setState({ loading: false });
                                    window.location.reload();
                                } else {
                                    swal.fire({
                                        title: "Warning",
                                        text: changePassword.message,
                                        icon: "warning",
                                        // timer: 6000,
                                        showConfirmButton: true,
                                    });
                                    this.setState({ loading: false });
                                }
                            }
                        });
                    } catch (error) {
                        this.setState({ loading: false });
                        swal.fire({
                            title: "Warning",
                            text: error,
                            icon: "warning",
                            // timer: 6000,
                            showConfirmButton: true,
                        });
                        this.setState({ loading: false });
                        this.handleCloseModal();
                    }
                } else {
                    this.setState({ loading: false });
                    this.setState({ error: true, error_massage: `${language_state === "th" ? "รหัสผ่านไม่ตรงกัน" : "Password Not Match"}` });
                }
            } else {
                this.setState({ loading: false });
                this.setState({ error: true, error_massage: `${language_state === "th" ? "กรุณากรอกให้ครบทุกช่อง" : "Please fill complete"}` });
            }
        }
    };

    handleOpen = (el, text, type, mt4_id) => {
        // console.log('type', type)
        this.setState({ openTranfer: true, objTranfer: { ...el, text, available_wallet: "$0", type: type }, mt4_id: mt4_id });
    };

    onClickDeposit = async () => {
        const { money, language_state, mt4_id } = this.state;
        try {
            if (money) {
                swal.fire({
                    text: `${language_state === "th" ? "ต้องการฝากเงินหรือไม่" : "Do you want to deposit?"}`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ loading: true });
                        let deposit_mt4 = await POST(DEPOSITMT4, { mt4_id, money });
                        if (deposit_mt4.success) {
                            swal.fire({
                                title: "Success",
                                text: `${language_state === "th" ? "ฝากเงินสำเร็จ" : "Deposit money Completed"}`,
                                icon: "success",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            this.setState({ loading: false });
                            window.location.reload();
                        } else {
                            this.setState({ loading: false });
                            swal.fire({
                                title: "Warning",
                                text: deposit_mt4.message,
                                icon: "warning",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                        }
                    }
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณากรอกจำนวนเงิน" : "Please fill your amont"}`,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };
    onClickWithdraw = async () => {
        const { money, language_state, mt4_id } = this.state;
        try {
            if (money) {
                swal.fire({
                    text: `${language_state === "th" ? "ต้องการถอนเงินหรือไม่" : "Do you want to withdraw?"}`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ loading: true });
                        let withdraw_mt4 = await POST(WITHDRAWMT4, { mt4_id, money });
                        if (withdraw_mt4.success) {
                            swal.fire({
                                title: "Success",
                                text: `${language_state === "th" ? "ถอนเงินสำเร็จ" : "Withdraw money Completed"}`,
                                icon: "success",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            this.setState({ loading: false });
                            window.location.reload();
                        } else {
                            this.setState({ loading: false });
                            swal.fire({
                                title: "Warning",
                                text: withdraw_mt4.message,
                                icon: "warning",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                        }
                    }
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณากรอกจำนวนเงิน" : "Please fill your amont"}`,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };

    onClickTransferToWallet = async () => {
        const { money_tranfer, language_state } = this.state;
        try {
            if (money_tranfer) {
                swal.fire({
                    text: `${language_state === "th" ? "Do you want tranfer to wallet?" : "Do you want tranfer to wallet?"}`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ loading: true });
                        let tranferToWallet = await POST(TRANFERTOWALLET, { money: money_tranfer });
                        if (tranferToWallet.success) {
                            swal.fire({
                                title: "Success",
                                text: `${language_state === "th" ? "Tranfer to wallet completed" : "Tranfer to wallet completed"}`,
                                icon: "success",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            this.setState({ loading: false });
                            window.location.reload();
                        } else {
                            this.setState({ loading: false });
                            swal.fire({
                                title: "Warning",
                                text: tranferToWallet.message,
                                icon: "warning",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                        }
                    }
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณากรอกจำนวนเงิน" : "Please fill your amont"}`,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };

    onClickTransferToWalletCashback = async () => {
        const { money_tranfer_cashback, language_state } = this.state;
        try {
            if (money_tranfer_cashback) {
                swal.fire({
                    text: `${language_state === "th" ? "Do you want tranfer to wallet?" : "Do you want tranfer to wallet?"}`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ loading: true });
                        let tranferToWalletCashback = await POST(TRANFERTOWALLETCASHBACK, { money: money_tranfer_cashback });
                        if (tranferToWalletCashback.success) {
                            swal.fire({
                                title: "Success",
                                text: `${language_state === "th" ? "Tranfer to wallet completed" : "Tranfer to wallet completed"}`,
                                icon: "success",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            this.setState({ loading: false });
                            window.location.reload();
                        } else {
                            this.setState({ loading: false });
                            swal.fire({
                                title: "Warning",
                                text: tranferToWalletCashback.message,
                                icon: "warning",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                        }
                    }
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณากรอกจำนวนเงิน" : "Please fill your amont"}`,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };

    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    handleDateStartMemberChange = (date) => {
        // console.log("start", date);
        this.setState({ date_start_member: date });
    };

    handleDateEndMemberChange = (date) => {
        // console.log("end", date);
        this.setState({ date_end_member: date });
    };

    handleDateStartChangeMT4 = (date) => {
        this.setState({ date_start_mt4: date });
    };

    handleDateEndChangeMT4 = (date) => {
        this.setState({ date_end_mt4: date });
    };

    handleDateStartChangeCashback = (date) => {
        // console.log("date_start_cashback", date);
        this.setState({ date_start_cashback: date });
    };

    handleDateEndChangeCashback = (date) => {
        // console.log("date_end_cashback", date);
        this.setState({ date_end_cashback: date });
    };

    handleChangeSearchCashback = (event) => {
        // console.log("SearchCashback:", event);
        this.setState({ search_cashback: event.target.value });
    };

    onChangeRadio = (event) => {
        // console.log("vvvv",event.target.value);
        this.setState({ leverage: event.target.value });
    };

    onClickSubmitChangeLeverage = async () => {
        const { mt4_id, leverage, language_state } = this.state;
        try {
            if (leverage) {
                let post_leverage = Number(leverage);
                swal.fire({
                    text: `${language_state === "th" ? "คุณต้องการเปลี่ยนแปลง Leverage หรือไม่" : "Do you want to change leverage?"}`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ loading: true });
                        let change_leverage = await POST(CHANGELEVERAGE, { mt4_id, leverage: post_leverage });
                        if (change_leverage.success) {
                            this.setState({ loading: false });
                            swal.fire({
                                title: "Success",
                                text: `${language_state === "th" ? "เปลี่ยนแปลง leverage เรียบร้อย" : "Change leverage completed"}`,
                                icon: "success",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            window.location.reload();
                        } else {
                            this.setState({ loading: false });
                            swal.fire({
                                title: "Warning",
                                text: change_leverage.message,
                                icon: "warning",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                        }
                    }
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณาเลิอกจำนวน Leverage" : "Please select amount leverage"}`,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };

    onClickRegisterIB = () => {
        const { language_state } = this.state;
        swal.fire({
            text: `${language_state === "th" ? "ต้องการสมัคร IB หรือไม่" : "Do you want register IB?"}`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    this.setState({ loading: true });
                    let register_ib = await GET(REGISTERIB);
                    if (register_ib.success) {
                        this.setState({ loading: false });
                        swal.fire({
                            title: "Success",
                            text: `${language_state === "th" ? "สมัคร IB เรียบร้อย" : "Register IB Completed"}`,
                            icon: "success",
                            // timer: 6000,
                            showConfirmButton: true,
                        });
                        window.location.reload();
                    } else {
                        this.setState({ loading: false });
                        swal.fire({
                            title: "Warning",
                            text: register_ib.message,
                            icon: "warning",
                            // timer: 6000,
                            showConfirmButton: true,
                        });
                    }
                } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({
                        title: "Warning",
                        text: error,
                        icon: "warning",
                        // timer: 6000,
                        showConfirmButton: true,
                    });
                }
            }
        });
    };

    onClickSubmitSearchMember = () => {
        this.getIB();
        // console.log("state", this.state);
    };

    async getProfile() {
        const { avaliableCredit, profile } = this.state;
        try {
            this.setState({ loading: true });
            let profile = await GET(PROFILEAPI);

            if (profile.success) {
                this.setState({
                    avaliableCredit: profile.result.avaliableCredit,
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "Warning",
                    text: profile.message,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    }

    onClickDelete = async (user_mt4_id) => {
        const { language_state } = this.state;
        try {
            if (user_mt4_id) {
                swal.fire({
                    text: `${language_state === "th" ? "ต้องการลบบัญชีหรือไม่" : "Do you want to delete account?"}`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ loading: true });
                        let res = await POST(DELETEACCOUNT, { user_mt4_id });
                        if (res.success) {
                            swal.fire({
                                title: "Success",
                                text: `${language_state === "th" ? "ลบบัญชีสำเร็จ" : "Account already deleted"}`,
                                icon: "success",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                            this.setState({ loading: false });
                            window.location.reload();
                        } else {
                            this.setState({ loading: false });
                            swal.fire({
                                title: "Warning",
                                text: res.message,
                                icon: "warning",
                                // timer: 6000,
                                showConfirmButton: true,
                            });
                        }
                    }
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: error,
                icon: "warning",
                // timer: 6000,
                showConfirmButton: true,
            });
        }
    };

    render() {
        const language = localStorage.getItem("language");
        const {
            tap_value,
            page_td,
            page_ib,
            page_cashback,
            page_member,
            data_transaction_td,
            data_transaction_ib,
            data_member,
            openModalReal,
            openModalDemo,
            real,
            demo,
            loading,
            typeEdit,
            openEdit,
            type_edit,
            account_type_list,
            error,
            error_massage,
            openTranfer,
            objTranfer,
            transaction_ib,
            transactiom_mt4,
            open_changeLeverage,
            sum_ib,
            transaction_cashback,
            openModalRealMT5,
        } = this.state;
        const profile = this.props.profile;
        // console.log('')
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                {/* <Grid sx={{ position: "inherit", top: -88 }}> */}
                <Grid sx={{ mb: 10 }}>
                    <DetailTaps profile={profile} setTap={(e) => this.setState({ tap_value: e })} />
                </Grid>
                {tap_value === 1 && (
                    <Grid>
                        <TranfersTD data={dataTranfers} avaliableCredit={this.state.avaliableCredit} language={language} profile={profile} />
                        {/* <TraderTD
                                   data={real.filter(e => e.meta_type == "MT4")}
                                   language={language}
                                   type={"real"}
                                   meta_type={"MT4"}
                                   props_state={this.state}
                                   onClickAddAccount={this.onClickAddAccount}
                                   onClickEdit={this.onClickEdit}
                                   onClickChangeLeverage={this.onClickChangeLeverage}
                                   onClickDeposit={this.onClickDeposit}
                                   onClickWithdraw={this.onClickWithdraw}
                                   handleChangeText={this.handleSearch}
                                   handleOpen={this.handleOpen}
                                   onClickDelete={this.onClickDelete}
                              /> */}
                        <TraderTD
                            data={real.filter((e) => e.meta_type == "MT5")}
                            language={language}
                            type={"realmt5"}
                            meta_type={"MT5"}
                            props_state={this.state}
                            onClickAddAccount={this.onClickAddAccount}
                            onClickEdit={this.onClickEdit}
                            onClickChangeLeverage={this.onClickChangeLeverage}
                            onClickDeposit={this.onClickDeposit}
                            onClickWithdraw={this.onClickWithdraw}
                            handleChangeText={this.handleSearch}
                            handleOpen={this.handleOpen}
                            onClickDelete={this.onClickDelete}
                        />
                        {/* <TraderTD
                                   data={demo.filter(e => e.meta_type == "MT4")}
                                   language={language}
                                   type={"demo"}
                                   meta_type={"MT4"}
                                   props_state={this.state}
                                   onClickAddAccount={this.onClickAddAccount}
                                   onClickEdit={this.onClickEdit}
                                   onClickChangeLeverage={this.onClickChangeLeverage}
                                   onClickDeposit={this.onClickDeposit}
                                   onClickWithdraw={this.onClickWithdraw}
                                   handleChangeText={this.handleSearch}
                                   handleOpen={this.handleOpen}
                                   onClickDelete={this.onClickDelete}
                              /> */}
                        <TraderTD
                            data={demo.filter((e) => e.meta_type == "MT5")}
                            language={language}
                            type={"demo"}
                            meta_type={"MT5"}
                            props_state={this.state}
                            onClickAddAccount={this.onClickAddAccount}
                            onClickEdit={this.onClickEdit}
                            onClickChangeLeverage={this.onClickChangeLeverage}
                            onClickDeposit={this.onClickDeposit}
                            onClickWithdraw={this.onClickWithdraw}
                            handleChangeText={this.handleSearch}
                            handleOpen={this.handleOpen}
                            onClickDelete={this.onClickDelete}
                        />
                        <TransactionTD
                            language={language}
                            data={transactiom_mt4}
                            page={page_td}
                            handleChangePage={this.handleChangePage}
                            handleSearch={this.handleSearch}
                            state={this.state}
                            submitSearch={this.submitSearch}
                            handleDateStartChangeMT4={this.handleDateStartChangeMT4}
                            handleDateEndChangeMT4={this.handleDateEndChangeMT4}
                        />
                        <ModalReal
                            open={openModalReal}
                            handleCloseModal={this.handleCloseModal}
                            state={this.state}
                            language={language}
                            handleSearch={this.handleSearch}
                            account_type_list={account_type_list.filter((e) => e.type == "MT4")}
                            onClickAddTypeAccount={this.onClickAddTypeAccount}
                            loading={loading}
                        />
                        <ModalReal
                            open={openModalRealMT5}
                            handleCloseModal={this.handleCloseModal}
                            state={this.state}
                            language={language}
                            handleSearch={this.handleSearch}
                            account_type_list={account_type_list.filter((e) => e.type == "MT5")}
                            onClickAddTypeAccount={this.onClickAddTypeAccount}
                            loading={loading}
                        />
                        <ModalDemo
                            open={openModalDemo}
                            handleCloseModal={this.handleCloseModal}
                            state={this.state}
                            language={language}
                            handleSearch={this.handleSearch}
                            onClickAddDemo={this.onClickAddDemo}
                            loading={loading}
                        />
                        <ModalEditTrading
                            open={openEdit}
                            type={typeEdit}
                            language={language}
                            loading={loading}
                            handleCloseModal={this.handleCloseModal}
                            state={this.state}
                            handleChange={this.handleChange}
                            type_edit={type_edit}
                            onClickChangePassword={this.onClickChangePassword}
                            error={error}
                            error_massage={error_massage}
                        />
                        <ModalTranfers
                            open={openTranfer}
                            detail={objTranfer}
                            handleClose={this.handleCloseModal}
                            language={language}
                            onClickDeposit={this.onClickDeposit}
                            onClickWithdraw={this.onClickWithdraw}
                            handleChangeText={this.handleSearch}
                            props_state={this.state}
                        />
                        <ModalChangeLeverage
                            open={open_changeLeverage}
                            handleClose={this.handleCloseModal}
                            language={language}
                            onChangeRadio={this.onChangeRadio}
                            onClickSubmitChangeLeverage={this.onClickSubmitChangeLeverage}
                            state={this.state}
                        />
                    </Grid>
                )}

                {tap_value === 2 && (
                    <Grid>
                        <TraderIB
                            data={dataTrader}
                            language={language}
                            profile={profile}
                            onClickTranfer={this.onClickTransferToWallet}
                            state={this.state}
                            handleChangeText={this.handleSearch}
                            onClickRegisterIB={this.onClickRegisterIB}
                        />
                        <TransactionIB
                            language={language}
                            data={transaction_ib}
                            profile={profile}
                            page={page_ib}
                            handleChangePage={this.handleChangePage}
                            handleSearch={this.handleSearch}
                            state={this.state}
                            submitSearch={this.submitSearch}
                            handleDateStartChange={this.handleDateStartChange}
                            handleDateEndChange={this.handleDateEndChange}
                            sum_ib={sum_ib}
                        />
                        <TranfersIB
                            language={language}
                            data={data_member}
                            page={page_member}
                            handleChangePage={(e, v) => this.setState({ page_member: v })}
                            handleDateStartMemberChange={this.handleDateStartMemberChange}
                            handleDateEndMemberChange={this.handleDateEndMemberChange}
                            state={this.state}
                            onClickSubmitSearchMember={this.onClickSubmitSearchMember}
                        />
                    </Grid>
                )}

                {tap_value === 3 && (
                    <Grid>
                        <TraderCashback
                            data={dataTrader}
                            language={language}
                            profile={profile}
                            onClickTransferToWalletCashback={this.onClickTransferToWalletCashback}
                            state={this.state}
                            handleChangeText={this.handleSearch}
                            // onClickRegisterIB={this.onClickRegisterIB}
                        />
                        <TransactionCashback
                            language={language}
                            data={transaction_cashback}
                            profile={profile}
                            page={page_cashback}
                            handleChangePage={this.handleChangePage}
                            handleSearch={this.handleSearch}
                            state={this.state}
                            submitSearch={this.submitSearch}
                            handleDateStartChangeCashback={this.handleDateStartChangeCashback}
                            handleDateEndChangeCashback={this.handleDateEndChangeCashback}
                            handleChangeSearchCashback={this.handleChangeSearchCashback}
                            // sum_ib={sum_ib}
                        />
                        {/* <TranfersCashback
                                   language={language}
                                   data={data_member}
                                   page={page_member}
                                   handleChangePage={(e, v) => this.setState({ page_member: v })}
                                   handleDateStartMemberChange={this.handleDateStartMemberChange}
                                   handleDateEndMemberChange={this.handleDateEndMemberChange}
                                   state={this.state}
                                   onClickSubmitSearchMember={this.onClickSubmitSearchMember}
                              /> */}
                    </Grid>
                )}
                <LoadingPage open={loading} />
            </Container>
        );
    }
}
const dataTrader = [
    {
        accountNO: 1,
        arrDetail: [
            {
                id: 1,
                img: eye,
                title: "Trader Password :",
                name: "trader_password",
                value: "123456789",
                edit: true,
                trader_password: true,
            },
            {
                id: 2,
                img: eye,
                title: "Investor Password :",
                name: "investor_password",
                value: "123456789",
                edit: true,
                investor_password: true,
            },
            {
                id: 3,
                img: "",
                title: "Balance :",
                name: "",
                value: "$100",
                edit: null,
            },
            {
                id: 4,
                img: "",
                title: "Account Group :",
                name: "",
                value: "SELF TRADE",
                edit: null,
            },
            {
                id: 5,
                img: "",
                title: "Leverage :",
                name: "",
                value: "1:100",
                edit: true,
            },
        ],
    },
    {
        accountNO: 2,
        arrDetail: [
            {
                id: 1,
                img: eye,
                title: "Trader Password :",
                name: "trader_password",
                value: "123456789",
                edit: true,
                trader_password: true,
            },
            {
                id: 2,
                img: eye,
                title: "Investor Password :",
                name: "investor_password",
                value: "123456789",
                edit: true,
                investor_password: true,
            },
            {
                id: 3,
                img: "",
                title: "Balance :",
                name: "",
                value: "$100",
                edit: null,
            },
            {
                id: 4,
                img: "",
                title: "Account Group :",
                name: "",
                value: "SELF TRADE",
                edit: null,
            },
            {
                id: 5,
                img: "",
                title: "Leverage :",
                name: "",
                value: "1:100",
                edit: true,
            },
        ],
    },
    {
        accountNO: 3,
        arrDetail: [
            {
                id: 1,
                img: eye,
                title: "Trader Password :",
                name: "trader_password",
                value: "123456789",
                edit: true,
                trader_password: true,
            },
            {
                id: 2,
                img: eye,
                title: "Investor Password :",
                name: "investor_password",
                value: "123456789",
                edit: true,
                investor_password: true,
            },
            {
                id: 3,
                img: "",
                title: "Balance :",
                name: "",
                value: "$100",
                edit: null,
            },
            {
                id: 4,
                img: "",
                title: "Account Group :",
                name: "",
                value: "SELF TRADE",
                edit: null,
            },
            {
                id: 5,
                img: "",
                title: "Leverage :",
                name: "",
                value: "1:100",
                edit: true,
            },
        ],
    },
    {
        accountNO: 4,
        arrDetail: [
            {
                id: 1,
                img: eye,
                title: "Trader Password :",
                name: "trader_password",
                value: "123456789",
                edit: true,
                trader_password: true,
            },
            {
                id: 2,
                img: eye,
                title: "Investor Password :",
                name: "investor_password",
                value: "123456789",
                edit: true,
                investor_password: true,
            },
            {
                id: 3,
                img: "",
                title: "Balance :",
                name: "",
                value: "$100",
                edit: null,
            },
            {
                id: 4,
                img: "",
                title: "Account Group :",
                name: "",
                value: "SELF TRADE",
                edit: null,
            },
            {
                id: 5,
                img: "",
                title: "Leverage :",
                name: "",
                value: "1:100",
                edit: true,
            },
        ],
    },
    {
        accountNO: 5,
        arrDetail: [
            {
                id: 1,
                img: eye,
                title: "Trader Password :",
                name: "trader_password",
                value: "123456789",
                edit: true,
                trader_password: true,
            },
            {
                id: 2,
                img: eye,
                title: "Investor Password :",
                name: "investor_password",
                value: "123456789",
                edit: true,
                investor_password: true,
            },
            {
                id: 3,
                img: "",
                title: "Balance :",
                name: "",
                value: "$100",
                edit: null,
            },
            {
                id: 4,
                img: "",
                title: "Account Group :",
                name: "",
                value: "SELF TRADE",
                edit: null,
            },
            {
                id: 5,
                img: "",
                title: "Leverage :",
                name: "",
                value: "1:100",
                edit: true,
            },
        ],
    },
    {
        accountNO: 6,
        arrDetail: [
            {
                id: 1,
                img: eye,
                title: "Trader Password :",
                name: "trader_password",
                value: "123456789",
                edit: true,
                trader_password: true,
            },
            {
                id: 2,
                img: eye,
                title: "Investor Password :",
                name: "investor_password",
                value: "123456789",
                edit: true,
                investor_password: true,
            },
            {
                id: 3,
                img: "",
                title: "Balance :",
                name: "",
                value: "$100",
                edit: null,
            },
            {
                id: 4,
                img: "",
                title: "Account Group :",
                name: "",
                value: "SELF TRADE",
                edit: null,
            },
            {
                id: 5,
                img: "",
                title: "Leverage :",
                name: "",
                value: "1:100",
                edit: true,
            },
        ],
    },
];
const dataTranfers = [
    {
        accountNO: 1,
        arrDetail: [
            {
                id: 1,
                title: "Balance :",
                value: "$100.00",
            },
            {
                id: 2,
                title: "Account Group :",
                value: "HY-PREMIUM",
            },
        ],
    },
    {
        accountNO: 2,
        arrDetail: [
            {
                id: 1,
                title: "Balance :",
                value: "$100.00",
            },
            {
                id: 2,
                title: "Account Group :",
                value: "HY-PREMIUM",
            },
        ],
    },
    {
        accountNO: 3,
        arrDetail: [
            {
                id: 1,
                title: "Balance :",
                value: "$100.00",
            },
            {
                id: 2,
                title: "Account Group :",
                value: "HY-PREMIUM",
            },
        ],
    },
    {
        accountNO: 4,
        arrDetail: [
            {
                id: 1,
                title: "Balance :",
                value: "$100.00",
            },
            {
                id: 2,
                title: "Account Group :",
                value: "HY-PREMIUM",
            },
        ],
    },
    {
        accountNO: 5,
        arrDetail: [
            {
                id: 1,
                title: "Balance :",
                value: "$100.00",
            },
            {
                id: 2,
                title: "Account Group :",
                value: "HY-PREMIUM",
            },
        ],
    },
    {
        accountNO: 6,
        arrDetail: [
            {
                id: 1,
                title: "Balance :",
                value: "$100.00",
            },
            {
                id: 2,
                title: "Account Group :",
                value: "HY-PREMIUM",
            },
        ],
    },
];
const dataTableTD = [
    {
        id: 1,
        number: "DF-20210613223141",
        transaction: "TF-MT4-WL-20200507203425",
        code: "TF-MT4-WL",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        wf_wallet: "WF 10.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 2,
        number: "DF-20210613223142",
        transaction: "TF-MT4-WL-20200507203425",
        code: "TF-MT4-WL",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        wf_wallet: "WF 10.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 3,
        number: "DF-20210613223143",
        transaction: "TF-MT4-WL-20200507203425",
        code: "TF-MT4-WL",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        wf_wallet: "WF 10.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 4,
        number: "DF-20210613223144",
        transaction: "TF-MT4-WL-20200507203425",
        code: "TF-MT4-WL",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        wf_wallet: "WF 10.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 5,
        number: "DF-20210613223145",
        transaction: "TF-MT4-WL-20200507203425",
        code: "TF-MT4-WL",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        wf_wallet: "WF 10.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 6,
        number: "DF-20210613223146",
        transaction: "TF-MT4-WL-20200507203425",
        code: "TF-MT4-WL",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        wf_wallet: "WF 10.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
];
const dataTableIB = [
    {
        id: 1,
        number: "DF-20210613223141",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        tranfer: "4.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 2,
        number: "DF-20210613223142",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        tranfer: "4.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 3,
        number: "DF-20210613223143",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        tranfer: "4.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 4,
        number: "DF-20210613223144",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        tranfer: "4.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 5,
        number: "DF-20210613223145",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        tranfer: "4.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
    {
        id: 6,
        number: "DF-20210613223146",
        date: "2020-05-07 20:34:25",
        ip: "115.87.152.167",
        tranfer: "4.00 USD",
        receive: "10.00 USD",
        status: "active",
    },
];
export default withRouter(Trading);
