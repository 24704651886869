import React from "react";
import { Grid, Container, container, item, Link } from "@material-ui/core";

const LoginLayout = (props) => {
     const path = window.location.pathname.replace("/", "");
     const language = localStorage.getItem("language");
     return (
          <Grid className={`${path.split("/")[0]}-layout`}>
               <Grid>{props.children}</Grid>
          </Grid>
          // <Grid>
          //      <Grid>{props.children}</Grid>
          // </Grid>

          /* <Grid sx={{ position: "relative", background: "tranparent", width: "100vw", py: 5 }}>{props.children}</Grid>

          </Grid> */
     );
};
export default LoginLayout;
