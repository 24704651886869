import { Grid, Typography } from "@material-ui/core";
import { headerTable } from "functions/Static";
import TableNewMember from "./TableNewMember";

const ListIBNewMember = (props) => {
    const { data, profile, language, page, handleChangePage } = props;
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = page - 1;
    const dataList = data.slice(setPage * 10, page * 10);

    return (
        <Grid container rowSpacing={5} justifyContent="center" alignItems="center" mt={5}>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {language === "th" ? "สมาชิกใหม่" : "New Member"}
                </Typography>
            </Grid>
            {profile.is_ib === 1 &&
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, mb: 2 }}>IB Network</Typography>
                    <Typography sx={{ fontWeight: "bold" }} >
                        {profile.name} {profile.lastname}
                    </Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <TableNewMember head={headerTable.iblist_new_customer} body={dataList} count={count} page={page} handleChangePage={handleChangePage} profile={profile} language={language} />
            </Grid>
        </Grid>
    );
};

export default ListIBNewMember;
