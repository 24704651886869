import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { sideBar } from "../../functions/Static";
import main from "../../assets/images/main.png";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const TitleQrs = (props) => {
     const classes = useStyles();
     const { language } = props;
     // const path = window.location.pathname;
     const path = window.location.pathname;
     const strs = path.split("/");
     // const title = sideBar.three.find((el) => el.link.includes(path));
     const title = sideBar.three.find((el) => el.link.includes(`/${strs[1]}`));
     return (
          <Grid className={classes.backGroundStyle}>
               {/* <Grid> */}
               <Typography className={classes.titleStyle}>{language === "th" ? title.th : title.en}</Typography>
               {/* <Typography >{language === "th" ? title.th : title.en}</Typography> */}

          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     backGroundStyle: {
          height: 150,
          //   width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          background: `linear-gradient(rgba(90, 90, 90, 0.45), rgba(90, 90, 90, 0.45)), url("${main}")`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          [theme.breakpoints.up("sm")]: {
               height: 400,
          },
     },
     titleStyle: {
          fontSize: 20,
          color: "white",
          marginTop: 40,
          borderBottom: "solid 2px #4589C6",
          [theme.breakpoints.up("sm")]: {
               marginTop: 80,
               fontSize: 32,
          },
     },
}));
export default TitleQrs;
