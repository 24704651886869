import { Container, Grid } from "@material-ui/core";
import LoadingPage from "components/LoadingPage/LoadingPage";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETACCOUNTTYPE, GETMT5ALL, GETMT5TRANSACTION, POST } from "service";
import swal from "sweetalert2";
import ListTransaction from "./ListMarketing/ListTransaction";
// import logo from "../../assets/images/picture2.png";
// import ListMarketing from "./ListMarketing/ListMarketing";

// const dataService = [
//      {
//           id: 1,
//           img: logo,
//           title: "การเทรดฟอเร็กซ์",
//           detail: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.",
//      },
//      {
//           id: 2,
//           img: logo,
//           title: "หุ้น",
//           detail: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.",
//      },
//      {
//           id: 3,
//           img: logo,
//           title: "สกุลเงิน",
//           detail: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.",
//      },
//      {
//           id: 4,
//           img: logo,
//           title: "ทองคำ",
//           detail: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.",
//      },
//      {
//           id: 5,
//           img: logo,
//           title: "ดัชนีต่างๆ",
//           detail: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.",
//      },
// ];
export class Report extends Component {
     constructor(props) {
          super(props);

          this.state = {
               page_td: 1,
               date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
               date_end: new Date(),
               data_transaction_td: [],
               loading: false,
               tap_value: 1,
               info_list: [
                    { label: "All", value: "all" },
               ],
               accountType_list: [
                    { label: "All", value: "all" },
               ],

               mt5: 'all',
               accountType: 'all',
               mt5_select: "All",
               accountType_select: "All",
               data_transaction: {},
          };
     }

     componentDidMount = () => {
          this.getInfo();
          this.getAcoountSelect();
          this.getDataMT5();
     };

     getInfo = async (id) => {
          const { accountType } = this.state;
          try {
               this.setState({ loading: true });
               let getInfo = await POST(GETMT5ALL, { account_type_id: id ? id : accountType });
               if (getInfo.success) {
                    let data = [{ label: "All", value: "all" }];
                    getInfo.result.map((el) => {
                         data.push({ label: el.mt4, value: el.mt4 });
                    });
                    this.setState({ info_list: data });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "Warning", text: getInfo.message, icon: "warning", showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "Warning", text: error, icon: "warning", showConfirmButton: true });
          }
     };

     getAcoountSelect = async () => {
          let { language } = this.state;
          try {
               let res = await POST(GETACCOUNTTYPE, { lang: language });
               if (res.success) {
                    let data = [{ label: "All", value: "all" }];
                    res.result.map((el) => {
                         data.push({ label: el.account_type_name, value: el.account_type_id });
                    });
                    this.setState({ accountType_list: data });
               } else {
                    swal.fire({
                         title: "Warning", text: res.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "Warning", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getDataMT5 = async (value) => {
          const { mt5, date_start, date_end, page_td, language, accountType } = this.state;
          let start = moment(date_start).format("YYYY-MM-DD");
          let end = moment(date_end).format("YYYY-MM-DD");

          try {
               this.setState({ loading: true });
               let MT5 = await POST(GETMT5TRANSACTION, { account_type_id: accountType, mt5, date_start: start, date_end: end, page: value ? value : page_td });
               if (MT5.success) {
                    this.setState({
                         data_transaction_td: MT5.result.transaction, data_transaction: MT5.result.dataHeader
                    });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "Warning", text: MT5.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "Warning", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     submitSearch = async () => {
          const { mt5, date_start, date_end, accountType } = this.state;
          let start = moment(date_start).format("YYYY-MM-DD");
          let end = moment(date_end).format("YYYY-MM-DD");
          try {
               this.setState({ loading: true });
               let MT5 = await POST(GETMT5TRANSACTION, { account_type_id: accountType, mt5, date_start: start, date_end: end, page: 1 });
               if (MT5.success) {
                    this.setState({
                         data_transaction_td: MT5.result.transaction, data_transaction: MT5.result.dataHeader, page_td: 1
                    });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "Warning", text: MT5.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "Warning", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     handleChangePage = (e, value) => {
          if (this.state.tap_value === 1) {
               this.setState({ page_td: value });
               this.getDataMT5(value);
               // this.setState({ page_td: value });
          } else {
               this.setState({ page_ib: value });
          }
     };

     handleSearch = (e) => {
          // console.log(e.target.value, e.target.name);
          this.setState({ [e.target.name]: e.target.value });
     };

     handleDateStartChange = (date) => {
          this.setState({ date_start: date });
     };

     handleDateEndChange = (date) => {
          this.setState({ date_end: date });
     };

     onChangeAutocomplete = (name, valueAuto) => {
          // console.log('valueAuto', valueAuto);
          if (name === "search_mt5") {
               this.setState({ mt5: valueAuto.value, mt5_select: valueAuto.label });
          } else {
               this.getInfo(valueAuto.value);
               this.setState({ mt5: 'all', mt5_select: 'All' });
               this.setState({ accountType: valueAuto.value, accountType_select: valueAuto.label });
          }
     };

     render() {
          const language = localStorage.getItem("language");
          const { data_transaction_td, page_td, info_list, loading, data_transaction, tap_value, accountType_list, onChangeAutocomplete } = this.state;
          const { classes } = this.props;
          // console.log(tap_value);
          return (
               <Container maxWidth="xl">
                    <LoadingPage open={loading} />
                    {/* <TapFinancial setTap={(e) => this.setState({ tap_value: e })} language={language} /> */}

                    <Grid>
                         {tap_value === 1 ? (
                              <ListTransaction
                                   language={language}
                                   data={data_transaction_td}
                                   data_transaction={data_transaction}
                                   page={page_td}
                                   handleChangePage={this.handleChangePage}
                                   handleDateStartChange={this.handleDateStartChange}
                                   handleDateEndChange={this.handleDateEndChange}
                                   handleSearch={this.handleSearch}
                                   state={this.state}
                                   submitSearch={this.submitSearch}
                                   info_list={info_list}
                                   accountType_list={accountType_list}
                                   onChangeAutocomplete={this.onChangeAutocomplete}
                              />
                         ) : tap_value === 2 ? (
                              <ListTransaction
                                   language={language}
                                   data={data_transaction_td}
                                   data_transaction={data_transaction}
                                   page={page_td}
                                   handleChangePage={this.handleChangePage}
                                   handleDateStartChange={this.handleDateStartChange}
                                   handleDateEndChange={this.handleDateEndChange}
                                   handleSearch={this.handleSearch}
                                   state={this.state}
                                   submitSearch={this.submitSearch}
                                   info_list={info_list}
                                   accountType_list={accountType_list}
                                   onChangeAutocomplete={this.onChangeAutocomplete}
                              />
                         ) : (
                              <ListTransaction
                                   language={language}
                                   data={data_transaction_td}
                                   data_transaction={data_transaction}
                                   page={page_td}
                                   handleChangePage={this.handleChangePage}
                                   handleDateStartChange={this.handleDateStartChange}
                                   handleDateEndChange={this.handleDateEndChange}
                                   handleSearch={this.handleSearch}
                                   state={this.state}
                                   submitSearch={this.submitSearch}
                                   info_list={info_list}
                                   accountType_list={accountType_list}
                                   onChangeAutocomplete={this.onChangeAutocomplete}
                              />
                         )}
                    </Grid>

                    {/* <ListTransaction
                         language={language}
                         data={data_transaction_td}
                         data_transaction={data_transaction}
                         page={page_td}
                         handleChangePage={this.handleChangePage}
                         handleDateStartChange={this.handleDateStartChange}
                         handleDateEndChange={this.handleDateEndChange}
                         handleSearch={this.handleSearch}
                         state={this.state}
                         submitSearch={this.submitSearch}
                         info_list={info_list}

                    /> */}
               </Container>
          );
     }
}

export default withRouter(Report);
