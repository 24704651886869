import React from "react";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/styles";
import { Grid, Tabs, Tab, TableContainer, Table } from "@material-ui/core";

const DetailTaps = (props) => {
     // console.log(props)
     // const classes = useStyles();
     const [value, setValue] = React.useState(1);
     const handleChange = (event, newValue) => {
          setValue(newValue);
          props.setTap(newValue);
     };
     const { profile } = props
     return (
          <Grid container justifyContent="center" alignItems="center">
               <Grid item xs={12} className="taps-css" sx={{ display: { xs: "none", md: "block" }, borderBottom: 1, borderColor: "#4589C6" }}>
                    <Tabs value={value} onChange={handleChange} centered variant="fullWidth">
                         <Tab value={1} label="Trading Accounts" />
                         {profile.ib_status == 1 ? <Tab value={2} label="IB INTERNAL TRANFERS TO WALLET" /> : null}
                         <Tab value={3} label="CASHBACK" />
                    </Tabs>
               </Grid>

               <Grid item xs={12} className="taps-css-mobile-trading" sx={{ display: { xs: "block", md: "none" }, borderBottom: 1, borderColor: "#4589C6" }}>
                    <TableContainer>
                         <Table sx={{ minWidth: 270 }}>
                              <Tabs value={value} onChange={handleChange} centered variant="fullWidth">
                                   <Tab value={1} label="Trading Accounts" />
                                   {profile.ib_status == 1 ? <Tab value={2} label="IB INTERNAL TRANFERS TO WALLET" /> : null}
                                   <Tab value={3} label="CASHBACK" />
                              </Tabs>
                         </Table>
                    </TableContainer>
               </Grid>
          </Grid>
     );
};
// const useStyles = makeStyles((theme) => ({
//      imgStyle: {
//           objectFit: "contain",
//           height: 200,
//           [theme.breakpoints.up("md")]: {
//                height: 500,
//           },
//      },
//      fontStyleTitle: {
//           fontSize: 28,
//           color: "#000",
//           marginTop: 15,
//           fontWeight: 600,
//           [theme.breakpoints.up("md")]: {
//                marginTop: 0,
//                fontSize: 54,
//           },
//      },
//      fontStyleDetail: {
//           fontSize: 13,
//           color: "#000",
//           textAlign: "justify",
//           marginTop: 24,
//           [theme.breakpoints.up("md")]: {
//                fontSize: 18,
//           },
//      },
// }));
export default withRouter(DetailTaps);
